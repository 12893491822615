import React from "react";
import { Card, Col, Row, Form, Table, Button, Tabs, Tab, Dropdown } from "react-bootstrap";
import dollarliability from "../../../assets/svg/newliability.svg";

import style from "../../../styles/Reports.module.css";
import TabStyle from "../../../styles/Sales.module.css";

import { useState, useEffect, useRef } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import axios from "axios";
import SearchableSelect from "../../SearchableSelect";
import DatePicker from "../../DatePicker/DatePicker";
import { useSpring, animated } from "react-spring";
import ReactSelect from "../../ReactSelect";
import { getcomponent } from "../../InventoryForms/utils";
import InventoryReportTab from "./InventoryReportTab";
import { moneyToMillions } from "./numberToWords";
import styles from "../../../styles/Setting.module.css";
import filterStyle from "../../../styles/filtersButtons.module.css";
import filterr from "../../../assets/svg/filter.svg";
import MobileSubReportsTabs from "../MobileSubReportsTab";
import MobilrReportsFilter from "../MobileReportsFilter";

const CurrencyAnimation = (props) => {
  const value = useSpring({
    from: { number: 0 },
    to: { number: props.number },
    delay: 200,
    config: { mass: 1, tension: 200, friction: 10, duration: 1000 },
  });
  return (
    <animated.div>
      {value.number.to((n) => {
        const tempN = Number(n.toFixed(0));
        return tempN.toLocaleString("en-IN");
      })}
    </animated.div>
  );
};

const InventoryReports = ({ MainactiveTab, close, show }) => {
  const [data, setData] = useState();

  const [fromDate, setFromDate] = useState(moment().subtract(29, "days").startOf("day")._d);
  const [toDate, setToDate] = useState(moment().endOf("day")._d);

  const { user } = useSelector((state) => state.auth);
  const shop_id = user.shop_id;
  const user_type = user.user_type;
  const userId = user.user_id;
  const shopName = user.shop_name;

  const [filterInput, setFilterInput] = useState("");
  const [location, setLocation] = useState({});
  const [locationFields, setLocationFields] = useState({});
  const [brand, setBrand] = useState({});
  const [category, setCategory] = useState({});
  const [vendor, setVendor] = useState({});
  const [product, setProcuct] = useState({});
  const [productOptions, setProcuctOptions] = useState([]);
  const [isProductLoading, setIsProductLoading] = useState(false);
  const [tableData, settableData] = useState([]);
  const [activeTab, setActiveTab] = useState("Item-Wise-Stock-Report");
  const [showMobileTabs, setShowMobileTabs] = useState(false);

  useEffect(() => {
    axios
      .post(process.env.REACT_APP_API_URL + `/location/get-location-by-user/${userId}`, { shop_id: shop_id, user_type: user_type }, { withCredentials: true })
      .then((res) => {
        const locationsArr = res.data.map((loc) => ({
          value: loc.location_id,
          label: loc.location,
          isDisabled: new Date().toISOString() >= loc.active_till ? true : false,
        }));

        setLocation(locationsArr);
        setLocationFields({
          value: locationsArr[0]?.value,
          label: locationsArr[0]?.label,
          isDisabled: locationsArr[0]?.isDisabled,
        });
      })
      .catch((err) => {
        throw new Error(err);
      });
  }, [userId, shop_id, user_type]);
  useEffect(() => {
    setIsProductLoading(true);
    axios
      .post(
        process.env.REACT_APP_API_URL + `/inventory/get-inventory/${user.user_id}`,
        {
          location_nick: locationFields.label,
          category_name: category?.value ? category?.label : null,
          brand: brand?.value ? brand?.value : null,
          isAvailableOnly: true,
        },
        { withCredentials: true }
      )
      .then((res) => {
        const data = res.data.data
          .map((item) => {
            return {
              value: item.id,
              label: item.title,
            };
          })
          .filter((i) => {
            return i.label.toLowerCase().includes(filterInput.toLowerCase());
          });
        data.splice(0, 0, { label: "All", value: null });
        setProcuctOptions(data);
        setIsProductLoading(false);
      })
      .catch((err) => {
        setIsProductLoading(false);
        throw new Error(err);
      });
  }, [userId, shop_id, filterInput, brand, category, locationFields]);

  useEffect(() => {
    const getReport = () => {
      const filter = {
        location: locationFields?.value,
      };
      axios
        .get(process.env.REACT_APP_API_URL + `/reports/inventory-total-worth/${shop_id}`, {
          withCredentials: true,
          headers: { filters: JSON.stringify(filter) },
        })
        .then((res) => {
          setData(res.data.success.data);
        })
        .catch((err) => {
          throw new Error(err);
        });
    };
    if (locationFields?.value && shop_id) {
      getReport();
    }
  }, [shop_id, fromDate, toDate, locationFields, brand, category, vendor, product]);

  const resetFilterHandle = (e) => {
    setFromDate(moment().subtract(29, "days").startOf("day")._d);
    setToDate(moment().endOf("day")._d);
    setBrand({});
    setProcuct({});
    setCategory({});
    setVendor({});
    // setLocationFields(location[0]);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleClose = () => {
    setShowMobileTabs(false);
  };
  const handleShow = () => {
    setShowMobileTabs(true);
  };

  const handleLocation = (e) => {
    if (e.value) {
      setLocationFields(e);
      setProcuct({});
    } else {
      setLocationFields({});
    }
  };
  return (
    <>
      <Row className="align-items-center mt-3 d-lg-flex d-none d-md-flex d-sm-flex bg-white py-2 ">
        <Col className="mb-1 d-lg-block d-none d-md-block d-sm-block" md="4" sm="4" lg="3" xl="2">
          <SearchableSelect placeholder="Select Location" searchable={false} options={location} handleChange={handleLocation} value={Object.keys(locationFields).length > 0 ? locationFields : null} style={{ width: "100px" }} disabled={!location.length} />
        </Col>
        <Col className="mb-2 d-lg-block d-none d-md-block d-sm-block" md="4" sm="4" lg="3" xl="2">
          <DatePicker fromDate={fromDate} toDate={toDate} setFromDate={setFromDate} setToDate={setToDate} />
        </Col>
        {activeTab === "Brand-Wise-Stock-Report" ? (
          <Col className="mb-2 d-lg-block d-none d-md-block d-sm-block" md="4" sm="4" lg="3" xl="2">
            <ReactSelect
              placeholder="Select Brand"
              searchable={true}
              options={(e) => {
                return new Promise((resolve, reject) => {
                  getcomponent("brand", {}, true)
                    .then((res) => {
                      const getData = res.data.success.data.map((b) => ({
                        value: b.id,
                        label: b.label,
                      }));
                      const result = getData.filter((i) => {
                        return i.label.toLowerCase().includes(e.toLowerCase());
                      });
                      result.splice(0, 0, { label: "All", value: null });
                      resolve(result);
                    })
                    .catch((err) => {
                      reject("err" + err);
                    });
                });
              }}
              handleChange={(e) => {
                if (e.value) {
                  setBrand(e);
                } else {
                  setBrand({});
                }
              }}
              value={Object.keys(brand).length > 0 ? brand : null}
              onInputChange={(newInput) => {
                const inputValue = newInput.replace(/\s+/g, " ");
                return inputValue;
              }}
            />
          </Col>
        ) : (
          ""
        )}
        {activeTab === "Item-Wise-Stock-Report" || activeTab === "Category-Wise-Stock-Report" ? (
          <Col className="mb-2 d-lg-block d-none d-md-block d-sm-block" md="4" sm="4" lg="3" xl="2">
            <ReactSelect
              placeholder="Select Category"
              searchable={false}
              value={Object.keys(category).length > 0 ? category : null}
              options={() => {
                return new Promise((resolve, reject) => {
                  axios
                    .get(process.env.REACT_APP_API_URL + "/categories/get", {
                      withCredentials: true,
                    })
                    .then((res) => {
                      const getData = res.data.map((cat) => ({
                        value: cat.id,
                        label: cat.category,
                      }));
                      const result = getData.filter((cat) => cat.value < 7);
                      result.splice(0, 0, { label: "All", value: null });
                      resolve(result);
                    })
                    .catch((err) => {
                      reject("err" + err);
                    });
                });
              }}
              handleChange={(e) => {
                if (e.value) {
                  setCategory(e);
                } else {
                  setCategory({});
                }
              }}
            />
          </Col>
        ) : (
          ""
        )}
        {activeTab === "Vendor-Wise-Stock-Report" ? (
          <Col className="mb-2 d-lg-block d-none d-md-block d-sm-block" md="4" sm="4" lg="3" xl="2">
            <ReactSelect
              placeholder="Select vendor"
              searchable={true}
              options={(e) => {
                return new Promise((resolve, reject) => {
                  axios
                    .get(process.env.REACT_APP_API_URL + "/vendors/get-vendor-by-shop/" + shop_id, { withCredentials: true })
                    .then((res) => {
                      const data = res.data
                        .map((item) => {
                          return {
                            label: item.vendor_name,
                            value: item.vendor_id,
                          };
                        })
                        .filter((i) => {
                          return i.label.toLowerCase().includes(e.toLowerCase());
                        });
                      data.splice(0, 0, { label: "All", value: null });
                      resolve(data);
                    })
                    .catch((err) => {
                      reject("err" + err);
                    });
                });
              }}
              handleChange={(e) => {
                if (e.value) {
                  setVendor(e);
                } else {
                  setVendor({});
                }
              }}
              value={Object.keys(vendor).length > 0 ? vendor : null}
              onInputChange={(newInput) => {
                const inputValue = newInput.replace(/\s+/g, " ");
                return inputValue;
              }}
            />
          </Col>
        ) : (
          ""
        )}

        <>
          {activeTab === "Item-Wise-Stock-Report" && (
            <Col className="mb-2 d-lg-block d-none d-md-block d-sm-block" md="4" sm="4" lg="3" xl="2">
              <SearchableSelect
                placeholder="Select Product"
                options={productOptions}
                isLoading={isProductLoading}
                handleChange={(e) => {
                  if (e.value) {
                    setProcuct(e);
                  } else {
                    setProcuct({});
                  }
                }}
                value={Object.keys(product).length > 0 ? product : null}
                onInputChange={(newInput) => {
                  const inputValue = newInput.replace(/\s+/g, " ");
                  setFilterInput(inputValue);
                  return inputValue;
                }}
              />
            </Col>
          )}
          <Col className="mb-2 d-lg-block d-none d-md-block d-sm-block" md="4" sm="4" lg="3" xl="2">
            <div className=" d-lg-flex justify-content-between">
              <Button size="sm" type="submit" style={{ fontSize: "14px", marginTop: "7px" }} onClick={resetFilterHandle}>
                <span className=" d-lg-inline">Reset Filters</span>{" "}
              </Button>
            </div>
          </Col>
        </>
      </Row>
      <Row className="py-1 my-4">
        <Col xs={12} sm={6} lg={3} className="mb-2">
          <Card className={`${style.cards} border-0 py-2 reports-card`}>
            <span className="border-bottom text-start px-2">Net worth</span>

            <div className="py-3 d-flex justify-content-evenly flex-md-row flex-column">
              <div className="text-md-start text-center d-flex">
                <img className="mx-3" src={dollarliability} />
                <Card.Title className="fs-2">
                  <span className={`${style.cardstitle} mx-2`}>{moneyToMillions(data?.totalNetWorth)}</span>
                </Card.Title>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
      <Row className="align-items-center mt-1 "></Row>
      <Row className=" w-100 bg-white p-2 mb-3 overflow-auto text-nowrap ob ">
        <Col xl="11" lg="11" md="12">
          <Button
            onClick={() => {
              // settableData(salesPaymentData)
              handleTabChange("Item-Wise-Stock-Report");
            }}
            className={`${styles.btn} ${activeTab === "Item-Wise-Stock-Report" ? styles.activebtn : ""}  mb-2 mx-2 rounded-5 `}
            variant="outline-primary"
          >
            Item Wise Stock Report
          </Button>
          <Button
            // disabled={true}
            onClick={() => {
              // settableData(vendorPaymentsData)
              handleTabChange("Category-Wise-Stock-Report");
            }}
            className={`${styles.btns} ${activeTab === "Category-Wise-Stock-Report" ? styles.activebtn : ""}  mb-2 mx-2 rounded-5`}
            variant="outline-primary"
          >
            Category Wise Stock Report
          </Button>
          <Button
            onClick={() => {
              // settableData(Vendor Wise Stock ReportsData)
              handleTabChange("Vendor-Wise-Stock-Report");
            }}
            className={`${styles.btns} ${activeTab === "Vendor-Wise-Stock-Report" ? styles.activebtn : ""}  mb-2 mx-2 rounded-5`}
            variant="outline-primary"
          >
            Vendor Wise Stock Report
          </Button>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="bg-white mb-1 p-3 w-100 fs-20 fw-bold">{activeTab.replaceAll("-", " ")}</div>
          {activeTab === "Item-Wise-Stock-Report" ? (
            <InventoryReportTab filter={{ itemWise: true }} shopId={shop_id} fromDate={fromDate} toDate={toDate} location={locationFields} brand={brand} category={category} product={product} />
          ) : activeTab === "Category-Wise-Stock-Report" ? (
            <InventoryReportTab filter={{ categoryWise: true }} shopId={shop_id} fromDate={fromDate} toDate={toDate} location={locationFields} category={category} />
          ) : activeTab === "Vendor-Wise-Stock-Report" ? (
            <InventoryReportTab filter={{ vendorWise: true }} shopId={shop_id} fromDate={fromDate} toDate={toDate} location={locationFields} vendor={vendor} />
          ) : (
            ""
          )}
        </Col>
      </Row>
      <MobileSubReportsTabs tabs={["Item-Wise-Stock-Report", "Category-Wise-Stock-Report", "Vendor-Wise-Stock-Report", "Brand-Wise-Stock-Report"]} activeTAb={activeTab} handleTabChange={handleTabChange} show={showMobileTabs} handleClose={handleClose} />
      <MobilrReportsFilter
        setFilterInput={setFilterInput}
        product={product}
        setProcuct={setProcuct}
        isProductLoading={isProductLoading}
        productOptions={productOptions}
        setBrand={setBrand}
        brand={brand}
        category={category}
        setCategory={setCategory}
        setVendor={setVendor}
        vendor={vendor}
        shop_id={shop_id}
        secondaryActiveTab={activeTab}
        handleLocation={handleLocation}
        location={location}
        locationFields={locationFields}
        setLocationFields={setLocationFields}
        activeTAb={MainactiveTab}
        show={show}
        handleClose={close}
        fromDate={fromDate}
        toDate={toDate}
        setFromDate={setFromDate}
        setToDate={setToDate}
      />
    </>
  );
};

export default InventoryReports;
