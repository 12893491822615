import { faBars, faClone, faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { faClone as thinclone } from "@fortawesome/free-regular-svg-icons";
import { Tooltip } from "react-tooltip";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Form, Navbar, Row } from "react-bootstrap";
import logo from "./../assets/images/logo2.png";
import logo2 from "./../assets/images/logo3.png";
import style from "./../styles/Navbar.module.css";
import cartImg from "./../assets/svg/cart.svg";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { emptyLocation, getTotals, getlocation } from "../Features/cart/cartSlice";
import { useNavigate } from "react-router-dom";
import { Link, useLocation } from "react-router-dom";
import style1 from "../styles/Sidebar.module.css";
import { authActions, getRegisterId } from "../Features/authSlice";
import { Dropdown } from "react-bootstrap";
import { emptyCart } from "../Features/cart/cartSlice";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useState } from "react";
import bellIcon from "../assets/svg/bellIcon.svg";
import bellActiveIcon from "../assets/svg/bellActiveIcon.svg";
import SearchableSelect from "./SearchableSelect";
import axios from "axios";
import { dateTime } from "../Features/dateTime";
import PopUpModal from "./Modal";
import OpeningRegister from "./Register/OpeningRegister";
import GlobalLocation from "./GlobalLocation";
import NotificationDropdown from "./NotificationDropdown/NotificationDropdown";
import { notificationActions } from "../Features/notificationSlice";
const Topbar = ({ toggle, isSandwichOpen, isCartOpen, setIsCartOpen }) => {
  const [notificationDropdown, setNotificationDropdown] = useState(false);
  const [activeBell, setActiveBell] = useState(false);
  const { pathname } = useLocation();
  const { user } = useSelector((state) => state.auth);
  const userId = user.user_id;
  const user_type = user.user_type;
  const shop_id = user.shop_id;
  const registerID = useSelector((state) => state.auth?.registerId);
  const cart = useSelector((state) => state.cart);
  const notification = useSelector((state) => state.notification);
  const [value, setValue] = useState(`${`User: ${user.user_full_name}\n`}${`Email: ${user.user_email}\n`}${`Shop Id: ${user.shop_id}\n`}${`Shop Username : ${user.username}\n`}`);
  const [copied, setCopied] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const userName = user.user_full_name.charAt(0);
  const userName1 = user.user_full_name.split(/\s/).reduce((response, word) => (response += word.slice(0, 1)), "");
  const location = useLocation();
  const isGlobalLocationVisible = useMemo(
    () =>
      pathname !== "/" &&
      pathname !== "/vendor" &&
      pathname !== "/vendor/add" &&
      pathname !== "/customer" &&
      pathname !== "/trade-in" &&
      pathname !== "/reports" &&
      pathname !== "/settings/locations" &&
      pathname !== "/settings/reset-password" &&
      pathname !== "/settings/systemSetting" &&
      pathname !== "/settings/subscriptions" &&
      pathname !== "/settings/banks",
    [pathname]
  );
  useEffect(() => {
    dispatch(getTotals());
  }, [cart, dispatch]);

  const logout = () => {
    localStorage.removeItem("refreshToken");
    dispatch(authActions.logout());
    dispatch(emptyCart());
    // dispatch(emptyMiscCart());
    dispatch(emptyLocation());
    localStorage.removeItem("cartItems");
    localStorage.removeItem("cartMiscItems");
    // localStorage.removeItem("location");
    localStorage.removeItem("customer");
    navigate("/login");
  };
  const visit = () => {
    window.open(process.env.REACT_APP_MARKETPLACE_URL + "/store/" + user.username, "_blank", "noopener,noreferrer");
  };
  useEffect(() => {
    if (pathname === "/notifications") {
      setActiveBell(true);
    } else {
      setActiveBell(false);
    }
  }, [pathname]);
  const handleNotificationDropdown = () => {
    if (window.innerWidth < 576) {
      navigate("/notifications");
      setNotificationDropdown(false);
    } else {
      setActiveBell((prev) => !prev);
      setNotificationDropdown((prev) => !prev);
    }
  };

  return (
    <>
      <Navbar className="shadowMain position-fixed w-100" bg="white" style={{ zIndex: "22", height: "70px" }}>
        <div className="d-flex w-100" style={{ height: "67px" }}>
          <div className={`${style.clickableIcon}  ${style.hamburgerWithLogo}  justify-content-center`} onClick={toggle}>
            <FontAwesomeIcon className="bars" role="button" icon={faBars} />
          </div>
          <div className={`${!isSandwichOpen ? style.navItem : style.navItemClose} ${style.logoBorder}`}>
            <div className={`${!isSandwichOpen ? style.logo : style.logoClose} `}>
              <img
                style={{
                  pointerEvents: "none",
                }}
                src={logo2}
                className="me-2"
                alt="logo"
              />
              <img
                style={{
                  pointerEvents: "none",
                }}
                src={logo}
                className={`${!isSandwichOpen ? style.logofull : style.logofullHide} d-xs-none`}
                alt="logo"
              />
            </div>
          </div>

          <div className={`${!isSandwichOpen ? style.userInfoPart : style.userInfoPartClose} d-flex align-items-center`}>
            <div className={`${style.clickableIcon} ${style.hamburgerWithoutLogo} justify-content-center`} onClick={() => toggle()}>
              <FontAwesomeIcon className="bars" role="button" icon={faBars} />
            </div>

            <div className={`${style.nextToSandwich} d-flex align-items-center justify-content-end`}>
              <div className="d-none d-md-block">
                <GlobalLocation disableCondition={!isGlobalLocationVisible} />
              </div>
              {isCartOpen ? (
                <></>
              ) : location.pathname === "/sales" ? (
                <div
                  style={{ width: "25px", position: "relative", marginRight: "10px" }}
                  className="d-inline d-lg-none justify-content-center cursor-pointer notificationButton"
                  onClick={
                    isCartOpen
                      ? null
                      : () => {
                          setIsCartOpen(true);
                        }
                  }
                >
                  <img
                    style={{
                      pointerEvents: "none",
                    }}
                    width="19px"
                    height="22px"
                    src={cartImg}
                    alt="Cart Icon"
                    className="notificationButton"
                  />
                  <div className={`${style.notificationBadge} notificationButton`}>
                    <span className={`${style.notificationCount} notificationButton`}>{+cart.totalCartQuantity > 99 ? "99+" : cart.totalCartQuantity}</span>
                  </div>
                </div>
              ) : (
                <div
                  style={{ width: "25px", position: "relative", marginRight: "10px" }}
                  className="d-inline d-flex justify-content-center cursor-pointer notificationButton"
                  onClick={
                    isCartOpen
                      ? null
                      : () => {
                          setIsCartOpen(true);
                        }
                  }
                >
                  <img
                    style={{
                      pointerEvents: "none",
                    }}
                    width="19px"
                    height="22px"
                    src={cartImg}
                    alt="Cart Icon"
                    className="notificationButton"
                  />
                  <div className={`${style.notificationBadge} notificationButton`}>
                    <span className={`${style.notificationCount} notificationButton`}>{+cart.totalCartQuantity > 99 ? "99+" : cart.totalCartQuantity}</span>
                  </div>
                </div>
              )}
              {user?.user_type === "primary" || +user?.has_global === 1 ? (
                <div style={{ position: "relative" }}>
                  <div style={{ width: "25px" }} className="d-inline d-flex justify-content-center cursor-pointer notificationButton" onClick={pathname === "/notifications" ? null : handleNotificationDropdown}>
                    <img
                      style={{
                        pointerEvents: "none",
                      }}
                      width="19px"
                      height="22px"
                      src={activeBell ? bellActiveIcon : bellIcon}
                      alt="notification Icon"
                      className="notificationButton"
                    />
                    <div className={`${style.notificationBadge} notificationButton`}>
                      <span className={`${style.notificationCount} notificationButton`}>{+notification.notificationsCount > 99 ? "99+" : notification.notificationsCount}</span>
                    </div>
                  </div>

                  {notificationDropdown && (
                    <div
                      style={{
                        position: "absolute",
                        top: "30px",
                        left: 0,
                        width: "100%",
                      }}
                    >
                      <NotificationDropdown
                        show={notificationDropdown}
                        handleClose={() => {
                          setActiveBell(false);
                          setNotificationDropdown(false);
                        }}
                      />
                    </div>
                  )}
                </div>
              ) : (
                <></>
              )}

              <div className={`${style.clickableIcon} d-flex justify-content-center align-items-center`}>
                <Dropdown className={`d-flex d-lg-inline d-md-inline justify-content-end ${style.toggle}`}>
                  <Dropdown.Toggle variant="light" className={`border-0 p-0 text-secondary `} id="dropdown-basic" style={{ background: "transparent" }}>
                    <div className={`ms-2 me-2 d-flex justify-content-center align-items-center rounded-circle ${style.name}`}>
                      {/* <img
                      width="36px"
                      height="36px"
                      className="rounded-circle"
                      src={profile}
                      alt="profile"
                    /> */}
                      {userName1}
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className=" border-0 shadow mt-1 p-3" style={{ left: "-12rem", borderRadius: "7px !important" }}>
                    {/* <Dropdown.Item className="purple" href="" eventKey="true"> */}
                    <div className={`${style.data_group}`}>
                      <span className={` d-flex justify-content-between ${style.heading}`}>
                        {" "}
                        <span>Account </span>
                        {/* <span className="d-none" id="acc">
                      The data in this div is not visible. It contains rich
                      text. Rich (i.e. "formatted") data can also be generated
                      by javascript. The next word is <b>bolded</b>, and the
                      next one is in <i>italics</i>.
                      <span>Some large purple text</span>. You can use two
                      setData directives to insert TWO copies of this text into
                      the same clipboard, one that is plain and one that is
                      rich. That way your users can paste into either a
                      <ul>
                        <li>plain text editor</li>
                        <li>or into a rich text editor</li>
                      </ul>
                      Here is a <a href="https://rumble.com">Link to Rumble</a>{" "}
                      - you must Ctrl-Click the link in Word.
                    </span> */}
                        <span>
                          <CopyToClipboard
                            id="copy"
                            text={value}
                            // text={`${"username:" + user.user_full_name}  ${
                            //   "email: " + user.user_email
                            // } ${"shop id: " + user.shop_id} ${
                            //   "username :" + user.username
                            // }`}
                            onCopy={() => {
                              setCopied(true);
                              // setValue(
                              //   `${`username: ${user.user_full_name}\n`}  ${`email: ${user.user_email}\n`} ${`shop id: ${user.shop_id}\n`} ${`username : ${user.username}\n`}`
                              // );
                            }}
                          >
                            <FontAwesomeIcon
                              style={{
                                cursor: "pointer",
                                height: "15px",
                                outline: "none",
                              }}
                              icon={copied ? faClone : thinclone}
                            />
                          </CopyToClipboard>
                          <Tooltip className={`${copied ? style.tooltipSuces : ""}`} anchorId={`copy`} content={`${copied ? "Copied" : "Copy info to clipboard"}`} place="top" />
                        </span>
                      </span>
                      <div className={`${style.account_detail}`}>
                        <span className="d-flex mt-2">
                          <div className={`ms-2 me-2 d-flex justify-content-center align-items-center rounded-circle ${style.name}`}>
                            {/* <div className={`${style.active} me-1 `}></div> */}

                            {/* <img
                      width="36px"
                      height="36px"
                      className="rounded-circle"
                      src={profile}
                      alt="profile"
                    /> */}
                            {userName1}
                          </div>
                          <div className="d-flex flex-column">
                            <strong className="d-flex align-items-baseline">
                              {/* <div className={`${style.active} me-1 `}></div> */}
                              {user.user_full_name}
                            </strong>
                            <small className={`${style.email}`}>{user.user_email}</small>
                            <small className={`${style.email}`}>@{user.username}</small>
                            {/* <small className={`${style.email}`}>
                          Shop name: {user.shop_name}
                        </small> */}
                            <small className={`${style.email}`}>Shop id : {user.shop_id}</small>
                          </div>
                        </span>
                      </div>
                      {/* <hr className={`  ${style.divider}`} /> */}
                      <Dropdown.Divider />
                    </div>
                    <div>
                      <Dropdown.Item className="purple m-0 pt-1 pb-1 ps-0 pe-0" href="" eventKey="true" onClick={visit}>
                        <span
                          className={` ms-3 d-flex justify-content-between align-items-baseline`}
                          // onClick={}
                        >
                          Visit Website{" "}
                          <span className={`me-2 `}>
                            <svg style={{ color: "#7A869A" }} width="18" height="20" viewBox="0 0 24 24" role="presentation">
                              <g fill="currentColor">
                                <path d="M19.005 19c-.003 0-.005.002-.005.002l.005-.002zM5 19.006c0-.004-.002-.006-.005-.006H5v.006zM5 4.994V5v-.006zM19 19v-6h2v6.002A1.996 1.996 0 0119.005 21H4.995A1.996 1.996 0 013 19.006V4.994C3 3.893 3.896 3 4.997 3H11v2H5v14h14zM5 4.994V5v-.006zm0 14.012c0-.004-.002-.006-.005-.006H5v.006zM11 5H5v14h14v-6h2v6.002A1.996 1.996 0 0119.005 21H4.995A1.996 1.996 0 013 19.006V4.994C3 3.893 3.896 3 4.997 3H11v2zm8 0v3a1 1 0 002 0V4a1 1 0 00-1-1h-4a1 1 0 000 2h3z"></path>
                                <path d="M12.707 12.707l8-8a1 1 0 10-1.414-1.414l-8 8a1 1 0 001.414 1.414z"></path>
                              </g>
                            </svg>
                          </span>
                        </span>
                      </Dropdown.Item>
                    </div>
                    {/* <hr className={`${style.divider}`} /> */}
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={logout} className="purple" href="" eventKey="true">
                      <span className={` ms-1 ${style1.signOut} `}>
                        {/* <img src={signOut} alt="signout" /> {"Sign Out"} */}
                        <FontAwesomeIcon className="me-1" icon={faRightFromBracket} />
                        {" Sign Out"}
                      </span>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </Navbar>
    </>
  );
};

export default Topbar;
