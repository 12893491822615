import React from "react";
import style from "./../../styles/Sales.module.css";
import { Form, Button, Row, Col } from "react-bootstrap";
import PopUpModal from "../Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import styleModal from "../../styles/Modal.module.css";
import { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { faArrowAltCircleRight } from "@fortawesome/free-regular-svg-icons";
import Paynow from "./Paynow";
import SearchableSelect from "../SearchableSelect";

const CustomPlan = ({ shop_id, title, customModal, setCustomModal }) => {
  const [validated, setValidated] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [themes, setThemes] = useState([]);
  const [locations, setLocations] = useState([]);
  const [secondaryUsers, setSecondaryUsers] = useState([]);
  const [locationLimit, setLocationLimit] = useState();
  const [modules, setModules] = useState([]);
  const { user } = useSelector((state) => state.auth);
  const [p_id, setP_id] = useState();
  const [checkedLocationsFalse, setCheckedLocationsFalse] = useState({});
  const [subscriptionDetails, setSubscriptionDetails] = useState({});
  const [checkedLocations, setCheckedLocations] = useState({});
  const [paymentTime, setPaymentTime] = useState("pay-later");
  const [yearlyTotal, setYearlyTotal] = useState("");
  const [monthlyTotal, setMonthlyTotal] = useState(0);
  const [quarterlyTotal, setQuarterlyTotal] = useState("");
  const [prices, setPrices] = useState();
  const [formValidate, setFormValidate] = useState(false);
  const [paynowValidated, setPaynowValidated] = useState(false);
  const [payable, setPayable] = useState(0);
  const reg = /^(0|[1-9]\d*)(\.\d+)?$/;

  const [requestForm, setRequestForm] = useState({
    Title: title,
    Description: "",
  });
  const [selectedLocations, setSelectedLocations] = useState([
    {
      isChecked: false,
      id: "",
    },
  ]);
  const [gTotal, setGTotal] = useState(0);
  const [customPrices, setCustomPrices] = useState({
    secondaryUserTotal: 0,
    marketplaceThemeTotal: 0,
    smsTotal: 0,
    emailTotal: 0,
    locationLimitTotal: 0,
    modulesTotal: 0,
    themesTotal: 0,
    itemsTotal: 0,
    yearlyTotal: 0,
    monthlyTotal: 0,
    quarterlyTotal: 0,
  });
  const [customForm, setCustomForm] = useState({
    secondaryUser: "",
    marketplaceThemes: "",
    smsLimit: "",
    emailLimit: "",
    locationLimit: "",
    itemsLimit: "",
    frequency: [],
    secondary_users: [],
    locations: [],
  });
  const [paymentObj, setPaymentObj] = useState({
    transactionId: "",
    transDate: "",
    frequency: {},
    imageURl: "",
  });

  const handleCustomForm = (e) => {
    setCustomForm({
      ...customForm,
      [e.target.name]: e.target.value,
    });
  };

  const handlePrices = (e, name) => {
    const limitNameMapping = {
      itemsTotal: "marketplace_items_limit",
      smsTotal: "sms_limit",
      emailTotal: "emails_limit",
      locationLimitTotal: "locations_limit",
      secondaryUserTotal: "secondary_users_limit",
      // Add other mappings if needed
    };

    const limitName = limitNameMapping[name];

    if (limitName) {
      const priceObj = prices.find((price) => price.name === limitName);

      if (priceObj) {
        setCustomPrices((prevPrices) => ({
          ...prevPrices,
          [name]: Number(priceObj.per_unit_price) * e.target.value,
        }));
      }
    }
  };

  const fetchthemes = async () => {
    await axios
      .get(process.env.REACT_APP_API_URL + "/themes/" + user.shop_id, {
        withCredentials: true,
      })
      .then((response) => {
        let combined = [];

        combined.push(...response.data.excludedThemes);
        combined.push(...response.data.includedThemes);
        setThemes(combined);
      });
  };
  function getSecondaryUsers() {
    axios
      .post(process.env.REACT_APP_API_URL + `/user/get-secondary-user/${shop_id}?page=` + 1 + "&size=" + 30, {}, { withCredentials: true })
      .then((data) => {
        const res = data?.data;
        setSecondaryUsers(res?.data.map((i) => ({ value: i.id, label: `${i.user_full_name} (${i.location_name})` })));
        // setIsLoading(false);x
      })
      .catch((err) => {
        // setIsLoading(false);
        const tempErr = err.response.data.error;
        if (tempErr.status === 404 && tempErr.message === "No Record Found") {
          setSecondaryUsers([]);
        }
      });
  }

  const getSubscriptionDetails = async () => {
    axios
      .get(process.env.REACT_APP_API_URL + `/subscriptions/subscription-details/${shop_id}`, { withCredentials: true })
      .then((res) => {
        setSubscriptionDetails(res.data?.subscription);
        setPrices(res.data.itemsPricing);
      })
      .catch((err) => {
        throw new Error(err);
      });
  };
  useEffect(() => {
    fetchthemes();
    getSubscriptionDetails();
    getSecondaryUsers();
    axios
      .get(process.env.REACT_APP_API_URL + `/location/get-location/${shop_id}`, { withCredentials: true })
      .then((res) => {
        setLocations(
          res.data.map((i) => ({
            value: i.location_id,
            label: i.location_nick,
          }))
        );
      })
      .catch((err) => {
        throw new Error(err);
      });
  }, [shop_id]);

  useEffect(() => {
    setSelectedLocations(
      locations.map((data) => {
        return {
          value: data.location_id,
          label: data.location_nick,
        };
      })
    );
  }, [locations]);

  useEffect(() => {
    setGTotal(customPrices.secondaryUserTotal + customPrices.marketplaceThemeTotal + customPrices.smsTotal + customPrices.emailTotal + customPrices.locationLimitTotal + customPrices.itemsTotal + customPrices.modulesTotal + customPrices.themesTotal);
  }, [customPrices]);

  useEffect(() => {
    setQuarterlyTotal(Number(gTotal) * 3 * 0.9);
    setYearlyTotal(Number(gTotal) * 12 * 0.8);
    setMonthlyTotal(Number(gTotal));
  }, [gTotal]);

  useEffect(() => {
    setPaynowValidated(false);
  }, [paymentObj]);

  useEffect(() => {
    setPaynowValidated(false);
    const reqObj = {
      plan: {
        plan_name: "customPlan",
        plan_type: "customPlan",
        locations_limit: customForm.locationLimit,
        ...(modules.find((module) => module.name === "Marketplace")?.isChecked && { marketplace_items_limit: customForm.itemsLimit }),
        emails_limit: customForm.emailLimit,
        sms_limit: customForm.smsLimit,
        secondary_users_limit: customForm.secondaryUser,
        monthly_grand_total: monthlyTotal,
        quarterly_grand_total: quarterlyTotal,
        yearly_grand_total: yearlyTotal,
        plan_amount: gTotal,
        fk_shop_id: shop_id,
        ...(subscriptionDetails[0]?.locations_limit > customForm.locationLimit && { locations: JSON.stringify(customForm.locations) }), // JSON.stringify({ locations: customForm.locations }),
        ...(subscriptionDetails[0]?.secondary_users_limit > customForm.secondaryUser && { secondary_users: JSON.stringify(customForm.secondary_users) }), // JSON.stringify({ secondary_users: customForm.secondary_users }),
      },
      modules: extractIds(modules),
      ...(modules.find((module) => module.name === "Marketplace")?.isChecked && { themes: extractIds(themes) }),
    };
    setFormValidate(reqObj?.modules.length > 0 && (modules.find((module) => module.name === "Marketplace")?.isChecked ? reqObj?.themes.length > 0 : true) && Object.values(reqObj?.plan).every((value) => value > 0 || value !== ""));
  }, [customForm, monthlyTotal, quarterlyTotal, yearlyTotal, gTotal, modules, themes]);
  const extractIds = (data) => {
    const ids = [];
    data.forEach((mainObj) => {
      if (mainObj.isChecked) {
        ids.push(mainObj.id);
      }
      mainObj?.subModules?.forEach((submodule) => {
        if (submodule.isChecked) {
          ids.push(submodule.id);
        }
      });
    });
    return ids;
  };

  useEffect(() => {
    if (customForm.frequency.value == "monthly") {
      setPayable(monthlyTotal);
    } else if (customForm.frequency.value == "yearly") {
      setPayable(yearlyTotal);
    } else {
      setPayable(quarterlyTotal);
    }
  }, [monthlyTotal, yearlyTotal, quarterlyTotal, customForm]);

  const handleChangeSelect = (e, name) => {
    setCustomForm({ ...customForm, [name]: e });
  };
  const handleRequest = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }
    setValidated(true);
    setPaynowValidated(true);

    const formData = new FormData();
    formData.append("riciept", paymentObj.imageURl);

    try {
      // if (Object.values(paymentObj).every((value) => value > 0 || value !== "")) {

      const res = await axios.post(process.env.REACT_APP_URL_IMAGE_CDN + `/images/transactions/${shop_id}`, formData, { withCredentials: true });
      const reqObj = {
        plan: {
          plan_name: `customPlan-${shop_id}`,
          plan_type: "customPlan",
          locations_limit: customForm.locationLimit,
          ...(modules.find((module) => module.name === "Marketplace")?.isChecked && { marketplace_items_limit: customForm.itemsLimit }),
          emails_limit: customForm.emailLimit,
          sms_limit: customForm.smsLimit,
          secondary_users_limit: customForm.secondaryUser,
          monthly_grand_total: monthlyTotal,
          quarterly_grand_total: quarterlyTotal,
          yearly_grand_total: yearlyTotal,
          plan_amount: gTotal,
          fk_shop_id: shop_id,
          billing_frequency: customForm.frequency.value,
          locations: JSON.stringify(customForm.locations.map((location) => location.value)),
          secondaryUsers: JSON.stringify(customForm.secondary_users.map((user) => user.value)),
        },
        modules: extractIds(modules),
        ...(modules.find((module) => module.name === "Marketplace")?.isChecked && { themes: extractIds(themes) }),
        ...((Object.values(paymentObj).every((value) => value > 0 || value !== "") || paymentTime === "pay-now") && {
          transaction: {
            transaction_image_url: res?.data?.files[0],
            credit: payable,
            arrears: 0,
            transaction_id: paymentObj.transactionId,
            transaction_date: paymentObj.transDate,
          },
        }),
      };

      if (
        reqObj.modules.length > 0 &&
        (modules.find((module) => module.name === "Marketplace")?.isChecked ? reqObj?.themes.length > 0 : true) &&
        Object.values(reqObj.plan).every((value) => value > 0 || value !== "") &&
        (paymentTime === "pay-now" ? paymentObj.imageURl !== "" && Object.values(reqObj.transaction).every((value) => value > 0 || value !== "") : true)
      ) {
        // if (paymentTime !== "pay-now") {
        const res = await axios.post(process.env.REACT_APP_API_URL + `/plan-requests/add`, reqObj, { withCredentials: true });
        if (res.data.message) {
          setSuccess(res.data.message);
          setModalShow(true);
          setTimeout(() => {
            setModalShow(false);
          }, 1000);
          setValidated(false);
          setCustomForm({
            ...customForm,
            secondaryUser: "",
            marketplaceThemes: "",
            emailLimit: "",
            smsLimit: "",
            locationLimit: "",
            itemsLimit: "",
          });
          setSelectedLocations([]);
          setPaymentObj({
            ...paymentObj,
            imageURl: "",
            transactionId: "",
            transDate: "",
            frequency: "",
          });

          setPaymentTime("pay-later");
          setQuarterlyTotal("");
          setYearlyTotal("");
          setMonthlyTotal("");
          // setModules(...modules.map((item) => ({ ...item, isChecked: false })));
          // setThemes(...themes.map((item) => ({ ...item, isChecked: false })));
        }
        if (res.data.error) {
          setError(res.data.error);
          setModalShow(true);
          setTimeout(() => {
            setModalShow(false);
          }, 3000);
          setValidated(false);
        }
        // }
      }
    } catch (error) {
      setError("Something Went Wrong!");
      setModalShow(true);
      setTimeout(() => {}, 2000);
    }
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    setRequestForm({ ...requestForm, [name]: value });
  };

  useEffect(() => {
    setCheckedLocations(selectedLocations.filter((i) => i.isChecked === true));
  }, [selectedLocations]);

  useEffect(() => {
    setCheckedLocationsFalse(selectedLocations.filter((i) => i.isChecked === false));
  }, [selectedLocations]);

  const handleLocations = (e, i) => {
    let values = [...selectedLocations];
    values[i]["id"] = e.target.value;
    values[i]["isChecked"] = e.target.checked;
    setSelectedLocations(values);
  };
  function fetchModules() {
    axios
      .get(process.env.REACT_APP_API_URL + "/modules/all", { withCredentials: true })
      .then((res) => {
        setModules(
          res.data.map((item) => {
            // if (item.name === "Dashboard") {
            //   return { ...item, isChecked: true };
            // } else {
            return item;
            // }
          })
        );
      })
      .catch((err) => {
        throw new Error(err);
      });
  }

  const fetchSubModulesData = async (id) => {
    setP_id(id);
    // setSubModulesOptions(modulesOptions[id].subModules);
  };

  const handleCheckboxChange = (e, index) => {
    setP_id(index);
    if (e.target.checked) {
      setModules((prev) => {
        return prev.map((item, i) => {
          if (i === index) {
            setCustomPrices((prev) => ({
              ...prev,
              modulesTotal: prev.modulesTotal + item.price,
            }));
            return { ...item, isChecked: e.target.checked };
          }
          return item;
        });
      });
    } else {
      setModules((prev) => {
        return prev.map((item, i) => {
          if (i === index) {
            item.isChecked = false;
            setCustomPrices((prev) => ({
              ...prev,
              modulesTotal: prev.modulesTotal - item.price,
            }));
            item.subModules.map((item, i) => {
              if ("isChecked" in item) {
                item.isChecked = false;
              }
            });
          }
          return item;
        });
      });
    }
  };
  const handleSubmoduleCheckboxChange = (e, index, pr_id) => {
    if (e.target.checked) {
      if (modules[pr_id]?.isChecked ? modules[pr_id]?.isChecked : false) {
        setModules((prevModulesOptions) => {
          return prevModulesOptions.map((module, i) => {
            if (i === pr_id) {
              setCustomPrices((prev) => ({
                ...prev,
                modulesTotal: prev.modulesTotal + module.price,
              }));
              return {
                ...module,
                subModules: module.subModules.map((item, subIndex) => {
                  if (subIndex === index) {
                    return { ...item, isChecked: e.target.checked };
                  }
                  return item;
                }),
              };
            }
            return module;
          });
        });
      } else {
        alert("Please select Parent Module first");
      }
    } else {
      setModules((prevModulesOptions) => {
        return prevModulesOptions.map((module, i) => {
          if (i === pr_id) {
            setCustomPrices((prev) => ({
              ...prev,
              modulesTotal: prev.modulesTotal - module.price,
            }));
            return {
              ...module,
              subModules: module.subModules.map((item, subIndex) => {
                if (subIndex === index) {
                  return { ...item, isChecked: false };
                }
                return item;
              }),
            };
          }
          return module;
        });
      });
    }
  };

  useEffect(() => {
    fetchModules();
  }, []);

  const handleThemeCheckboxChange = (e, index) => {
    if (e.target.checked) {
      setThemes((prev) => {
        return prev.map((item, i) => {
          if (i === index) {
            setCustomPrices((prev) => ({
              ...prev,
              themesTotal: prev.themesTotal + item.price,
            }));
            return { ...item, isChecked: e.target.checked };
          }

          return item;
        });
      });
    } else {
      setThemes((prev) => {
        return prev.map((item, i) => {
          if (i === index) {
            item.isChecked = false;
            setCustomPrices((prev) => ({
              ...prev,
              themesTotal: prev.themesTotal - item.price,
            }));
          }
          return item;
        });
      });
    }
  };

  return (
    <Form onSubmit={handleRequest} noValidate validated={validated}>
      {
        <PopUpModal
          title={success ? success : error}
          icon={<FontAwesomeIcon icon={success ? faCircleCheck : faCircleXmark} />}
          text={success ? success : error}
          type={success ? styleModal.successBg : styleModal.errorBg}
          iconModal={success ? styleModal.successIcon : styleModal.errorIcon}
          show={modalShow}
          onHide={() => {
            setModalShow(false);
          }}
        />
      }

      <>
        <Row className="d-flex justify-content-center">
          <Col lg={8}>
            <Row className="mt-3 bg-white p-3 mb-2 ">
              {paymentTime === "pay-later" ? (
                <>
                  {" "}
                  <h5 className="text-center mb-4">Custom Plan</h5>
                  <Col lg="4" sm="6" xs="6">
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label className="mb-1">
                        Secondary users Limit <span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        onChange={(e) => {
                          if ((e.target.value === "" || e.target.value.match(reg)) && e.target.value.length < 4) {
                            handleCustomForm(e);
                            handlePrices(e, "secondaryUserTotal");
                          }
                        }}
                        type="number"
                        placeholder="Enter no of user"
                        name="secondaryUser"
                        value={customForm.secondaryUser}
                        required
                      />
                      <small id="emailHelp" class="form-text text-muted ">
                        Max 3 digits
                      </small>
                      <Form.Control.Feedback type="invalid">Please Enter value.</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={4} sm="6" xs={6} className="py-2">
                    <Form.Group className="w-100" controlId="formBasicShopName">
                      <label>
                        Emails Limit <span className="text-danger">*</span>
                      </label>
                      <Form.Control
                        value={customForm.emailLimit}
                        name="emailLimit"
                        onChange={(e) => {
                          if ((e.target.value === "" || e.target.value.match(reg)) && e.target.value.length < 6) {
                            handleCustomForm(e);
                            handlePrices(e, "emailTotal");
                          }
                        }}
                        type="number"
                        placeholder="eg.1000"
                        required
                      />
                      <small id="emailHelp" class="form-text text-muted ">
                        Max 5 digits
                      </small>
                      <Form.Control.Feedback type="invalid">Please Enter value.</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md="6" sm="4" xs={6} lg="4" className="py-2">
                    <Form.Group className="w-100" controlId="formBasicShopName">
                      <label>
                        Locations Limit <span className="text-danger">*</span>
                      </label>
                      <Form.Control
                        value={customForm.locationLimit}
                        name="locationLimit"
                        onChange={(e) => {
                          if ((e.target.value === "" || e.target.value.match(reg)) && e.target.value.length < 4) {
                            handleCustomForm(e);
                            handlePrices(e, "locationLimitTotal");
                          }
                        }}
                        type="number"
                        placeholder="eg.1000"
                        required
                      />
                      <small id="emailHelp" class="form-text text-muted ">
                        Max 3 digits
                      </small>
                      <Form.Control.Feedback type="invalid">Please Enter value.</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col className="py-2" sm="6" md={6} lg="4">
                    <Form.Group className="mb-3" controlId="price">
                      <label>
                        Sms Limit <span className="text-danger">*</span>
                      </label>
                      <Form.Control
                        value={customForm.smsLimit}
                        name="smsLimit"
                        onChange={(e) => {
                          if ((e.target.value === "" || e.target.value.match(reg)) && e.target.value.length < 6) {
                            handleCustomForm(e);
                            handlePrices(e, "smsTotal");
                          }
                        }}
                        type="number"
                        placeholder="eg.1000"
                        required
                      />
                      <small id="emailHelp" class="form-text text-muted ">
                        Max 5 digits
                      </small>
                      <Form.Control.Feedback type="invalid">Please Enter value.</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  {modules.find((module) => module.name === "Marketplace")?.isChecked && (
                    <Col lg={4} sm="6" xs={6} className="py-2">
                      <Form.Group className="w-100" controlId="formBasicShopName">
                        <label>
                          MarketPlace Items Limit <span className="text-danger">*</span>
                        </label>
                        <Form.Control
                          value={customForm.itemsLimit}
                          name="itemsLimit"
                          onChange={(e) => {
                            if ((e.target.value === "" || e.target.value.match(reg)) && e.target.value.length < 6) {
                              handleCustomForm(e);
                              handlePrices(e, "itemsTotal");
                            }
                          }}
                          type="number"
                          placeholder="eg.1000"
                          required
                        />
                        <small id="emailHelp" class="form-text text-muted ">
                          Max 5 digits
                        </small>
                        <Form.Control.Feedback type="invalid">Please Enter value.</Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  )}
                  <Col className="" sm="4" md={6} lg="4">
                    <Form.Group className="my-2">
                      <label>
                        Frequency <span className="text-danger">*</span>
                      </label>
                      <SearchableSelect
                        placeholder="Select frequency "
                        selectClassName={"bg-white"}
                        value={customForm?.frequency || ""}
                        searchable={false}
                        options={[
                          { value: "monthly", label: "Monthly" },
                          { value: "quarterly", label: "Quarterly" },
                          { value: "yearly", label: "Yealy" },
                        ]}
                        handleChange={(e) => {
                          handleChangeSelect(e, "frequency");
                        }}
                      />
                      <Form.Control className="p-2" name="city" hidden type="text" value={Object.keys(customForm?.frequency)?.length > 0 ? customForm.frequency?.value : null} onChange={() => {}} required />
                      <Form.Control.Feedback type="invalid">* Please Select frequency</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  {customForm.locationLimit !== "" && subscriptionDetails[0]?.consumed_locations > customForm.locationLimit && (
                    <Col className="" sm="4" md={6}>
                      <Form.Group className="my-2">
                        <label>Locations To Keep</label>
                        <SearchableSelect
                          searchable={true}
                          placeholder="Select Locations "
                          multiselect={true}
                          selectClassName={"bg-white"}
                          value={customForm?.locations || ""}
                          options={locations}
                          handleChange={(e) => {
                            if (customForm.locationLimit.length === subscriptionDetails[0]?.consumed_locations) {
                            }
                            handleChangeSelect(e, "locations");
                          }}
                        />
                        <Form.Control className="p-2" name="city" hidden type="text" value={Object.keys(customForm?.locations)?.length > 0 ? customForm.locations : null} onChange={() => {}} required />
                        {/* <Form.Control.Feedback type="invalid">* Please Select locations</Form.Control.Feedback> */}
                        <small id="emailHelp" class="form-text text-muted ">
                          Have to select atleast {customForm.locationLimit} Locations for this plan
                        </small>
                      </Form.Group>
                    </Col>
                  )}
                  {customForm.secondaryUser !== "" && subscriptionDetails[0]?.consumed_secondary_users > customForm.secondaryUser && (
                    <Col className="" sm="4" md={6}>
                      <Form.Group className="my-2">
                        <label>Employees To Keep</label>
                        <SearchableSelect
                          searchable={true}
                          placeholder="Select Employees "
                          multiselect={true}
                          selectClassName={"bg-white"}
                          value={customForm.secondary_users || ""}
                          options={secondaryUsers}
                          handleChange={(e) => {
                            handleChangeSelect(e, "secondary_users");
                          }}
                        />
                        <Form.Control className="p-2" name="city" hidden type="text" value={Object.keys(customForm?.secondary_users)?.length > 0 ? customForm.secondary_users : null} onChange={() => {}} required />
                        {/* <Form.Control.Feedback type="invalid">* Please Select employees</Form.Control.Feedback> */}
                        <small id="emailHelp" class="form-text text-muted ">
                          Have to select atleast {customForm.secondaryUser} employees for this plan
                        </small>
                      </Form.Group>
                    </Col>
                  )}
                  <Form.Group className="w-100 border-bottom pb-3 " controlId="formBasicShopName">
                    <Form.Label> Associated Modules</Form.Label>
                    <div className="d-flex gap-3 flex-wrap">
                      {modules.map((item, index) => (
                        <div className="d-flex flex-column  ">
                          <div style={{ maxHeight: "50px" }} onClick={() => fetchSubModulesData(index)} className=" mb-2 d-flex gap-3 p-2 bg-light align-items-center ">
                            <Form.Check
                              key={index}
                              className={""}
                              onChange={(e) => {
                                handleCheckboxChange(e, index);
                              }}
                              name={item?.name}
                              checked={item.isChecked}
                              type="checkbox"
                              id={`${index}`}
                            />
                            <label
                              className=""
                              style={{ fontSize: "14px" }}
                              //  htmlFor={`${index}`}
                            >
                              {item.name}
                            </label>
                          </div>
                          {item?.subModules.length > 0 ? (
                            <Form.Group className="w-100" controlId="formBasicShopName">
                              <div className="d-grid ms-2 ">
                                {item?.subModules?.map((item, i) => (
                                  <Form.Check
                                    key={i}
                                    className={`${style.Checkbox}`}
                                    onChange={(e) => {
                                      handleSubmoduleCheckboxChange(e, i, index);
                                    }}
                                    name={item.name}
                                    checked={item?.isChecked ? item.isChecked : false}
                                    type="checkbox"
                                    id={`${index}-${p_id}`}
                                    label={`${item.name}`}
                                  />
                                ))}
                              </div>
                            </Form.Group>
                          ) : (
                            ""
                          )}
                        </div>
                      ))}
                    </div>
                    {validated && extractIds(modules).length === 0 && (
                      <p className="text-danger text-center" type="valid">
                        Please select atleast one module
                      </p>
                    )}
                  </Form.Group>
                  {modules.find((module) => module.name === "Marketplace")?.isChecked && (
                    <Form.Group className="w-100 mt-2 mb-3 border-bottom pb-3 " controlId="formBasicShopName">
                      <Form.Label> Associated Themes</Form.Label>
                      <div className="d-flex flex-wrap gap-3">
                        {themes?.map((item, index) => (
                          <div className="d-grid ">
                            <Form.Check
                              key={index}
                              className={""}
                              onChange={(e) => {
                                handleThemeCheckboxChange(e, index);
                              }}
                              name={item?.name}
                              checked={item?.isChecked ? item.isChecked : false}
                              type="checkbox"
                              id={`${index}-${p_id}`}
                              label={`${item.theme_name}`}
                            />
                            <img src={` ${process.env.REACT_APP_URL_S3 + "/" + item.thumbnail}`} height="150px" width="150px" alt="" />
                            <a href={process.env.REACT_APP_URL_S3 + "/" + item.thumbnail} target="_blank" className="text-center ">
                              Preview
                            </a>
                          </div>
                        ))}
                      </div>
                      {validated && extractIds(themes).length === 0 && (
                        <p className="text-danger text-center" type="valid">
                          Please select atleast one theme
                        </p>
                      )}
                    </Form.Group>
                  )}
                  <Col className="py-2" md={4} xs={4}>
                    <Form.Group className="mb-3" controlId="price">
                      <Form.Label>
                        Monthly Total <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control value={Math.round(monthlyTotal)} type="number" placeholder="Rs.1000" disabled />
                      <Form.Control.Feedback type="invalid">Please Enter Amount of Purchase you make with this vendor.</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={4} xs={4} className="py-2">
                    <Form.Group className="mb-3" controlId="price">
                      <Form.Label>
                        Quarterly Total <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control value={Math.round(quarterlyTotal)} type="number" placeholder="Rs.1000" disabled />
                      <Form.Control.Feedback type="invalid">Please Enter Amount of Purchase you make with this vendor.</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col className="py-2" md={4} xs={4}>
                    <Form.Group className="mb-3" controlId="price">
                      <Form.Label>
                        Yearly Total<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control value={Math.round(yearlyTotal)} type="number" placeholder="Rs.1000" disabled />
                      <Form.Control.Feedback type="invalid">Please Enter Amount of Purchase you make with this vendor.</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </>
              ) : (
                <Paynow payable={payable} setPayable={setPayable} setPaymentObj={setPaymentObj} paymentObj={paymentObj} monthlyTotal={monthlyTotal} quarterlyTotal={quarterlyTotal} yearlyTotal={yearlyTotal} />
              )}
              <Col md={12} xs={12} className="py-2 d-flex justify-content-end  ">
                <div className="d-flex w-50 justify-content-end mx-2 gap-2 ">
                  {" "}
                  {paymentTime === "pay-later" ? (
                    <>
                      {" "}
                      <Button onClick={() => setPaymentTime("pay-later")} className="w-100" type="submit">
                        Pay later
                      </Button>
                      <Button
                        type="submit"
                        onClick={() => {
                          setPaymentTime("pay-now");
                          setValidated(false);
                        }}
                        disabled={formValidate ? false : true}
                        variant="secondary"
                        className=" btn btn-secondary w-100"
                      >
                        Pay now <FontAwesomeIcon icon={faArrowAltCircleRight} />
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button onClick={() => setPaymentTime("pay-later")} className="w-100" type="submit">
                        Back
                      </Button>
                      <Button disabled={paynowValidated} type="submit" variant="secondary" className=" btn btn-  w-100">
                        Submit
                      </Button>
                    </>
                  )}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    </Form>
  );
};

export default CustomPlan;
