import { useState } from "react";
import { Form, Row, Col, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import SearchableSelect from "../../Components/SearchableSelect";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import axios from "axios";
import style from "./../../styles/ResetPassword.module.css";
import { faEye, faEyeSlash, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PhoneInput from "../../Components/PhoneInput/PhoneInput";

const AddUser = ({ close }) => {
  const { user } = useSelector((state) => state.auth);
  const location = useSelector((state) => state.location.location);
  const shopId = user.shop_id;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [number, setNumber] = useState();
  const [alternativenumber, setAlternativeNumber] = useState();
  // const [location, setLocation] = useState({});
  const [locationFields, setLocationFields] = useState([]);
  const [isInventory, setIsInventory] = useState(false);
  const [isSales, setIsSales] = useState(false);
  const [isCustomer, setIsCustomer] = useState(false);
  const [isMarketing, setIsMarketing] = useState(false);
  const [isPurchaseOrder, setIsPurchaseOrder] = useState(false);
  const [isMarketplace, setIsMarketplace] = useState(false);
  const [isTradeIn, setIsTradeIn] = useState(false);
  const [reportsCheck, setreportsCheck] = useState(false);
  const [isReports, setIsReports] = useState(false);
  const [isSettings, setIsSettings] = useState(false);
  const [isVendor, setIsVendor] = useState(false);
  const [isExpense, setIsExpense] = useState(false);
  const [role, setRole] = useState({});
  const [commission, setCommission] = useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [validated, setValidated] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [modules, setModules] = useState([]);
  const [p_id, setP_id] = useState();

  const [reportsCheckBoxes, setReportsCheckBoxes] = useState({
    isGeneralReport: false,
    isDailyCashReport: false,
    isDailyTransactionReport: false,
    isProfitLossReport: false,
    isInventoryReport: false,
    isSalesReport: false,
    isIMEIReport: false,
    isSalesSummary: false,
    isJournal: false,
    isBankTransactionsReport: false,
    isMiscItemsHistoryReport: false,
  });
  const [notificationsCheckBoxes, setNotificationsCheckBoxes] = useState({
    has_global: false,
    has_new_reservation: false,
  });
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + `/location/get-location/${shopId}`, {
        withCredentials: true,
      })
      .then((res) => {
        const getData = res.data.map((loc) => ({
          value: loc.location_id,
          label: loc.location_nick,
          isDisabled: new Date().toISOString() >= loc.active_till ? true : false,
        }));
        setLocationFields(getData);
      })
      .catch((err) => {
        throw new Error(err);
      });
  }, [shopId]);
  const handleRoleChange = (e) => {
    if (e.value) {
      setRole(e);
    } else {
      setRole({});
    }
  };

  const handleCommisionChange = (e) => {
    let value = e.target.value.toString();
    const regex = /^[0-9]+(\.[0-9]{0,2})?$/; // Regular expression to validate numbers or floating points up to 2 decimal places
    if (value.includes(".")) {
      const decimalIndex = value.indexOf(".");
      const decimalPart = value.substring(decimalIndex + 1);
      value = value.substring(0, decimalIndex + 1) + decimalPart.substring(0, 2); // Truncate decimal part to 2 places
    }
    if (regex.test(value) && parseFloat(value) <= 100) {
      setCommission(value);
    } else if (value === "") {
      setCommission("");
    } else {
      setCommission(100);
    }
  };

  const extractIds = (data) => {
    const ids = [];
    data.forEach((mainObj) => {
      if (mainObj.isChecked) {
        ids.push(mainObj.id);
      }
      mainObj.subModules.forEach((submodule) => {
        if (submodule.isChecked) {
          ids.push(submodule.id);
        }
      });
    });
    return ids;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
    }
    setValidated(true);

    try {
      setError("");
      const user = {
        user_full_name: name,
        user_email: email,
        password: password,
        whatsapp_number: number,
        backup_phone_number: alternativenumber,
        shop_id: shopId,
        user_type: "secondary",
        role: role?.label,
        commission: commission ? commission : 0,
      };

      const permissions = {
        Inventory: isInventory,
        Sales: isSales,
        Vendor: isVendor,
        Customer: true,
        Reports: isReports,
        MyExpenses: isExpense,
        MyMarketing: isMarketing,
        Settings: isSettings,
        PurchaseOrder: isPurchaseOrder,
        Marketplace: isMarketplace,
        TradeIn: isTradeIn,
        SubReports: isReports ? reportsCheckBoxes : {},
      };

      const locationUser = {
        location_id: location.value,
      };
      if (isReports && Object.values(reportsCheckBoxes).every((item) => item === false)) {
        setreportsCheck(true);
      }
      if (name && email && location.value && password && number && role?.label && (isReports && Object.values(reportsCheckBoxes).every((item) => item === false) ? false : true)) {
        setIsClicked(true);
        const res = await axios.post(
          process.env.REACT_APP_API_URL + `/user/add-secondary-user`,
          {
            user,
            permissions,
            notificationPermissions: notificationsCheckBoxes,
            location: locationUser,
            modules: extractIds(modules),
          },
          { withCredentials: true }
        );
        if (res.data) {
          setSuccess("User Added Successfully.");
          setTimeout(() => close(), 1000);
        }
      }
    } catch (error) {
      setIsClicked(true);
      setError(error.response?.data.error.message);
      setIsClicked(false);
    }
  };
  const handleReportsChange = (e, name) => {
    setreportsCheck(false);
    setReportsCheckBoxes({ ...reportsCheckBoxes, [name]: e.target.checked });
  };

  const handleNotificationsChange = (e, name) => {
    setNotificationsCheckBoxes({
      ...notificationsCheckBoxes,
      [name]: e.target.checked,
    });
  };
  const handleNotifications = (e) => {
    if (e.target.checked) {
      setNotificationsCheckBoxes((prev) => ({
        ...prev,
        has_global: true,
      }));
    } else {
      setNotificationsCheckBoxes({
        has_global: false,
        has_new_reservation: false,
      });
    }
  };

  function fetchModules() {
    setModules(user.modules);
  }

  useEffect(() => {
    fetchModules();
  }, []);

  const fetchSubModulesData = async (id) => {
    setP_id(id);
    // setSubModulesOptions(modulesOptions[id].subModules);
  };

  const handleCheckboxChange = (e, index) => {
    setP_id(index);
    if (e.target.checked) {
      setModules((prev) => {
        return prev.map((item, i) => {
          if (i === index) {
            return { ...item, isChecked: e.target.checked };
          }
          return item;
        });
      });
    } else {
      setModules((prev) => {
        return prev.map((item, i) => {
          if (i === index) {
            item.isChecked = false;
            item.subModules.map((item, i) => {
              if ("isChecked" in item) {
                item.isChecked = false;
              }
            });
          }
          return item;
        });
      });
    }
  };

  const handleSubmoduleCheckboxChange = (e, index, pr_id) => {
    if (modules[pr_id]?.isChecked ? modules[pr_id]?.isChecked : false) {
      setModules((prevModulesOptions) => {
        return prevModulesOptions.map((module, i) => {
          if (i === pr_id) {
            return {
              ...module,
              subModules: module.subModules.map((item, subIndex) => {
                if (subIndex === index) {
                  return { ...item, isChecked: e.target.checked };
                }
                return item;
              }),
            };
          }
          return module;
        });
      });
    } else {
      alert("Please select Parent Module first");
    }
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      {error && <p className="text-danger p-2 text-center my-1 rounded-1">*{error}</p>}
      {success && <p className="text-success p-2 text-center my-1 rounded-1">*{success}</p>}
      <Row>
        <Col lg="6">
          <Form.Group className="mb-3">
            <Form.Label className="mb-1">
              Full Name <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control className="p-2" type="text" name="name" placeholder="Full Name" value={name} onChange={(e) => setName(e.target.value)} required />
            <Form.Control.Feedback type="invalid">* Please Enter User Name</Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg="6">
          <Form.Group className="mb-3">
            <Form.Label className="mb-1">
              Email <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control className="p-2" type="text" name="name" autocomplete="new-password" placeholder="example@mail.com" pattern="[a-z0-9._+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" value={email} onChange={(e) => setEmail(e.target.value)} required />
            <Form.Control.Feedback type="invalid">* Invalid Email</Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg="6">
          <Form.Group className={`mb-4 ${style.passwordInput}`}>
            <Form.Label>
              Password <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control className={`p-2 ${style.passwordValidate}`} name="password" type={showPassword ? "text" : "password"} placeholder="******" required value={password} onChange={(e) => setPassword(e.target.value)} />
            <div className={`${style.passwordField}`}>
              <FontAwesomeIcon className="text-secondary" icon={showPassword ? faEye : faEyeSlash} onClick={() => setShowPassword((a) => !a)} />
            </div>
            <Form.Control.Feedback type="invalid">* Please Enter Password</Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg="6">
          <div className="mb-3">
            <SearchableSelect important label={"Location"} placeholder="Select Location" disabled={true} value={location} />
            <Form.Control hidden type="text" name="email" value={Object.values(location).length > 0 ? location : ""} onChange={() => {}} required />
            <Form.Control.Feedback type="invalid">* Please Select Location</Form.Control.Feedback>
          </div>
        </Col>
        <Col lg="6">
          <div className="mb-3">
            <SearchableSelect
              important
              label={"Role"}
              value={Object.values(role).length > 0 ? role : null}
              placeholder="Select Role"
              options={[
                { value: "Cashier", label: "Cashier" },
                { value: "sales Person", label: "Sales Person" },
              ]}
              handleChange={handleRoleChange}
              required
            />
            <Form.Control hidden type="text" name="role" value={Object.values(role).length > 0 ? role : null} onChange={() => {}} required />
            <Form.Control.Feedback type="invalid">* Please Select Role</Form.Control.Feedback>
          </div>
        </Col>
        <Col lg="6">
          {role?.label === "Sales Person" && (
            <Form.Group className="mb-3">
              <Form.Label className="mb-1">Commision(%)</Form.Label>
              <Form.Control
                className="p-2"
                type="number"
                name="commission"
                placeholder="Commission"
                value={commission}
                onChange={handleCommisionChange}
                onKeyDown={(e) => {
                  if (["e", "E", "+", "-"].includes(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
            </Form.Group>
          )}
        </Col>
        <Col lg="6">
          <Form.Group className="mb-3">
            <Form.Label className="mb-1">
              Phone No. <span style={{ color: "red" }}>*</span>
            </Form.Label>
            {/* <Form.Control className="p-2" type='number' name="number" {...bindField("number")} placeholder='xxx xxxxxxxx' value={number} onChange={(e)=> setNumber(e.target.value)} pattern="^((\\+92)?(0092)?(92)?(0)?)(3)([0-9]{2})(-||)([0-9]{7})$" required /> */}
            <PhoneInput className="p-2" placeholder="+92-3XX-XXXXXXX" value={number} onChange={(e) => setNumber(e.target.value)} required />
            <Form.Control.Feedback type="invalid">* Please Select Phone Number</Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg="6">
          <Form.Group className="mb-3">
            <Form.Label className="mb-1">Alternative Phone No.</Form.Label>
            {/* <Form.Control className="p-2" type='number' placeholder='xxx xxxxxxxx' value={alternativenumber} onChange={(e)=> setAlternativeNumber(e.target.value)} pattern="^((\\+92)?(0092)?(92)?(0)?)(3)([0-9]{2})(-||)([0-9]{7})$" /> */}
            <PhoneInput className="p-2" placeholder="+92-3XX-XXXXXXX" value={alternativenumber} onChange={(e) => setAlternativeNumber(e.target.value)} />
          </Form.Group>
        </Col>
        {/* <div className='mb-3'>
                <Select label={'Permission Type'} placeholder="Specific" />
            </div> */}
      </Row>

      <Row className="py-2 mb-2">
        <Col lg="12 " className={`mb-2 `}>
          <Form.Group className="w-100" controlId="formBasicShopName">
            <Form.Label> Associated Modules</Form.Label>

            <div className="d-flex gap-3 flex-wrap">
              {modules.map(
                (item, index) =>
                  item.isIncluded && (
                    <div className="d-flex flex-column  ">
                      <div style={{ maxHeight: "50px" }} onClick={() => fetchSubModulesData(index)} className=" mb-2 d-flex gap-3 p-2 bg-light align-items-center ">
                        <Form.Check
                          key={index}
                          className={""}
                          onChange={(e) => {
                            handleCheckboxChange(e, index);
                          }}
                          name={item?.name}
                          checked={item.isChecked}
                          type="checkbox"
                          id={`${index}`}
                        />
                        <label
                          className=""
                          style={{ fontSize: "14px" }}
                          //  htmlFor={`${index}`}
                        >
                          {item.name}
                        </label>
                      </div>
                      {item?.subModules.length > 0 ? (
                        <Form.Group className="w-100" controlId="formBasicShopName">
                          <div className="d-grid ms-2 ">
                            {item?.subModules?.map(
                              (item, i) =>
                                item.type !== "common" && (
                                  <Form.Check
                                    key={i}
                                    className={`${style.Checkbox}`}
                                    onChange={(e) => {
                                      handleSubmoduleCheckboxChange(e, i, index);
                                    }}
                                    name={item.name}
                                    checked={item.isChecked ? item.isChecked : false}
                                    type="checkbox"
                                    id={`${index}-${p_id}`}
                                    label={`${item.name}`}
                                  />
                                )
                            )}
                          </div>
                        </Form.Group>
                      ) : (
                        ""
                      )}
                    </div>
                  )
              )}
            </div>
          </Form.Group>
        </Col>
      </Row>
      {/* {notificationsCheckBoxes.has_global ? ( */}
      <>
        <hr className="mt-1 mb-1" />
        <Row className="py-2 mb-2">
          <label className="fs-5 fw-bold mb-1">Notifications</label>
          <Col>
            <Form.Group>
              <Form.Check type="checkbox" label="Reservation Notifications" checked={notificationsCheckBoxes.has_new_reservation} onChange={(e) => handleNotificationsChange(e, "has_new_reservation")} />
            </Form.Group>
          </Col>
        </Row>
      </>
      {/* ) : (
        ""
      )} */}

      {isReports ? (
        <>
          {" "}
          <hr className="mt-1 mb-1" />
          <Row className="py-2 mb-2">
            <label className="fs-5 fw-bold mb-1">Reports</label>
            <Col lg={"6"}>
              <Form.Group>
                <Form.Check type="checkbox" label="General Report" checked={reportsCheckBoxes.isGeneralReport} onChange={(e) => handleReportsChange(e, "isGeneralReport")} />
              </Form.Group>
            </Col>
            <Col lg={"6"}>
              <Form.Group>
                <Form.Check type="checkbox" label="Daily Cash Report" checked={reportsCheckBoxes.isDailyCashReport} onChange={(e) => handleReportsChange(e, "isDailyCashReport")} />
              </Form.Group>
            </Col>
            <Col lg={"6"}>
              <Form.Group>
                <Form.Check type="checkbox" label="Daily Transaction Report" checked={reportsCheckBoxes.isDailyTransactionReport} onChange={(e) => handleReportsChange(e, "isDailyTransactionReport")} />
              </Form.Group>
            </Col>
            <Col lg={"6"}>
              <Form.Group>
                <Form.Check type="checkbox" label="Profit/Loss Report" checked={reportsCheckBoxes.isProfitLossReport} onChange={(e) => handleReportsChange(e, "isProfitLossReport")} />
              </Form.Group>
            </Col>
            <Col lg={"6"}>
              <Form.Group>
                <Form.Check type="checkbox" label="Inventory Report" checked={reportsCheckBoxes.isInventoryReport} onChange={(e) => handleReportsChange(e, "isInventoryReport")} />
              </Form.Group>
            </Col>
            <Col lg={"6"}>
              <Form.Group>
                <Form.Check type="checkbox" label="Sales Report" checked={reportsCheckBoxes.isSalesReport} onChange={(e) => handleReportsChange(e, "isSalesReport")} />
              </Form.Group>
            </Col>
            <Col lg={"6"}>
              <Form.Group>
                <Form.Check type="checkbox" label="IMEI Report" checked={reportsCheckBoxes.isIMEIReport} onChange={(e) => handleReportsChange(e, "isIMEIReport")} />
              </Form.Group>
            </Col>
            <Col lg={"6"}>
              <Form.Group>
                <Form.Check type="checkbox" label="Sales Summary Report" checked={reportsCheckBoxes.isSalesSummary} onChange={(e) => handleReportsChange(e, "isSalesSummary")} />
              </Form.Group>
            </Col>
            <Col lg={"6"}>
              <Form.Group>
                <Form.Check type="checkbox" label="Journal Report" checked={reportsCheckBoxes.isJournal} onChange={(e) => handleReportsChange(e, "isJournal")} />
              </Form.Group>
            </Col>
            <Col lg={"6"}>
              <Form.Group>
                <Form.Check type="checkbox" label="Bank Transaction Report" checked={reportsCheckBoxes.isBankTransactionsReport} onChange={(e) => handleReportsChange(e, "isBankTransactionsReport")} />
              </Form.Group>
            </Col>
            <Col lg={"6"}>
              <Form.Group>
                <Form.Check type="checkbox" label="Misc Items History Report" checked={reportsCheckBoxes.isMiscItemsHistoryReport} onChange={(e) => handleReportsChange(e, "isMiscItemsHistoryReport")} />
              </Form.Group>
            </Col>
          </Row>
        </>
      ) : (
        ""
      )}
      {reportsCheck && <p className="text-danger ">select atleast one report</p>}
      <Button disabled={isClicked} type="submit" className="w-100">
        Save
      </Button>
    </Form>
  );
};

export default AddUser;
