import React from "react";
import { Col, Row, Container, Button } from "react-bootstrap";
import lineIcon from "../assets/svg/line.svg";
import style from "../styles/Reports.module.css";
import DatePicker from "../Components/DatePicker/DatePicker";
import { useState, useEffect, useRef } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import styles from "../styles/Setting.module.css";
import DailyCashReport from "../Components/Reports/DailyCashReport";
import DailyTransactionalReport from "../Components/Reports/DailyTransactionalReport";
import ReactToPrint from "react-to-print";
import print from "../assets/svg/printIcon.svg";
import PrintDailyCashReport from "./../Components/Reports/PrintDailyCashReport";
import GeneralReport from "../Components/Reports/GeneralReport";
import ProfitLossReport from "../Components/Reports/ProfitLossRepor";
import InventoryReports from "../Components/Reports/InventoryReports/InventoryReports";
import SalesReports from "../Components/Reports/SalesReports/SalesReports";
import ImeiReport from "../Components/Reports/ImeiReport";
import filterStyle from "../styles/filtersButtons.module.css";
import filterr from "../assets/svg/filter.svg";
import MobileReportsTabs from "../Components/Reports/MobileReportsTabs";
import MobilrReportsFilter from "../Components/Reports/MobileReportsFilter";
import SalesSummary from "../Components/Reports/SalesReports/SalesSummary";
import Journal from "../Components/Reports/Journal";
import BankTransactionsReport from "../Components/Reports/BankTransactionsReport";
import MiscItemsHistoryReport from "../Components/Reports/MiscItemsHistoryReport";

const Reports = () => {
  const [activeTAb, setActiveTAb] = useState("General-Report");
  const [openingBalance, setOpeningBalance] = useState();
  const [cashInHandProp, setCashInHandProp] = useState();
  const [grandTotalProp, setGrandTotalProp] = useState();
  const [totalSalesPaymentDataProp, setTotalSalesPaymentDataProp] = useState([]);
  const [totalVendorPaymentsDataProp, setTotalVendorPaymentsDataProp] = useState([]);
  const [totalExpensesDataProp, setTotalExpensesDataProp] = useState([]);
  const [totalRefundsDataProp, setTotalRefundsDataProp] = useState([]);
  const [totalWithdrawAmountProp, setTotalWithdrawAmountProp] = useState();
  const [totalDepositeAmountProp, setTotalDepositeAmountProp] = useState();
  const [showMobileTabs, setShowMobileTabs] = useState(false);
  const [showMobileFilter, setShowMobileFilter] = useState(false);

  const handleFilterClose = () => setShowMobileFilter(false);
  const handleFilterShow = () => setShowMobileFilter(true);
  const handleClose = () => setShowMobileTabs(false);
  const handleShow = () => setShowMobileTabs(true);

  const [mobileTabs, setMobileTabs] = useState(false);
  const [noRegisterCashReportProp, setNoRegisterCashReportProp] = useState(true);

  const componentRef = useRef(null);

  const [fromDate, setFromDate] = useState(moment().subtract(29, "days").startOf("day")._d);
  const [toDate, setToDate] = useState(moment().endOf("day")._d);

  const { user } = useSelector((state) => state.auth);
  const permissions = user;
  function getKeyWithTrueValue(arr) {
    const objWithTrueValue = arr.find((obj) => Object.values(obj).includes(true));
    if (objWithTrueValue) {
      return Object.keys(objWithTrueValue).find((key) => objWithTrueValue[key] === true);
    }
    return null; // Return null if no key has a true value
  }

  // useEffect(() => {
  //   let conditionMet = false;
  //   if (permissions.SubReports.isGeneralReport && !conditionMet) {
  //     handleTabChange("General-Report");
  //     conditionMet = true;
  //   } else if (permissions.SubReports.isDailyCashReport && !conditionMet) {
  //     handleTabChange("Daily-Cash-Report");
  //     conditionMet = true;
  //   } else if (
  //     permissions.SubReports.isDailyTransactionReport &&
  //     !conditionMet
  //   ) {
  //     handleTabChange("Daily-Transactional-Report");
  //     conditionMet = true;
  //   } else if (permissions.SubReports.isProfitLossReport && !conditionMet) {
  //     handleTabChange("Profit/Loss-Report");
  //     conditionMet = true;
  //   } else if (permissions.SubReports.isInventoryReport && !conditionMet) {
  //     handleTabChange("Inventory-Report");
  //     conditionMet = true;
  //   } else if (permissions.SubReports.isSalesReport && !conditionMet) {
  //     handleTabChange("Sales-Report");
  //     conditionMet = true;
  //   } else if (permissions.SubReports.isIMEIReport && !conditionMet) {
  //     handleTabChange("IMEI-Report");
  //     conditionMet = true;
  //   } else if (permissions.SubReports.isSalesSummary && !conditionMet) {
  //     handleTabChange("Sales-Summary");
  //     conditionMet = true;
  //   } else if (permissions.SubReports.isJournal && !conditionMet) {
  //     handleTabChange("Journal");
  //     conditionMet = true;
  //   }
  // }, []);

  const shop_id = user.shop_id;
  const userId = user.user_id;
  const shopName = user.shop_name;

  const handleTabChange = (tab) => {
    setActiveTAb(tab);
  };
  return (
    <>
      {showMobileTabs === true ? (
        <MobileReportsTabs handleTabChange={handleTabChange} activeTAb={activeTAb} show={showMobileTabs} handleClose={handleClose} />
      ) : (
        <>
          <div className="d-none">
            <PrintDailyCashReport
              ref={componentRef}
              totalSalesPaymentData={totalSalesPaymentDataProp}
              totalVendorPaymentsData={totalVendorPaymentsDataProp}
              totalExpensesData={totalExpensesDataProp}
              totalRefundsData={totalRefundsDataProp}
              totalDepositeAmount={totalDepositeAmountProp}
              totalWithdrawAmount={totalWithdrawAmountProp}
              openingBalanceData={openingBalance}
            />
          </div>
          <Container fluid>
            <Row className="py-3 d-none d-lg-flex d-md-flex d-sm-flex  ">
              <Col xs="6" sm="6" lg="6" className="mb-3">
                <span className={`${style.soldproducts}`}>Reports</span>
              </Col>
              {activeTAb === "Daily-Cash-Report" && !noRegisterCashReportProp ? (
                <Col md="4" lg="4" xl="1" xs="6" sm="6" className={`d-flex justify-content-end d-flex d-sm-none  `}>
                  <ReactToPrint
                    trigger={() => (
                      <Button variant="outline-primary fs-14" style={{ height: "38px" }}>
                        <img src={print} alt="removeIcon" className="me-2" style={{ marginTop: "-3px" }} />
                        Print
                      </Button>
                    )}
                    content={() => componentRef.current}
                    documentTitle="Daily Cash Report"
                  >
                    {" "}
                  </ReactToPrint>
                </Col>
              ) : (
                ""
              )}

              {/* {activeTAb === "General-Report" ? (
            <>
              <hr className="d-inline d-lg-none" />
              <Col xs={12} md="6" lg="6">
                <div
                  className={`d-flex align-items-center ms-auto ${style.dateFilter}`}
                >
                  <label className="me-4">Period Type:</label>
                  <DatePicker
                    fromDate={fromDate}
                    toDate={toDate}
                    setFromDate={setFromDate}
                    setToDate={setToDate}
                  />
                </div>
              </Col>
            </>
          ) : (
            ""
          )} */}
            </Row>

            <div className="d-flex gap-3 mb-2 justify-content-center d-lg-none d-md-none d-sm-none ">
              <Button onClick={handleShow} variant="outline-primary" className={`${filterStyle.filterToggleButton} d-flex justify-content-between align-items-center w-100`}>
                <span className=" ms-2 d-lg-inline d-sm-inline d-inline ">{activeTAb.replaceAll("-", " ")}</span>
                <img className="" src={filterr} alt="filter" />
              </Button>
              <Button onClick={() => handleFilterShow()} variant="outline-primary" className={`${filterStyle.filterToggleButton} d-flex align-items-center`}>
                <img className="" src={filterr} alt="filter" />
              </Button>
            </div>

            <Row className={`d-sm-flex d-none overflow-auto text-nowrap `}>
              <Col>
                {(user.user_type === "secondary" && permissions?.SubReports?.isGeneralReport ? true : false) || user.user_type === "primary" ? (
                  <Button onClick={() => handleTabChange("General-Report")} className={` ${styles.btn}  ${activeTAb === "General-Report" ? styles.activebtn : ""}  mb-2`} variant="outline-primary">
                    General Report
                  </Button>
                ) : (
                  ""
                )}
                {(user.user_type === "secondary" && permissions?.SubReports?.isDailyCashReport ? true : false) || user.user_type === "primary" ? (
                  <Button onClick={() => handleTabChange("Daily-Cash-Report")} className={`${styles.btns} ${activeTAb === "Daily-Cash-Report" ? styles.activebtn : ""}  mb-2`} variant="outline-primary">
                    Daily Cash Report
                  </Button>
                ) : (
                  ""
                )}
                {(user.user_type === "secondary" && permissions?.SubReports?.isDailyTransactionReport ? true : false) || user.user_type === "primary" ? (
                  <Button onClick={() => handleTabChange("Daily-Transactional-Report")} className={`${styles.btns} ${activeTAb === "Daily-Transactional-Report" ? styles.activebtn : ""}  mb-2`} variant="outline-primary">
                    Daily Transactional Report
                  </Button>
                ) : (
                  ""
                )}
                {(user.user_type === "secondary" && permissions?.SubReports?.isProfitLossReport ? true : false) || user.user_type === "primary" ? (
                  <Button onClick={() => handleTabChange("Profit/Loss-Report")} className={` ${styles.btns} ${activeTAb === "Profit/Loss-Report" ? styles.activebtn : ""}  mb-2`} variant="outline-primary">
                    Profit/Loss Report
                  </Button>
                ) : (
                  ""
                )}
                {(user.user_type === "secondary" && permissions?.SubReports?.isInventoryReport ? true : false) || user.user_type === "primary" ? (
                  <Button onClick={() => handleTabChange("Inventory-Report")} className={` ${styles.btns} ${activeTAb === "Inventory-Report" ? styles.activebtn : ""}  mb-2`} variant="outline-primary">
                    Inventory Report
                  </Button>
                ) : (
                  ""
                )}
                {(user.user_type === "secondary" && permissions?.SubReports?.isSalesReport ? true : false) || user.user_type === "primary" ? (
                  <Button onClick={() => handleTabChange("Sales-Report")} className={`${styles.btns} ${activeTAb === "Sales-Report" ? styles.activebtn : ""}  mb-2`} variant="outline-primary">
                    Sales Report
                  </Button>
                ) : (
                  ""
                )}
                {(user.user_type === "secondary" && permissions?.SubReports?.isSalesSummary ? true : false) || user.user_type === "primary" ? (
                  <Button onClick={() => handleTabChange("Sales-Summary")} className={`${styles.btns} ${activeTAb === "Sales-Summary" ? styles.activebtn : ""}  mb-2`} variant="outline-primary">
                    Sales Summary
                  </Button>
                ) : (
                  ""
                )}
                {(user.user_type === "secondary" && permissions?.SubReports?.isJournal ? true : false) || user.user_type === "primary" ? (
                  <Button onClick={() => handleTabChange("Journal")} className={`${styles.btns} ${activeTAb === "Journal" ? styles.activebtn : ""}  mb-2`} variant="outline-primary">
                    Journal
                  </Button>
                ) : (
                  ""
                )}
                {(user.user_type === "secondary" && permissions?.SubReports?.isIMEIReport ? true : false) || user.user_type === "primary" ? (
                  <Button onClick={() => handleTabChange("IMEI-Report")} className={`${styles.btns} ${activeTAb === "IMEI-Report" ? styles.activebtn : ""}  mb-2`} variant="outline-primary">
                    IMEI Report
                  </Button>
                ) : (
                  ""
                )}
                {(user.user_type === "secondary" && permissions?.SubReports?.isBankTransactionsReport ? true : false) || user.user_type === "primary" ? (
                  <Button onClick={() => handleTabChange("Bank-Transactions-Report")} className={`${styles.btns} ${activeTAb === "Bank-Transactions-Report" ? styles.activebtn : ""}  mb-2`} variant="outline-primary">
                    Bank Transactions Report
                  </Button>
                ) : (
                  ""
                )}
                {(user.user_type === "secondary" && permissions?.SubReports?.isMiscItemsHistoryReport ? true : false) || user.user_type === "primary" ? (
                  <Button onClick={() => handleTabChange("Misc-Items-History-Report")} className={`rounded-end ${styles.btns} ${activeTAb === "Misc-Items-History-Report" ? styles.activebtn : ""}  mb-2`} variant="outline-primary">
                    Misc Items History Report
                  </Button>
                ) : (
                  ""
                )}
              </Col>
            </Row>
            {activeTAb === "General-Report" ? (
              <GeneralReport MainactiveTab={activeTAb} show={showMobileFilter} close={handleFilterClose} setFromDate={setFromDate} setToDate={setToDate} fromDate={fromDate} toDate={toDate} />
            ) : activeTAb === "Daily-Cash-Report" ? (
              <DailyCashReport
                setOpeningBalanceProp={setOpeningBalance}
                show={showMobileFilter}
                close={handleFilterClose}
                MainactiveTab={activeTAb}
                setCashInHandProp={setCashInHandProp}
                setTotalSalesPaymentDataProp={setTotalSalesPaymentDataProp}
                setTotalRefundsDataProp={setTotalRefundsDataProp}
                setTotalExpensesDataProp={setTotalExpensesDataProp}
                setTotalVendorPaymentsDataProp={setTotalVendorPaymentsDataProp}
                setTotalWithdrawAmountProp={setTotalWithdrawAmountProp}
                setTotalDepositeAmountProp={setTotalDepositeAmountProp}
                noRegisterCashReportProp={noRegisterCashReportProp}
                setNoRegisterCashReportProp={setNoRegisterCashReportProp}
              />
            ) : activeTAb === "Daily-Transactional-Report" ? (
              <DailyTransactionalReport show={showMobileFilter} close={handleFilterClose} MainactiveTab={activeTAb} setGrandTotalProp={setGrandTotalProp} />
            ) : activeTAb === "Profit/Loss-Report" ? (
              <ProfitLossReport show={showMobileFilter} close={handleFilterClose} MainactiveTab={activeTAb} />
            ) : activeTAb === "Inventory-Report" ? (
              <InventoryReports show={showMobileFilter} close={handleFilterClose} MainactiveTab={activeTAb} />
            ) : activeTAb === "Sales-Report" ? (
              <SalesReports show={showMobileFilter} close={handleFilterClose} MainactiveTab={activeTAb} />
            ) : activeTAb === "Sales-Summary" ? (
              <SalesSummary show={showMobileFilter} close={handleFilterClose} MainactiveTab={activeTAb} />
            ) : activeTAb === "Journal" ? (
              <Journal MainactiveTab={activeTAb} show={showMobileFilter} close={handleFilterClose} setFromDate={setFromDate} setToDate={setToDate} fromDate={fromDate} toDate={toDate} />
            ) : activeTAb === "IMEI-Report" ? (
              <ImeiReport />
            ) : activeTAb === "Bank-Transactions-Report" ? (
              <BankTransactionsReport MainactiveTab={activeTAb} show={showMobileFilter} close={handleFilterClose} setFromDate={setFromDate} setToDate={setToDate} fromDate={fromDate} toDate={toDate} />
            ) : activeTAb === "Misc-Items-History-Report" ? (
              <MiscItemsHistoryReport MainactiveTab={activeTAb} show={showMobileFilter} close={handleFilterClose} setFromDate={setFromDate} setToDate={setToDate} fromDate={fromDate} toDate={toDate} />
            ) : (
              ""
            )}
          </Container>
        </>
      )}
    </>
  );
};

export default Reports;
