import { Button, Col, Form, Row } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import SearchableSelect from "../../Components/SearchableSelect";
import axios from "axios";
import styleModal from "../../styles/Modal.module.css";

const AddBank = ({ close, defaultAccountExist }) => {
  const [bankFields, setBanksFields] = useState([]);
  const [bankName, setBankName] = useState([]);
  const [accountNo, setAccountNo] = useState([]);
  const [accountTitle, setAccountTitle] = useState();
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [validated, setValidated] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [openingBalance, setOpeningBalance] = useState("");
  const [accountType, setAccountType] = useState("");
  const [defaultBusinessAccount, setDefaultBusinessAccount] = useState(false);

  const { user } = useSelector((state) => state.auth);
  const shopId = user.shop_id;

  const validateAccountNumberField = (name) => {
    const formatOnlyNumber = /^[A-Z]*$/;
    const formatAlphanumeric = /^[a-zA-Z0-9]*$/;
    if (formatAlphanumeric.test(name) && !formatOnlyNumber.test(name)) {
      return true;
    } else {
      return false;
    }
  };
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + `/shops/banks/get-banks-name`, {
        withCredentials: true,
      })
      .then((res) => {
        const getData = res.data.map((bank) => ({
          value: bank.full_name,
          label: bank.full_name + "  (" + bank.short_name + ")",
        }));
        setBanksFields(getData);
      })
      .catch((err) => {
        throw new Error(err);
      });
  }, []);

  const handleBankSubmit = async (e) => {
    try {
      e.preventDefault();

      const form = e.currentTarget;
      if (form.checkValidity() === false) {
        e.stopPropagation();
      }
      setValidated(true);
      if (bankName.length > 0 && accountTitle?.length > 0 && accountNo.length > 0 && validateAccountNumberField(accountNo)) {
        setIsClicked(true);
        const res = await axios.post(
          process.env.REACT_APP_API_URL + `/shops/add-bank/${shopId}`,
          {
            bank_name: bankName,
            account_title: accountTitle,
            account_number: accountNo,
            opening_balance: openingBalance ? Number(openingBalance) : 0,
            account_type: accountType?.value,
            default_business_account: defaultBusinessAccount ? 1 : 0,
          },
          { withCredentials: true }
        );
        if (res) {
          setBankName("");
          setAccountTitle("");
          setAccountNo("");
          setAccountType("");
          setDefaultBusinessAccount(false);
          setOpeningBalance("");
          setError("");
          setValidated(false);
          setSuccess("Bank Added Successfully.");
          setTimeout(() => close(), 1000);
        }
      }
    } catch (error) {
      setIsClicked(true);
      setError(error.response.data.error.message);
      setIsClicked(false);
    }
  };

  return (
    <Form onSubmit={handleBankSubmit} noValidate validated={validated}>
      {error && <p className="text-danger p-2 text-center my-1 rounded-1">*{error}</p>}
      {success && <p className="text-success p-2 text-center my-1 rounded-1">*{success}</p>}
      <div className={`mb-3 ${styleModal.bankDropdown}`}>
        <SearchableSelect important label={"Bank Name"} placeholder="Select Bank Name" options={bankFields} handleChange={(e) => setBankName(e.value)} required />
        <Form.Control hidden type="text" name="bankName" value={bankName} onChange={() => {}} required />
        <Form.Control.Feedback type="invalid">* Please Select Bank</Form.Control.Feedback>
      </div>
      <Form.Group className="mb-3">
        <Form.Label className="mb-1">Opening Balance</Form.Label>
        <Form.Control
          type="number"
          name="openingBalance"
          placeholder="Write Opening Balance"
          onKeyDown={(e) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
          value={openingBalance}
          onChange={(e) => {
            if (e.target.value.length < 10) {
              setOpeningBalance(e.target.value);
            }
          }}
        />
      </Form.Group>
      <div className={`mb-3 `}>
        <SearchableSelect
          important
          label={"Account Type"}
          placeholder="Select Account Type"
          options={[
            { label: "Individual", value: "Individual" },
            { label: "Buisness", value: "Buisness" },
          ]}
          handleChange={(e) => setAccountType(e)}
          required
        />
        <Form.Control hidden type="text" name="accountType" value={accountType} onChange={() => {}} required />
        <Form.Control.Feedback type="invalid">* Please Select Account Type</Form.Control.Feedback>
      </div>
      <Form.Group className="mb-3">
        <Form.Check
          type="checkbox"
          label="Default Account"
          checked={defaultBusinessAccount}
          disabled={defaultAccountExist}
          onChange={(e) => {
            setDefaultBusinessAccount(e.target.checked);
          }}
        />
        {defaultAccountExist ? (
          <div className="d-inline-block">
            <div
              className="text-center p-2"
              style={{
                border: "1px dashed #dedfe4",
                backgroundColor: "#dedfe47a",
                borderRadius: "5px",
                fontSize: "10px",
              }}
            >
              You already have a default account.In order to set this A/C as Default A/C, please first de-select your current Default A/C
            </div>
          </div>
        ) : (
          <></>
        )}
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label className="mb-1">
          Account Title<span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          value={accountTitle}
          pattern="^^[A-Za-z ]+$"
          // onChange={(e) => {
          //   setAccountTitle(e.target.value);
          // }}
          onChange={(e) => {
            if (e.target.value.length < 35) {
              setAccountTitle(e.target.value);
            }
          }}
          name="accountTitle"
          className="p-2"
          type="text"
          required
          placeholder="Write Account Title"
          onKeyDown={(e) => ["!", "#", "$", "%", "^", "&", "*", "(", ")", ":", "'", "/", "`", "{", "}", "[", "]", "~", "+", "@", "1", "2", "3", "4", "5", "6", "7", "8", "9", "0", "-", "=", ";", ",", ".", "_", "|", ">", "<", "?", '"'].includes(e.key) && e.preventDefault()}
        />
        <Form.Control.Feedback type="invalid">Please Enter Account Title.</Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label className="mb-1">
          Account number<span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          value={accountNo}
          onChange={(e) => {
            if (e.target.value.length < 35) {
              setAccountNo(e.target.value.toUpperCase().replace(/[^\w\s]/gi, ""));
            }
          }}
          className="p-2"
          type="text"
          placeholder="xxxxxxxxxxxxxx"
          required
        />
        <Form.Control.Feedback type="invalid">Please Enter Account Number.</Form.Control.Feedback>

        <div
          className="text-center p-2"
          style={{
            border: "1px dashed #dedfe4",
            backgroundColor: "#dedfe47a",
            borderRadius: "5px",
            fontSize: "10px",
          }}
        >
          Standard IBAN number contain maximum <strong>34</strong> character
        </div>
      </Form.Group>

      {accountNo.length > 0 && !validateAccountNumberField(accountNo) ? (
        <div className="alert alert-warning" role="alert">
          Account number can be alphanumeric or numeric only!
        </div>
      ) : (
        ""
      )}

      <Button disabled={isClicked} type="submit" className="w-100">
        Save
      </Button>
    </Form>
  );
};

export default AddBank;
