import React, { useEffect, useState } from "react";
import style from "./FullCart.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight, faClose, faTriangleExclamation, faXmark } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import CartItemList from "./CartItemList";
import CartInputPart from "./CartInputPart";
import CartPaymentPart from "./CartPaymentPart";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import { useDispatch } from "react-redux";
import { addToCart, emptyCart, toggle } from "../../../Features/cart/cartSlice";
import LoaderOverlay from "../../loaders/loaderOverlay";
import OpeningRegister from "../../Register/OpeningRegister";
import { setLocation } from "../../../Features/locationSlice";

function FullCart({ isOpen, setIsOpen }) {
  const cart = useSelector((state) => state.cart);
  const { user } = useSelector((state) => state.auth);
  const { location, register } = useSelector((state) => state.location);

  const userId = user.user_id;
  const shopId = user.shop_id;
  const shopName = user.shop_name;
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [cartError, setCartError] = useState("");

  // Opening Balance States and effects
  const [greetingMessage, setGreetingMessage] = useState("");
  const [isCashRegisterModalOpen, setIsCashRegisterModalOpen] = useState(false);
  const [cashRegisterFormValidated, setCashRegisterFormValidated] = useState(false);
  const [openingBalanceValue, setOpeningBalanceValue] = useState();
  const [cashRegisterError, setCashRegisterError] = useState("");
  const [cashRegisterSuccess, setCashRegisterSuccess] = useState("");

  const handleCloseCashRegisterModal = () => {
    setIsCashRegisterModalOpen(false);
  };

  const onSubmitCashRegister = async (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }
    setCashRegisterFormValidated(true);
    try {
      if (register.registerId === -1) {
        setCashRegisterError("Register is not Set!");
      } else {
        if (openingBalanceValue >= 0) {
          const res = await axios.post(
            process.env.REACT_APP_API_URL + `/cash-registers/opening-balance`,
            {
              register_id: register.registerId,
              opening_balance: openingBalanceValue,
            },
            { withCredentials: true },
          );
          if (res) {
            dispatch(setLocation({ shop_id: shopId, location: location }));
            setCashRegisterFormValidated(false);
            // toggle((prev) => !prev);
            setOpeningBalanceValue();
            setCashRegisterSuccess(res.data);
            setTimeout(() => setIsCashRegisterModalOpen(false), 1000);
            setTimeout(() => setCashRegisterSuccess(""), 1000);
          }
        }
      }
    } catch (error) {
      setCashRegisterError(error);
    }
  };

  useEffect(() => {
    const today_sh = new Date();
    const currentTime = today_sh.getHours();
    if (currentTime < 12) {
      setGreetingMessage("Good Morning");
    } else if (currentTime < 18) {
      setGreetingMessage("Good Afternoon");
    } else {
      setGreetingMessage("Good Evening");
    }
  }, [isOpen]);

  // END of Opening Balance States and effects

  // States related to payment
  const [paymentType, setPyamentType] = useState("Cash");
  const [amountReceived, setAmountReceived] = useState(0);
  const [bank, setBank] = useState(null);
  const [comment, setComment] = useState("");
  const [isInvoice, setIsInvoice] = useState(false);
  const [isSms, setIsSms] = useState(false);
  const [isEmail, setIsEmail] = useState(false);

  // State related to cart inputs
  const [salesPerson, setSalesPerson] = useState(null);

  useEffect(() => {
    const baseGrandTotal = cart.totalCartAmount + cart.shippingCost - cart.totalCartDiscount;
    setAmountReceived(baseGrandTotal > 0 ? baseGrandTotal : 0);
  }, [cart, paymentType]);

  // END States related to payment
  //Scanner and Add in Cart
  const handleAddToCart = async (search_input) => {
    toast.info(`Searching the product!`, {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 1500,
      pauseOnHover: false,
      className: "toastMsg",
    });
    const responseObj = await axios.post(process.env.REACT_APP_API_URL + "/inventory/get/" + userId, { search: search_input, all: true,available: true }, { withCredentials: true });

    const product = responseObj.data.data[0];
    if (product) {
      if (product.location_id === location.value) {
        if (cart.cartItems.some((cartItem) => cartItem.id === product.id)) {
          toast.warning(`Product is already in Cart!`, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 1500,
            pauseOnHover: false,
            className: "toastMsg",
          });
        } else {
          if (product.quantity > 0) {
            dispatch(addToCart(product));
            toast.success(`${product.title} added to cart.`, {
              // toastId: product.id,
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 1500,
              pauseOnHover: false,
              className: "toastMsg",
            });
          } else {
            toast.error(`Selected Product is out of stock! Try an other Product.`, {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 1500,
              pauseOnHover: false,
              className: "toastMsg",
            });
          }
        }
      } else {
        toast.error(`You try to Add Product from Different location!`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1500,
          pauseOnHover: false,
          className: "toastMsg",
        });
      }
    } else {
      toast.error(`Unable to add in cart! Try again.`, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1500,
        pauseOnHover: false,
        className: "toastMsg",
      });
    }
  };

  useEffect(() => {
    let barcode = "";
    let interval;
    const scannerEventListener = (evt) => {
      if (interval) {
        clearInterval(interval);
      }
      if (evt.code === "Enter") {
        if (barcode.length > 0) {
          handleAddToCart(barcode);
        }
        barcode = "";
        return;
      }
      if (evt.key != "Shift") {
        barcode += evt.key;
      }
      interval = setInterval(() => (barcode = ""), 100);
    };

    window.addEventListener("keypress", scannerEventListener);

    return () => {
      window.removeEventListener("keypress", scannerEventListener);
    };
  });
  // END of Scanner and Add in Cart

  const handleSellItem = async (e) => {
    e.preventDefault();
    try {
      if (cart?.cartItems?.length === 0) {
        setCartError("Please add item in cart to sell.");
        return;
      } else {
        if (cart?.cartItems.some((cartItem) => cartItem?.isValid === false)) {
          // Cart is not validate
          setCartError("The discount on item(s) must be less than or equal to the sales price. Please check and try again.");
        } else {
          if (!cart?.isCustomerWalkIn ? cart?.customer?.customer_number && cart?.customer?.customer_name && cart?.customer?.customer_type : true) {
            if (paymentType === "Cash" ? register?.isCashRegisterOpen : true) {
              // ERROR Reset here
              setCartError("");
              if (Number(amountReceived) > 0 && (paymentType === "Card" || paymentType === "Transfer") ? (paymentType === "Card" || paymentType === "Transfer") && bank?.label?.length : true) {
                setIsLoading(true);

                const { salesItems, miscItems } = cart?.cartItems.reduce(
                  (acc, item) => {
                    if (item?.isMisc) {
                      acc.miscItems.push(item); // Change item Object as needed in backend
                      return acc;
                    } else {
                      acc.salesItems.push(item); // Change item Object as needed in backend
                      return acc;
                    }
                  },
                  { salesItems: [], miscItems: [] },
                );
                const baseGrandTotal = cart.totalCartAmount + cart.shippingCost - cart.totalCartDiscount;
                const customer = cart?.isCustomerWalkIn ? null : cart?.customer;
                const sales = {
                  base_grand_total: baseGrandTotal,
                  amount_received: amountReceived > baseGrandTotal ? baseGrandTotal : amountReceived,
                  base_subtotal: cart?.totalCartAmount,
                  base_shipping_amount: Number(cart?.shippingCost),
                  shop_id: shopId,
                  shop_name: shopName,
                  user_id: userId,
                  location_id: location?.value,
                  sales_order_comment: comment,
                  collective_discount: cart?.discount,
                  sales_person_id: salesPerson?.value ? salesPerson?.value : -1,
                  totalDiscount: cart?.totalCartDiscount,
                  shopLogo: user?.logo_path,
                };
                const payment = {
                  customer_payments_bank_id: paymentType === "Cash" ? -1 : bank.value.bank_id,
                  payment_type: paymentType,
                  customer_payments_comment: comment,
                  customer_payments_amount_received: Number(amountReceived) === 0 ? 0 : amountReceived > baseGrandTotal ? baseGrandTotal : amountReceived,
                  shop_id: shopId,
                };
                const res = await axios.post(
                  process.env.REACT_APP_API_URL + `/sales/add`,
                  {
                    miscItems: miscItems.map((i) => ({
                      name: i.title,
                      price: i.sale_price,
                      qty_ordered: Number(i.cartQuantity),
                      // description: i.description,
                      discount: i.itemDiscount,
                    })),
                    customer,
                    sales,
                    payment,
                    isEmail,
                    isInvoice,
                    isSms,
                    salesItems: salesItems.map((item) => ({
                      item_id: item.id,
                      product_type: item.label,
                      price: Number(item.sale_price),
                      qty_ordered: item.cartQuantity,
                      discount: item.itemDiscount,
                      imeis: item?.imeis?.map((imei) => imei.imeiNumber).join(","),
                    })),
                  },
                  { withCredentials: true },
                );
                if (res) {
                  setIsLoading(false);
                  toast.success("Sales Added", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 1500,
                    pauseOnHover: false,
                    className: "toastMsg",
                  });
                  if (isInvoice) {
                    setTimeout(() => window.open(`/invoice/` + res.data.salesOrderId, "_blank", "noopener,noreferrer"), 1500);
                  }
                  dispatch(toggle());
                  dispatch(emptyCart());
                  setIsOpen(false);
                }
              }
            } else {
              setIsCashRegisterModalOpen(true);
            }
          } else {
            // customer error
            setCartError("Please Enter Customer Details");
          }
        }
      }
    } catch (error) {}
  };

  return (
    <>
      <LoaderOverlay loading={isLoading} />
      <div className={`${style.fullCartContainer}  `}>
        <div className={`${style.actionToClose}`} onClick={() => setIsOpen(false)}>
          <FontAwesomeIcon icon={faCaretRight} />
        </div>
        <div className={`w-100`}>
          <div className={`p-3 pb-0 position-relative z-3`}>
            <div className={`d-flex justify-content-between border-bottom px-2`}>
              <h4>Cart</h4>
              <div className="d-none d-md-block">{cartError.length > 0 ? <span className="text-danger">{cartError}</span> : <></>}</div>
              <div className={`d-flex align-items-center h-100 gap-3`}>
                <Button className="d-block d-md-none" style={{ fontSize: "10px ", fontWeight: "300", height: "31px", whiteSpace: "nowrap" }} onClick={handleSellItem}>
                  Sell Items
                </Button>
                <Button
                  className="p-2"
                  variant="outline-primary"
                  style={{ fontSize: "10px ", fontWeight: "300", height: "31px", whiteSpace: "nowrap" }}
                  onClick={() => {
                    dispatch(emptyCart());
                  }}
                >
                  Empty Cart
                </Button>
                <FontAwesomeIcon onClick={() => setIsOpen(false)} className={`fs-5 cursor-pointer`} icon={faXmark} />
              </div>
            </div>
            {cartError.length > 0 ? (
              <div className={`d-block d-md-none position-absolute py-1 top-100 start-0 end-0 w-100 text-center bg-danger bg-opacity-75`}>
                <div className="text-white pe-3 text-wrap" style={{ fontSize: "12px" }}>
                  <FontAwesomeIcon icon={faTriangleExclamation} /> {cartError}
                </div>
                <FontAwesomeIcon
                  className="text-white position-absolute top-50 end-0 translate-middle"
                  onClick={() => {
                    setCartError("");
                  }}
                  icon={faClose}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className={`${style.cartMainContainer}`}>
            <div className={`${style.cartMainBody}`}>
              <div className={`d-flex flex-column flex-md-row justify-content-between`}>
                <CartItemList />
                <div className={`${style.otherCartPart}`}>
                  <div className={`${style.cartInputPartContainer} p-1`}>
                    <CartInputPart salesPerson={salesPerson} setSalesPerson={setSalesPerson} />
                  </div>
                  <div className={`${style.cartPaymentPartContainer} border-top p-1`}>
                    <CartPaymentPart
                      paymentType={paymentType}
                      setPyamentType={setPyamentType}
                      amountReceived={amountReceived}
                      setAmountReceived={setAmountReceived}
                      bank={bank}
                      setBank={setBank}
                      comment={comment}
                      setComment={setComment}
                      isInvoice={isInvoice}
                      setIsInvoice={setIsInvoice}
                      isSms={isSms}
                      setIsSms={setIsSms}
                      isEmail={isEmail}
                      setIsEmail={setIsEmail}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={`${style.cartCheckoutContainer} border-top `}>
              <div>
                <Button onClick={handleSellItem}>Sell Items</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <OpeningRegister
        cashRegisterModal={isCashRegisterModalOpen}
        setCashRegisterModal={setIsCashRegisterModalOpen}
        validated={cashRegisterFormValidated}
        handleCashOpeningBalance={handleCloseCashRegisterModal}
        showtime={greetingMessage}
        registerSuccess={cashRegisterSuccess}
        error={cashRegisterError}
        setIsValidate={setCashRegisterFormValidated}
        openingBalance={openingBalanceValue}
        setOpeningBalance={setOpeningBalanceValue}
        handleCashRegister={onSubmitCashRegister}
      />
    </>
  );
}

export default FullCart;
