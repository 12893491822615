import React, { useState } from "react";
import style from "./listingHeader.module.css";
import { Button, Form } from "react-bootstrap";

import searchIcon from "../../../assets/svg/search.svg";
import searchActiveIcon from "../../../assets/svg/searchPurple.svg";
import SearchableSelect from "../../SearchableSelect";
import { Link } from "react-router-dom";
import { faClose, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactSelect from "../../ReactSelect";
import axios from "axios";

function SearchInput({ setSearchText, searchText }) {
  const handle = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  return (
    <>
      <Form className="position-relative d-inline" onSubmit={handle}>
        <Form.Control type="search" placeholder="Search" className={` ${style.searchInputControl} `} aria-label="Search" onChange={(e) => setSearchText(e.target.value)} value={searchText ? searchText : undefined} />
        <div className={` ${style.margin_bottom}  ${style.searchIcon} d-flex`}>
          <img src={searchIcon} alt="search" />
        </div>
      </Form>
    </>
  );
}

function MobleFilters({ hideSearch = false, hideShowing = false, hideButton = false, hideAvailableItems = false, hideCategoryFilter = false, addListingButtonHandle, availableItems, setSizeOfPages, sizeOfPages, handleCategoryFilter, categoryFilter, setSearchText, searchText }) {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  return (
    <>
      {isSearchOpen ? (
        <div className={`d-flex bd-highlight w-100`}>
          <div className={`me-auto bd-highlight w-100`}>
            <SearchInput setSearchText={setSearchText} searchText={searchText} />
          </div>
          <div className={`d-flex align-items-center p-2`} onClick={() => setIsSearchOpen(false)}>
            <FontAwesomeIcon icon={faClose} />
          </div>
        </div>
      ) : (
        <div className="d-flex bd-highlight gap-2 w-100">
          {hideShowing ? (
            ""
          ) : (
            <div className="bd-highlight">
              <SearchableSelect
                selectClassName={`${style.ShowingSelect} bg-white`}
                placeholder={`Showing 10`}
                defaultValue={`Showing 10`}
                value={
                  [
                    { value: 10, label: "Showing 10" },
                    { value: 20, label: "Showing 20" },
                    { value: 30, label: "Showing 30" },
                  ].filter((op) => op.value === sizeOfPages)[0]
                }
                searchable={false}
                options={[
                  { value: 10, label: "Showing 10" },
                  { value: 20, label: "Showing 20" },
                  { value: 30, label: "Showing 30" },
                ]}
                handleChange={(e) => setSizeOfPages(e.value)}
              />
            </div>
          )}
          {hideAvailableItems ? (
            ""
          ) : (
            <div className="bd-highlight">
              <div
                style={{
                  color: "#6C757D",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "18px",
                }}
                className={`d-flex align-items-center h-100`}
              >
                {availableItems} Items Available
              </div>
            </div>
          )}
          {hideCategoryFilter ? (
            ""
          ) : (
            <div className="bd-highlight" style={{ width: "170px" }}>
              <ReactSelect
                important
                placeholder="Select Category"
                searchable={false}
                value={categoryFilter}
                options={() => {
                  return new Promise((resolve, reject) => {
                    axios
                      .get(process.env.REACT_APP_API_URL + "/categories/get", {
                        withCredentials: true,
                      })
                      .then((res) => {
                        const getData = res.data.map((cat) => ({
                          value: cat.id,
                          label: cat.category,
                        }));
                        getData.splice(0, 0, { value: null, label: "All" });
                        resolve(getData);
                      })
                      .catch((err) => {
                        reject("err" + err);
                      });
                  });
                }}
                handleChange={handleCategoryFilter}
              />
            </div>
          )}
          {hideSearch ? (
            ""
          ) : (
            <div className="ms-auto bd-highlight">
              <div className="d-flex position-relative justify-content-end h-100">
                <div className={`pe-2 ${style.searchIconMobile}  ${style.iconBorder} d-flex  justify-content-center align-items-center`} onClick={() => setIsSearchOpen(true)}>
                  <img src={searchActiveIcon} height={20} alt="search" />
                </div>
              </div>
            </div>
          )}
          {hideButton ? (
            ""
          ) : (
            <div className=" bd-highlight">
              <Button
                type="submit"
                style={{
                  fontSize: "1rem",
                  height: "100%",
                }}
                className={style.addButtonLink}
                onClick={addListingButtonHandle}
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            </div>
          )}
        </div>
      )}
    </>
  );
}

function ListingHeader({
  hideTitle = false,
  hideSearch = false,
  hideShowing = false,
  hideButton = false,
  hideCategoryFilter = false,
  hideAvailableItems = false,
  addListingButtonHandle,
  availableItems = 0,
  setSearchText = (text) => {
    return text;
  },
  setSizeOfPages,
  sizeOfPages,
  handleCategoryFilter = (category) => {
    return category;
  },
  searchText,
}) {
  const [categoryFilter, setCategoryFilter] = useState(null);
  const handleChangeCategoryFilter = (e) => {
    if (e.value) {
      handleCategoryFilter(e);
      setCategoryFilter(e);
    } else {
      setCategoryFilter(null);
      handleCategoryFilter(null);
    }
  };
  return (
    <div className="w-100 px-3">
      <div className={`d-none d-sm-grid d-xl-flex w-100 align-items-baseline gap-1 gap-xl-3  ${hideAvailableItems ? "pb-3 p-xl-3" : ""} ${style.filtersContainer} ${hideAvailableItems ? style.filtersContainerBottom : ""}`}>
        {hideTitle ? "" : <div className={`${style.pageTitle} pb-4 pb-xl-0`}>Listings</div>}
        <div className="d-flex bd-highlight gap-2 w-100">
          {hideSearch ? (
            ""
          ) : (
            <div className="me-auto bd-highlight">
              <SearchInput setSearchText={setSearchText} searchText={searchText} />
            </div>
          )}
          {hideCategoryFilter ? (
            ""
          ) : (
            <div className="bd-highlight" style={{ width: "170px" }}>
              <ReactSelect
                important
                placeholder="Select Category"
                searchable={false}
                value={categoryFilter}
                options={() => {
                  return new Promise((resolve, reject) => {
                    axios
                      .get(process.env.REACT_APP_API_URL + "/categories/get", {
                        withCredentials: true,
                      })
                      .then((res) => {
                        const getData = res.data.map((cat) => ({
                          value: cat.id,
                          label: cat.category,
                        }));
                        getData.splice(0, 0, { value: null, label: "All" });
                        resolve(getData);
                      })
                      .catch((err) => {
                        reject("err" + err);
                      });
                  });
                }}
                handleChange={handleChangeCategoryFilter}
              />
            </div>
          )}
          {hideShowing ? (
            ""
          ) : (
            <div className="bd-highlight">
              <SearchableSelect
                selectClassName={`${style.ShowingSelect} bg-white`}
                placeholder={`Showing 10`}
                defaultValue={"Showing 10"}
                value={
                  [
                    { value: 10, label: "Showing 10" },
                    { value: 20, label: "Showing 20" },
                    { value: 30, label: "Showing 30" },
                  ].filter((op) => op.value === sizeOfPages)[0]
                }
                searchable={false}
                options={[
                  { value: 10, label: "Showing 10" },
                  { value: 20, label: "Showing 20" },
                  { value: 30, label: "Showing 30" },
                ]}
                handleChange={(e) => setSizeOfPages(e.value)}
              />
            </div>
          )}
          {hideAvailableItems ? (
            ""
          ) : (
            <div className="bd-highlight">
              <div
                style={{
                  color: "#6C757D",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "18px",
                }}
                className={`d-flex align-items-center h-100`}
              >
                {availableItems} Items Available
              </div>
            </div>
          )}
          {hideButton ? (
            ""
          ) : (
            <div className="bd-highlight">
              <Button
                type="submit"
                style={{
                  fontSize: "1rem",
                  height: "100%",
                }}
                className={style.addButtonLink}
                onClick={addListingButtonHandle}
              >
                <span className="d-none d-sm-inline  d-lg-inline">Add listing</span> <FontAwesomeIcon icon={faPlus} />
              </Button>
            </div>
          )}
        </div>
      </div>
      <div className="d-grid d-sm-none w-100 gap-1 px-1 pb-3 ">
        {hideTitle ? "" : <div className={`${style.pageTitle}  pb-3`}>Listings</div>}
        <MobleFilters
          addListingButtonHandle={addListingButtonHandle}
          hideSearch={hideSearch}
          hideShowing={hideShowing}
          hideButton={hideButton}
          hideAvailableItems={hideAvailableItems}
          hideCategoryFilter={hideCategoryFilter}
          availableItems={availableItems}
          setSizeOfPages={setSizeOfPages}
          sizeOfPages={sizeOfPages}
          handleCategoryFilter={handleChangeCategoryFilter}
          categoryFilter={categoryFilter}
          setSearchText={setSearchText}
          searchText={searchText}
        />
      </div>
    </div>
  );
}

export default ListingHeader;
