import { Link, useLocation } from "react-router-dom";
import GlobalLocation from "../GlobalLocation";
import { useMemo } from "react";

const BreadCrumb = () => {
  const location = useLocation();
  const path = location.pathname;
  const isGlobalLocationVisible = useMemo(
    () =>
      path !== "/" &&
      path !== "/vendor" &&
      path !== "/vendor/add" &&
      path !== "/customer" &&
      path !== "/trade-in" &&
      path !== "/reports" &&
      path !== "/settings/locations" &&
      path !== "/settings/reset-password" &&
      path !== "/settings/systemSetting" &&
      path !== "/settings/subscriptions" &&
      path !== "/settings/banks",
    [path]
  );
  const items = path.slice(1).split("/");
  let pathArr = [
    {
      label: "Dashboard",
      path: "/",
    },
  ];
  if (items[0] !== "") {
    let stringPath = "/";
    items.map((item, index, array) => {
      if (!isNaN(item)) {
        return null;
      } else {
        if (!isNaN(array[index + 1])) {
          if (item === "purchase-order") {
            stringPath = stringPath + item;
            pathArr.push({
              label: "Purchase Order No." + array[index + 1],
              path: stringPath,
            });
          } else {
            stringPath = stringPath + item + "/" + array[index + 1];
            if (item === "edit") {
              pathArr.push({
                label: item,
                path: stringPath,
              });
            } else if (item === "invoice") {
              pathArr.push({
                label: "Invoice No." + array[index + 1],
                path: stringPath,
              });
            } else if (item === "invoice-payments-sales") {
              pathArr.push({
                label: "Invoice No." + array[index + 1],
                path: stringPath,
              });
            } else if (item === "invoice-view") {
              pathArr.push({
                label: "Invoice No." + array[index + 1],
                path: stringPath,
              });
            } else if (item === "trade-in") {
              pathArr.push({
                label: "Trade No." + array[index + 1],
                path: stringPath,
              });
            } else if (item === "orders") {
              pathArr.push({
                label: "Order No." + array[index + 1],
                path: stringPath,
              });
            } else {
              if (!isNaN(array[index - 1])) {
                pathArr.push({
                  label: array[index - 2] + " no. " + array[index + 1],
                  path: stringPath,
                });
              } else {
                pathArr.push({
                  label: array[index - 1] + " no. " + array[index + 1],
                  path: stringPath,
                });
              }
            }
          }
        } else {
          stringPath = stringPath + item;
          pathArr.push({
            label: item,
            path: stringPath,
          });
        }
        stringPath = stringPath + "/";
      }
    });
  }
  return (
    <>
      <div
        className="d-flex d-md-none d-lg-none bg-white w-100 px-2"
        style={{
          zIndex: "22",
          height: "auto",
          padding: "10px",
          // marginLeft: "14px",
        }}
      >
        <GlobalLocation disableCondition={!isGlobalLocationVisible} />
      </div>
      <div style={{ padding: "10px" }}>
        {pathArr.map((obj, i) => {
          if (i === 0) {
            if (pathArr.length > 1) {
              return (
                <span key={i}>
                  <span style={{ margin: "0 5px", fontSize: "17px", color: "gray" }}>{"/"}</span>
                  <Link
                    to={obj.path}
                    style={{
                      color: "#A93BFF",
                      textDecoration: "none",
                      textTransform: "capitalize",
                    }}
                  >
                    {obj.label}
                  </Link>
                </span>
              );
            } else {
              return (
                <span key={i}>
                  <span style={{ margin: "0 5px", fontSize: "17px", color: "gray" }}>{"/"}</span>
                  <span
                    style={{
                      color: "gray",
                      textTransform: "capitalize",
                    }}
                  >
                    {obj.label}
                  </span>
                </span>
              );
            }
          } else if (pathArr.length === i + 1) {
            return (
              <span key={i}>
                <span style={{ margin: "0 5px", fontSize: "17px", color: "gray" }}>{"/"}</span>
                <span style={{ textTransform: "capitalize" }}>
                  <Link
                    to={obj.path}
                    style={{
                      pointerEvents: "none",
                      textDecoration: "none",

                      textDecoration: "none",
                      color: "gray",
                      textTransform: "capitalize",
                    }}
                  >
                    {obj.label}
                  </Link>
                </span>
              </span>
            );
          } else {
            return (
              <span key={i}>
                <span style={{ margin: "0 5px", fontSize: "17px", color: "gray" }}>{"/"}</span>
                <span style={{ textTransform: "capitalize" }}>
                  <Link
                    to={obj.path}
                    style={{
                      color: "#A93BFF",
                      textDecoration: "none",

                      textTransform: "capitalize",
                    }}
                  >
                    {obj.label}
                  </Link>
                </span>
              </span>
            );
          }
        })}
      </div>
    </>
  );
};

export default BreadCrumb;
