import React from "react";
import TableView from "../Components/TableView";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Pagination,
  Dropdown,
} from "react-bootstrap";
import SearchInput from "../Components/SearchInput";
import { useState } from "react";
import PopUpModal from "../Components/Modal";
import triangle from "../assets/svg/triangle.svg";
import ImageInput from "../Components/ImageInput";
import dangerline from "../assets/svg/dangerline.svg";
import filter from "../assets/svg/filter.svg";
import Select from "../Components/Select";

const MyCampaign = () => {
  const [create_sms_modal, setSMSCampaignModal] = useState(false);
  const [create_email_modal, setEmailCampaignModal] = useState(false);

  const [delete_modal, setDeleteModal] = useState(false);

  const shop = [
    {
      Customer_name: "John",
      Email: "example@gmail.com",
      Phone_number: "xxx xxxxxxxxx",
      City: "Lahore",
      Created_at: "19 May, 2022",
      Status: 1,
    },
    {
      Customer_name: "John1",
      Email: "example@gmail.com",
      Phone_number: "xxx xxxxxxxxx",
      City: "Karachi",
      Created_at: "19 May, 2022",
      Status: 0,
    },
    {
      Customer_name: "John2",
      Email: "example@gmail.com",
      Phone_number: "xxx xxxxxxxxx",
      City: "	Islamabad",
      Created_at: "19 May, 2022",
      Status: 1,
    },
    {
      Customer_name: "John3",
      Email: "example@gmail.com",
      Phone_number: "xxx xxxxxxxxx",
      City: "Multan",
      Created_at: "19 May, 2022",
      Status: 0,
    },
    {
      Customer_name: "John4",
      Email: "example@gmail.com",
      Phone_number: "xxx xxxxxxxxx",
      City: "Peshawar",
      Created_at: "19 May, 2022",
      Status: 1,
    },
  ];
  return (
    <>
      <Container fluid>
        <Row className="d-flex align-items-baseline">
          <Col xs="2" lg="3" className="d-none d-lg-inline">
            <SearchInput searchlogo />
          </Col>
          <Col xs lg="2" className="d-none d-lg-inline"></Col>
          <Col xs lg="2" className="d-none d-lg-inline">
            <Form.Select className="text-secondary">
              <option hidden>Campaign type</option>
            </Form.Select>
          </Col>
          <Col xs lg="2" className="d-none d-lg-inline">
            <Form.Select className="text-secondary">
              <option hidden>By date</option>
            </Form.Select>
          </Col>
          <Col xs="6" lg="2">
            <Form.Select className=" h-100">
              <option>Showing {shop.length}</option>
            </Form.Select>
          </Col>
          <Col xs="2" lg="3" className="d-md-none d-flex">
            <SearchInput searchlogo bordered />
          </Col>
          <Col className="d-md-none d-flex" xs="2" lg="2">
            <Button variant="outline-primary">
              <img src={filter} alt="filter" />
            </Button>
          </Col>
          <Col className="d-md-none d-flex" xs="2" lg="2">
            <Dropdown>
              <Dropdown.Toggle variant="outline-primary" id="dropdown-basic">
                +
              </Dropdown.Toggle>

              <Dropdown.Menu className="rounded-0 border-0 shadow">
                <Dropdown.Item
                  className="purple"
                  href=""
                  onClick={() => setSMSCampaignModal(true)}
                >
                  Create SMS Campaign
                </Dropdown.Item>
                <Dropdown.Item
                  className="purple"
                  href=""
                  onClick={() => setEmailCampaignModal(true)}
                >
                  Create Email Campaign
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col xs="2" lg="1" className="d-none d-lg-flex">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic" style={{ fontSize: "13px" }}>
                Create Campaign
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  className="purple"
                  href=""
                  onClick={() => setSMSCampaignModal(true)}
                >
                  Create SMS Campaign
                </Dropdown.Item>
                <Dropdown.Item
                  className="purple"
                  href=""
                  onClick={() => setEmailCampaignModal(true)}
                >
                  Create Email Campaign
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>{" "}
          </Col>
        </Row>

        <TableView
          data={shop}
          sortFields={[]}
          CheckList
          VARIABLE={["Pending", " Sent"]}
          actions
          trashlogo
          edit
          remove={() => setDeleteModal(true)}
          className="mt-4"
        />

        <Row className="mt-5">
          <Col className="d-none d-lg-block" md="6" lg="3">
            <span className="text-secondary fs-6">{shop.length} results</span>
          </Col>
          <Col xs="12" lg="6">
            <Pagination className="mb-0 justify-content-center">
              <Pagination.Prev />
              <Pagination.Item>{1}</Pagination.Item>
              <Pagination.Item>{2}</Pagination.Item>
              <Pagination.Item active>{3}</Pagination.Item>
              <Pagination.Item>{4}</Pagination.Item>
              <Pagination.Ellipsis />
              <Pagination.Item>{20}</Pagination.Item>
              <Pagination.Next />
            </Pagination>
          </Col>
          <Col
            className="d-md-none d-flex align-items-center mt-3"
            xs="6"
            lg="3"
          >
            <span className="text-secondary fs-6">{shop.length} results</span>
          </Col>
          <Col xs="6" lg="3" className="text-end mt-3">
            <Button variant="outline-primary">Download as CSV </Button>
          </Col>
        </Row>
      </Container>

      <PopUpModal
        title="Create Email Campaign"
        show={create_sms_modal}
        onHide={() => setSMSCampaignModal(false)}
      >
        <Form>
          <Form.Group className="mb-3">
            <Form.Label className="mb-1">Campaign Name</Form.Label>
            <Form.Control
              className="p-2"
              type="text"
              placeholder="Campaign Name"
            />
          </Form.Group>
          <Col className="mb-3">
            <Select label={"Send to"} placeholder="Specific Customers" />
          </Col>
          <Col>
            <ImageInput label={"Upload customers'  numbers"} />
          </Col>
          <Col className="mb-3">
            <Select label={"Select date & time"} placeholder="Select" />
          </Col>

          <Form.Group className="mb-3">
            <Form.Label className="mb-1">Message Content</Form.Label>
            <Form.Control
              className="p-2"
              type="text"
              as="textarea"
              rows={3}
              placeholder=""
            />
          </Form.Group>

          <div className="py-2">
            <Button className="w-100">Save</Button>
          </div>
        </Form>
      </PopUpModal>

      <PopUpModal
        title="Create Email Campaign"
        show={create_email_modal}
        onHide={() => setEmailCampaignModal(false)}
      >
        <Form>
          <Form.Group className="mb-3">
            <Form.Label className="mb-1">Campaign Name</Form.Label>
            <Form.Control
              className="p-2"
              type="text"
              placeholder="Campaign Name"
            />
          </Form.Group>
          <Col className="mb-3">
            <Select label={"Send to"} placeholder="Specific Customers" />
          </Col>
          <Col>
            <ImageInput label={"Upload customers'  numbers"} />
          </Col>
          <Col className="mb-3">
            <Select label={"Select date & time"} placeholder="Select" />
          </Col>

          <Form.Group className="mb-3">
            <Form.Label className="mb-1">Email Content</Form.Label>
            <Form.Control
              className="p-2"
              type="text"
              as="textarea"
              rows={3}
              placeholder=""
            />
          </Form.Group>

          <div className="py-2">
            <Button className="w-100">Save</Button>
          </div>
        </Form>
      </PopUpModal>

      <PopUpModal
        title="Update Payment"
        show={delete_modal}
        onHide={() => setDeleteModal(false)}
      >
        <div className="d-flex justify-content-center pb-4">
          <img src={triangle} alt="triangle" />
          <img
            src={dangerline}
            alt="dangerline"
            style={{ position: "absolute", bottom: "9.9rem" }}
          />
        </div>
        <div>
          <span className="d-flex justify-content-center pb-4">
            Do you really want to delete this expense?
          </span>
        </div>

        <Row className="py-2">
          <Col className="border-bottom "></Col>
        </Row>
        <div></div>

        <Row className="mt-2">
          <Col sm={6} lg={6}></Col>
          <Col sm={6} lg={6}>
            <div className="d-flex gap-3">
              <button
                className="w-100 rounded-3"
                style={{ border: "2px solid #686E76" }}
                onClick={() => setDeleteModal(false)}
              >
                Cancel
              </button>

              <Button className="w-100">Delete</Button>
            </div>
          </Col>
        </Row>
      </PopUpModal>
    </>
  );
};

export default MyCampaign;
