import style from "./../../styles/Sidebar.module.css";
import profile from "./../../assets/images/profile.png";
import SideNavData from "./SideNavData";
import MenuItem from "./MenuItem";
import { useSelector, useDispatch } from "react-redux";
import { authActions, currentUser } from "./../../Features/authSlice";
import Cookies from "../../Features/Cookies";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faEdit, faUserPen } from "@fortawesome/free-solid-svg-icons";
import signOut from "./../../assets/svg/signout.svg";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Menu from "./Menu";
import useOutsideAlerter from "../../Hooks/Outsider";
import { Button, Form, Modal } from "react-bootstrap";
import axios from "axios";
import { routesAction } from "../../Features/routesSlice";
const Sidebar = ({ setIsSandwichOpen, isSandwichOpen, toggle, ef }) => {
  const dispatch = useDispatch();
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectImageURL, setSelectImagURL] = useState();
  const [selectImage, setSelectImag] = useState();
  const [toogle, setToogle] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const [isLoading, setIsloading] = useState(false);
  const [sideNav, setSideNav] = useState([])
  // const wrapperRef = useRef(null);
  // useOutsideAlerter(wrapperRef, setIsSandwichOpen);
  const navigate = useNavigate();
  const publicPermissions = ["Dashboard", "Customer", "Settings"];
  const permissions = user?.permissionArray;
  const userType = user?.user_type;
  const [data, setdata] = useState(SideNavData);
  const {privateRoutes} =useSelector((state=>
    state.routes
   ))

  useEffect(() => {
    const newPermissions = permissions?.concat(publicPermissions);
    if (userType === "primary") {
      setdata(SideNavData);
    } else {
      if (userType === "secondary") {
        const newSideNavData = SideNavData.map((Nav) => {
          if (Nav.identifier === "Settings") {
            const NewSubNav = Nav.subNav.filter((Sub) => Sub.identifier !== "AddNewLocation" && Sub.identifier !== "Subscriptions" && Sub.identifier !== "Banks");
            return { ...Nav, subNav: NewSubNav };
          } else {
            return Nav;
          }
        });
        setdata(newSideNavData?.filter((ans) => newPermissions?.includes(ans.identifier)));
      }
    }
  }, [permissions, userType, SideNavData, user, toggle]);
  const cancelConfirm = () => {
    setShowEditModal(false);
    setSelectImagURL();
  };
  const handleImageUpload = async (e) => {
    if (e.target.files[0]) {
      setSelectImag(e.target.files[0]);
      setSelectImagURL(URL.createObjectURL(e.target.files[0]));
    }
  };
  const updateImage = async (e) => {
    e.preventDefault();
    setIsloading(true);

    const formData = new FormData();
    formData.append("file", selectImage, selectImage.name);
    formData.append("old_url", user.logo_path ? user.logo_path : "nil");
    const res = await axios.post(process.env.REACT_APP_URL_IMAGE_CDN + `/shop/${user.shop_id}`, formData, { withCredentials: true });
    setIsloading(false);
    if (res) {
      setToogle((prev) => !prev);
      setShowEditModal(false);
      dispatch(currentUser());
      setSelectImagURL();
    }
  };
  return (
    <div
      // ref={wrapperRef}
      className={`bg-white ${style.sidebar} ${!isSandwichOpen ? "" : style.sandwichOpen}`}
    >
      <div className={`${isSandwichOpen ? "justify-content-xl-center" : ""} py-3 px-2 ${style.infoDiv}`}>
        <div className={`${style.userInfo}`}>
          {/* <div className="ms-2 me-2 ">
            <img
              width="52px"
              height="52px"
              className="rounded-circle image"
              // src={user.logo_path === null ? profile : user.logo_path}
              src="https://staging.cdn.techbazaar.pk/images/profiles/n88RxUpJFTnNFzvF1670915375409.png"
              alt="profile"
            />
          </div> */}
          <div
            className={`${style.profile_pic}`}
            onClick={() => {
              setShowEditModal(true);
            }}
          >
            <label className={`${style.label}`}>
              <span className="glyphicon glyphicon-camera"></span>
              <span>
                <FontAwesomeIcon icon={faUserPen} />
              </span>
            </label>
            <img src={user.logo_path ? process.env.REACT_APP_URL_S3 + "/" + user.logo_path : profile} id="output" width="200" />
          </div>
          {!isSandwichOpen && (
            <div>
              <p className="fw-bold mb-0 text-capitalize">{user && user.shop_name}</p>
              <span className="fw-light text-capitalize">{user && user.user_type}</span>
            </div>
          )}
        </div>
        <div
          role="button"
          className="text-white d-sm-block d-lg-none"
          onClick={() => {
            if (window.innerWidth <= 820) {
              window.tidioChatApi?.show();
            }
            setIsSandwichOpen(true);
          }}
        >
          {/* <span className="d-none d-sm-none d-md-none d-lg-none "> Close </span> */}
          <FontAwesomeIcon icon={faClose} />
        </div>
      </div>
      <div className={`d-flex flex-column justify-content-between ${isSandwichOpen ? "align-items-center" : "align-items-start"} ${style.sidebarItems}`}>
        <div className={`${style.scroll} ${style.scrollheight} w-100`}>
          <Menu setIsSandwichOpen={setIsSandwichOpen} navData={privateRoutes} isSandwichOpen={isSandwichOpen} />
        </div>
      </div>

      <Modal show={showEditModal} onHide={cancelConfirm} animation={true} centered>
        <Modal.Header>
          <Modal.Title>Edit Logo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            className={`${style.ChangePic}`}
            onClick={() => {
              setShowEditModal(true);
            }}
          >
            <label className={`${style.label}`} for="file">
              <span className="glyphicon glyphicon-camera"></span>
              <span>
                <FontAwesomeIcon icon={faUserPen} />
              </span>
            </label>
            <Form.Control id="file" type="file" size="sm" accept="image/jpg, image/jpeg, image/png" name="contact_person_name" onChange={(e) => handleImageUpload(e)} />
            <img src={selectImageURL ? selectImageURL : user.logo_path ? process.env.REACT_APP_URL_S3 + "/" + user.logo_path : profile} id="output" width="200" />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex gap-3">
            {/* <Button className="w-100 rounded-3" onClick={cancelConfirm}>
              Cancel
            </Button> */}
            <span
              className="w-100 d-flex justify-content-center align-items-center text-secondary p-2 border-2 border-secondary"
              style={{
                cursor: "pointer",
                border: "2px solid rgba(151, 156, 162, 1)",
                borderRadius: "6px",
              }}
              onClick={cancelConfirm}
            >
              Cancel
            </span>
            <Button className="w-100 rounded-3" disabled={!selectImageURL ? true : false || isLoading} onClick={updateImage}>
              {isLoading ? "Uploading..." : "Update"}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Sidebar;
