import React from "react";
import { Offcanvas } from "react-bootstrap";

function MobileReportsTabs({ show, handleClose, activeTAb, handleTabChange }) {
  return (
    <div>
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header className="border-bottom bg-light " closeButton>
          <Offcanvas.Title>
            <div>{activeTAb.replace(/-/g, " ")}</div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="mt-3">
          <div
            onClick={() => {
              handleTabChange("General-Report");
              handleClose();
            }}
            className={`w-100 p-2  mt-2 ${activeTAb === "General-Report" ? "bg-light primary-color" : ""}`}
          >
            <span className=" ">General Report</span>
          </div>
          <div
            onClick={() => {
              handleTabChange("Daily-Cash-Report");
              handleClose();
            }}
            className={`w-100 p-2 mt-2 ${activeTAb === "Daily-Cash-Report" ? "bg-light primary-color" : ""}`}
          >
            <span className=" ">Daily Cash Report</span>
          </div>
          <div
            onClick={() => {
              handleTabChange("Daily-Transactional-Report");
              handleClose();
            }}
            className={`w-100 p-2 mt-2 ${activeTAb === "Daily-Transactional-Report" ? "bg-light primary-color" : ""}`}
          >
            <span className=" ">Daily Transaction Report</span>
          </div>
          <div
            onClick={() => {
              handleTabChange("Profit/Loss-Report");
              handleClose();
            }}
            className={`w-100 p-2 mt-2 ${activeTAb === "Profit/Loss-Report" ? "bg-light primary-color" : ""}`}
          >
            <span className=" ">Profit/Loss Report</span>
          </div>
          <div
            onClick={() => {
              handleTabChange("Inventory-Report");
              handleClose();
            }}
            className={`w-100 p-2 mt-2 ${activeTAb === "Inventory-Report" ? "bg-light primary-color" : ""}`}
          >
            <span className=" ">Inventory Report</span>
          </div>
          <div
            onClick={() => {
              handleTabChange("Sales-Report");
              handleClose();
            }}
            className={`w-100 p-2 mt-2 ${activeTAb === "Sales-Report" ? "bg-light primary-color" : ""}`}
          >
            <span className=" ">Sales Report</span>
          </div>
          <div
            onClick={() => {
              handleTabChange("Journal");
              handleClose();
            }}
            className={`w-100 p-2 mt-2 ${activeTAb === "Journal" ? "bg-light primary-color" : ""}`}
          >
            <span className=" ">Journal</span>
          </div>
          <div
            onClick={() => {
              handleTabChange("IMEI-Report");
              handleClose();
            }}
            className={`w-100 p-2 mt-2 ${activeTAb === "IMEI-Report" ? "bg-light primary-color  " : ""}`}
          >
            <span className=" ">IMEI Report</span>
          </div>
          <div
            onClick={() => {
              handleTabChange("Bank-Transactions-Report");
              handleClose();
            }}
            className={`w-100 p-2 mt-2 ${activeTAb === "Bank-Transactions-Report" ? "bg-light primary-color" : ""}`}
          >
            <span className=" ">Bank Transactions Report</span>
          </div>
          <div
            onClick={() => {
              handleTabChange("Misc-Items-History-Report");
              handleClose();
            }}
            className={`w-100 p-2 mt-2 ${activeTAb === "Misc-Items-History-Report" ? "bg-light primary-color" : ""}`}
          >
            <span className=" ">Misc Items History Report</span>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

export default MobileReportsTabs;
