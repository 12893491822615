import axios from "axios";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import style from "./CartInputPart.module.css";
import SearchableSelect from "../../SearchableSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleUser, faXmark } from "@fortawesome/free-solid-svg-icons";
import ReactInputMask from "react-input-mask";
import { useDispatch } from "react-redux";
import { addCustomer, onChangeShippingCost, onChangeWalkInCustomer, removeCustomer, updateDiscount } from "../../../Features/cart/cartSlice";

function CartInputPart({ salesPerson, setSalesPerson }) {
  const { user } = useSelector((state) => state.auth);
  const user_type = user.user_type;
  const userId = user.user_id;

  const location = useSelector((state) => state.location.location);
  const cart = useSelector((state) => state.cart);
  const [customerNumber, setCustomerNumber] = useState(null);
  const [customerName, setCustomerName] = useState(null);
  const [customerType, setCustomerType] = useState("Individual");

  const [totalSalesPersons, setTotalSalesPerson] = useState(0);
  const [salesPersonsFields, setSalesPersonsFields] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (location?.value) {
      axios
        .get(process.env.REACT_APP_API_URL + `/user/get-secondary-sales-persons/${user?.shop_id}`, {
          withCredentials: true,
        })
        .then((res) => {
          const getData = res.data?.employees?.map((user) => {
            return {
              value: user.user_id,
              label: (
                <>
                  Sales Person: <span style={{ fontSize: "12px", fontWeight: "bold" }}>{user.user_full_name}</span>
                  <br />
                  <span>
                    Email: <span style={{ textTransform: "lowercase" }}>{user.user_email}</span>
                  </span>
                  <br />
                  <span>Phone: {user.whatsapp_number}</span>
                  <br />
                </>
              ),
              name: user.user_full_name,
            };
          });

          setTotalSalesPerson(res.data.total[0].totalSalesPersons);
          setSalesPersonsFields(getData);
        })
        .catch((err) => {
          throw new Error(err);
        });
    }
  }, [location, user]);
  const handleSalesPerson = (e) => {
    setSalesPerson({ value: e.value, label: e.name });
  };

  const fetchCustomer = async (number) => {
    try {
      const res = await axios.get(process.env.REACT_APP_API_URL + `/customer/get-by-number?number=${number.substring(1, number.length)}`, {
        withCredentials: true,
      });
      dispatch(addCustomer(res.data.success.data));
    } catch (error) {
      if (error?.response?.status === 404) {
        updateCustomerInfo({ customer_number: number });
        // console.log(error?.response?.data?.error, "ERROR");
      } else {
        console.log(error, "ERROR");
      }
    }
  };

  const handleCollectiveDiscount = (e) => {
    var num = e.target.value;
    // setDiscountCheck((prev) => {
    //   if (prev.length > 0 && num.length === 0) {
    //     discountRef.current = true;
    //   } else {
    //     discountRef.current = false;
    //   }
    //   return num;
    // });
    dispatch(updateDiscount(num));
  };

  const updateCustomerInfo = ({ customer_number, customer_name, customer_type }) => {
    dispatch(
      addCustomer({
        customer_number: customer_number ? customer_number : customerNumber,
        customer_name: customer_name ? customer_name : customerName,
        customer_type: customer_type ? customer_type : customerType,
      })
    );
  };
  return (
    <div className={`d-flex flex-column gap-2 w-100 p-1`}>
      <div className={`pb-1 border-bottom`}>
        <div className="d-flex justify-content-center gap-2 p-2">
          <input
            type="checkbox"
            value={cart.isCustomerWalkIn}
            onChange={(e) => {
              dispatch(onChangeWalkInCustomer(e.target.checked));
            }}
            checked={cart.isCustomerWalkIn}
          />{" "}
          <label style={{ fontSize: "14px" }}>Walk-in Customer</label>
        </div>
        {!cart.isCustomerWalkIn && cart?.customer?.id ? (
          <div style={{ background: "#F5F6FA" }} className={`p-1 rounded p-1`}>
            <div className="d-flex  flex-column py-2">
              <div className={`d-flex justify-content-start align-items-center gap-1`}>
                <FontAwesomeIcon className={`mb-2 rounded-circle text-white`} icon={faCircleUser} style={{ width: "42px", height: "42px" }} />

                <h5 className="ms-1" style={{ fontSize: "16px" }}>
                  {cart?.customer?.customer_name}
                </h5>
                <div className={`h-100 p-1 ms-auto`}>
                  <FontAwesomeIcon
                    className={`cursor-pointer`}
                    icon={faXmark}
                    onClick={() => {
                      dispatch(removeCustomer());
                    }}
                  />
                </div>
              </div>
              {cart?.customer?.customer_number || cart?.customer?.customer_email ? (
                <>
                  <hr className={`m-0`} />
                  <div className=" pt-1 px-2">
                    <table style={{ fontSize: "12px" }}>
                      {cart?.customer?.customer_number ? (
                        <tr>
                          <td>
                            <strong>Phone</strong>
                          </td>
                          <td>
                            <strong>:</strong>
                          </td>
                          <td> {cart?.customer?.customer_number}</td>
                        </tr>
                      ) : (
                        <></>
                      )}
                      {cart?.customer?.customer_email ? (
                        <tr>
                          <td>
                            <strong>Email</strong>
                          </td>
                          <td>
                            <strong>:</strong>
                          </td>
                          <td> {cart?.customer?.customer_email}</td>
                        </tr>
                      ) : (
                        <></>
                      )}
                    </table>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        ) : (
          <div className="d-flex flex-column gap-2">
            <div className={`d-flex w-100 align-items-center`}>
              <Form.Label style={{ fontSize: "12px", fontWeight: "400", width: "45%" }}>Customer{!cart.isCustomerWalkIn ? <span className="text-danger">*</span> : <></>}</Form.Label>
              <Form.Control
                as={ReactInputMask}
                mask="+\92-399-9999999"
                maskChar={"_"}
                size="sm"
                alwaysShowMask
                style={{ fontSize: "12px" }}
                permanents={[0, 1, 2, 3, 4, 7]}
                value={customerNumber}
                onChange={(e) => {
                  setCustomerNumber(e.target.value);
                  if (e.target.value.replaceAll("_", "").length === 15) {
                    // fetch customer here
                    fetchCustomer(e.target.value);
                  }
                }}
                disabled={cart.isCustomerWalkIn}
              />
            </div>
            {!cart?.customer?.id && cart?.customer?.customer_number ? (
              <>
                <div className={`d-flex w-100 align-items-center`}>
                  <Form.Label style={{ fontSize: "12px", fontWeight: "400", width: "45%" }}>
                    Name<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    placeholder="i.e. John Doe"
                    size="sm"
                    value={customerName}
                    onChange={(e) => {
                      setCustomerName(e.target.value);
                    }}
                    onBlur={(e) => updateCustomerInfo({ customer_name: e.target.value })}
                    disabled={cart.isCustomerWalkIn}
                  />
                </div>
                <div className={`w-100`}>
                  <Form.Group className="">
                    <Form.Check
                      inline
                      type="radio"
                      value="Individual"
                      label="Individual"
                      name="customerType"
                      checked={customerType === "Individual"}
                      onChange={(e) => {
                        setCustomerType(e.target.value);
                        updateCustomerInfo({ customer_type: e.target.value });
                      }}
                    />
                    <Form.Check
                      inline
                      type="radio"
                      value="Business"
                      label="Business"
                      name="customerType"
                      checked={customerType === "Business"}
                      onChange={(e) => {
                        setCustomerType(e.target.value);
                        updateCustomerInfo({ customer_type: e.target.value });
                      }}
                    />
                  </Form.Group>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        )}
      </div>
      {(totalSalesPersons > 0 && user_type === "primary" && user.role === null) || (totalSalesPersons > 0 && user_type === "secondary" && user.role === "Cashier") ? (
        <div className={`mb-1 ${style.salesPersonDropdown}`} style={{ fontSize: "12px" }}>
          <SearchableSelect
            // style={{fontSize: '12px'}}
            // label="Sales Person"
            placeholder="Select Sales Person"
            options={salesPersonsFields}
            value={salesPerson}
            handleChange={handleSalesPerson}
            filterOption={(option, searchString) => {
              return option?.data?.name?.toLowerCase().includes(searchString?.toLowerCase());
            }}
          />

          <Form.Control hidden type="text" name="salesPerson" value={salesPerson?.name} onChange={() => {}} />
        </div>
      ) : (totalSalesPersons === 0 && user_type === "Primary" && user.role === "null") || (totalSalesPersons === 0 && user_type === "secondary" && user.role === "Cashier") ? (
        ""
      ) : totalSalesPersons > 0 && user_type === "secondary" && user.role === "Sales Person" ? (
        <Form.Group className="mb-3" controlId="salesPerson">
          <SearchableSelect disabled={true} searchable={false} value={{ value: userId, label: user.user_full_name }} />
        </Form.Group>
      ) : (
        ""
      )}

      <div className={`d-flex w-100 align-items-center`}>
        <Form.Label style={{ fontSize: "12px", fontWeight: "400", width: "45%" }}>Shipping Cost</Form.Label>
        <Form.Control
          type="number"
          className={`p-2 rounded w-100`}
          placeholder="0"
          min="0"
          size="sm"
          style={{ fontSize: "12px" }}
          onKeyDown={(e) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
          value={cart?.shippingCost && cart?.shippingCost !== 0 ? cart?.shippingCost : ""}
          onInput={(e) => (e.target.value = e.target.value.slice(0, 10))}
          onChange={(e) => {
            if (+e.target.value > -1) {
              dispatch(onChangeShippingCost(+e.target.value));
            }
          }}
          onBlur={(e) => {
            if (e.target.value.length === 0) {
              dispatch(onChangeShippingCost(0));
            }
          }}
        />
      </div>
      {cart?.shippingCost && cart?.shippingCost !== 0 && +cart?.shippingCost > cart?.totalCartAmount - cart?.totalCartDiscount ? (
        <div style={{ fontSize: "12px", textAlign: "center", width: "100%", padding: "5px", marginTop: "-5px", marginBottom: "0px" }} className="alert alert-warning">
          The shipping cost you entered is greater than the total amount
        </div>
      ) : (
        <></>
      )}
      <div className={`d-flex w-100 align-items-center`}>
        <Form.Label style={{ fontSize: "12px", fontWeight: "400", width: "45%" }}>Discount</Form.Label>
        <Form.Control
          type="text"
          className={`p-2 rounded w-100`}
          placeholder="0"
          size="sm"
          style={{ fontSize: "12px" }}
          min="0"
          onKeyDown={(e) =>
            [
              "!",
              "#",
              "$",
              "^",
              "&",
              "*",
              "(",
              ")",
              ":",
              "'",
              "/",
              "`",
              "{",
              "}",
              "[",
              "]",
              "~",
              "+",
              "@",
              "a",
              "b",
              "c",
              "d",
              "e",
              "f",
              "g",
              "h",
              "i",
              "j",
              "k",
              "l",
              "m",
              "n",
              "o",
              "p",
              "q",
              "r",
              "s",
              "t",
              "u",
              "v",
              "w",
              "x",
              "y",
              "z",
              "A",
              "B",
              "C",
              "D",
              "E",
              "F",
              "G",
              "H",
              "I",
              "J",
              "K",
              "L",
              "M",
              "N",
              "O",
              "P",
              "Q",
              "R",
              "S",
              "T",
              "U",
              "V",
              "W",
              "X",
              "Y",
              "Z",
              "-",
              "=",
              ";",
              ",",
              ".",
              "_",
              "|",
              ">",
              "<",
              "?",
              '"',
            ].includes(e.key) && e.preventDefault()
          }
          value={cart?.discount ? cart?.discount : ""}
          onInput={(e) => {
            if (e.target.value.includes("%")) {
              if (+e.target.value.split("%")[0] > 100) {
                e.target.value = null;
              }
            } else if (!e.target.value.includes("%")) {
              if (!Number(e.target.value > cart?.totalCartAmount)) {
                e.target.value = e.target.value;
              } else {
                e.target.value = null;
              }
            } else {
              e.target.value = null;
            }
          }}
          onChange={(e) => handleCollectiveDiscount(e)}
        />
      </div>
    </div>
  );
}

export default CartInputPart;
