import React, { useState } from "react";
import { Button, Dropdown, Modal, Table, Row, Col } from "react-bootstrap";

import noImage from "../../assets/svg/no-image.svg";

import LodingGif from "../../assets/images/RollingLoader.gif";

import triangle from "../../assets/svg/triangle.svg";
import dangerline from "../../assets/svg/dangerline.svg";

import sortAsc from "../../assets/svg/icon.svg";
import sortDesc from "../../assets/svg/Up.svg";

import removeIcon from "../../assets/svg/remove.svg";

import editpencil from "../../assets/svg/editpencil.svg";
import styles from "../../styles/CustomizeTable.module.css";
import ellipsis from "../../assets/svg/Ellipsis.svg";
import printIcon from "../../assets/svg/print.svg";
import emailIcon from "../../assets/svg/email.svg";
import eyeIcon from "../../assets/svg/union.svg";
import batchIcon from "../../assets/svg/batchIcon.svg";
import barcodeIcon from "../../assets/svg/barcode.svg";

import purchaseOrderIcon from "../../assets/svg/purchase_order.svg";
import paymentIcon from "../../assets/svg/paymentIcon.svg";
import settingsgif from "../../assets/images/settings.gif";
import archiveIcon from "../../assets/svg/archive.svg";
import unarchiveIcon from "../../assets/svg/Unarchive.svg";
import duplicateIcon from "../../assets/svg/save.svg";
import settingsIcon from "../../assets/svg/settings.svg";
import depositIcon from "../../assets/svg/depositIcon.svg";
import VendorCaret from "../VendorCaret";
import { dateTime } from "../../Features/dateTime";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare, faClose, faFileExcel, faFileExport, faRectangleList } from "@fortawesome/free-solid-svg-icons";
import featuredIcon from "../../assets/svg/featuredIcon.svg";
import moment from "moment";
import axios from "axios";

const transform = (text) => {
  const temp = text.split("_");
  let ret = "";
  temp.forEach((txt) => {
    if (txt.toLowerCase() === "no") {
      ret += " " + txt.charAt(0).toUpperCase() + txt.slice(1) + ".";
    } else if (txt.toLowerCase() === "incl") {
      ret += " " + txt.charAt(0).toUpperCase() + txt.slice(1) + ".";
    } else {
      ret += " " + txt.charAt(0).toUpperCase() + txt.slice(1);
    }
  });
  return ret;
};
const ThumbnailImage = ({ srcInput }) => {
  const [imgSrc, setImgSrc] = useState(process.env.REACT_APP_URL_S3 + "/" + srcInput);

  return (
    <img
      style={{ borderRadius: "6px" }}
      width="32"
      height="32"
      alt="thumbnail"
      src={imgSrc}
      className="me-2"
      onError={() => {
        setImgSrc(noImage);
      }}
    />
  );
};

const CustomizeTableview = ({
  data,
  dummy,
  cancel,
  done,
  settingsModal,
  highlight,
  settings = null,
  edit = null,
  remove = null,
  duplicate = null,
  archive = null,
  featured = null,
  unarchive = null,
  columnNotShow = [],
  dateColumn = [],
  datetimeColumn = [],
  sortColumn = [],
  thumbnail = null,
  inventoryActions,
  orderBy = null,
  setOrderBy = null,
  status = null,
  className,
  style,
  print = null,
  view = null,
  email = null,
  purchaseOrder = null,
  payment = null,
  refund = null,
  actionButton = null,
  actionButton1 = null,
  SimpleLink,
  actions = true,
  caret = null,
  hyperlink = null,
  batches = null,
  caretTitle,
  caretData,
  total,
  totalReports,
  isToggled,
  viewImei,
  barcode,
  deleteBatch = null,
  editBatch = null,
  url,
  message,
  borderRounded = true,
  checkbox,
  link,
  totalPayments,
  netSales,
  salesDate,
  fixedColumnTable = false,
  firstColumnWidth,
  list,
  deposit = null,
}) => {
  const [id, setId] = useState(-1);
  const [modelDeleteShow, setModalDeleteShow] = useState(false);
  const [modelArchiveShow, setModalArchiveShow] = useState(false);
  const [modelUnArchiveShow, setModalUnArchiveShow] = useState(false);
  const [show, setShow] = useState(false);

  const showDropdown = (e) => {
    setShow(!show);
  };
  const hideDropdown = (e) => {
    setShow(false);
  };
  const [caretState, setCaretState] = useState({
    id: null,
  });
  const ListColumn = ({ data }) => {
    return (
      <td className={`border-bottom-0  text-wrap`}>
        {data.length > 0 && data.includes(",") ? (
          <>
            {data.split(",").map((item, index) => {
              const trimmedItem = item.replace("[Trade-in]", "");
              return <li key={index}>{trimmedItem}</li>;
            })}
          </>
        ) : (
          <li>{data.replace("[Trade-in]", "")}</li>
        )}
      </td>
    );
  };

  const deleteConfirm = (id) => {
    if (inventoryActions) {
      inventoryActions?.remove(id);
      setModalDeleteShow(false);
    } else {
      remove(id);
      setModalDeleteShow(false);
    }
  };

  const featuredConfirm = async (id) => {
    const currentTimestamp = moment.now();
    const currentDate = moment(currentTimestamp).format("YYYY-MM-DD");
    await axios
      .post(`${process.env.REACT_APP_API_URL}/featured-listings/add`, { fk_listing_id: id, featured_since: currentDate, allowed_duration: "2024-01-01", request_approved_by: -1 })
      .then((res) => {
        setShow(false);
        return res;
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const deleteInventory = (id) => {};
  const cancelConfirm = () => {
    setModalDeleteShow(false);
    setModalArchiveShow(false);
    setModalUnArchiveShow(false);
    setShow(false);
  };
  const archiveConfirm = (id) => {
    if (inventoryActions) {
      inventoryActions?.archive(id);
    } else {
      archive.action(id);
    }
    setModalArchiveShow(false);
  };
  const unarchiveConfirm = (id) => {
    if (inventoryActions) {
      inventoryActions?.unarchive(id);
    } else {
      unarchive.action(id);
    }
    setModalUnArchiveShow(false);
  };

  const handleSort = (column) => {
    if (orderBy) {
      const temp = new Object();
      if (Object.keys(orderBy)[0] === column) {
        if (orderBy[column] === "ASC") {
          temp[column] = "DESC";
        } else {
          temp[column] = "ASC";
        }
      } else {
        temp[column] = "ASC";
      }
      setOrderBy(temp);
    }
  };

  const handleCheckbox = (e, row) => {
    if (e.target.checked) {
      checkbox.setter((prev) => [...prev, row]);
    } else {
      checkbox.setter((prev) => prev.filter((item) => item[Object.keys(item)[0]] !== row[Object.keys(row)[0]]));
    }
  };

  const checkAllHandle = (e) => {
    if (e.target.checked) {
      checkbox.setter((prev) => [...prev, ...data.filter((item) => !checkbox.data.some((subItem) => subItem[Object.keys(subItem)[0]] === item[Object.keys(item)[0]]))]);
    } else {
      checkbox.setter((prev) => prev.filter((item) => !data.some((subItem) => subItem[Object.keys(subItem)[0]] === item[Object.keys(item)[0]])));
    }
  };

  const tdNotShow = [...columnNotShow, ...dateColumn, ...datetimeColumn];
  const thNotShow = [...columnNotShow, thumbnail?.description, thumbnail?.img];
  if (thumbnail) {
    tdNotShow.push(thumbnail?.img, thumbnail?.description);
  }
  var statusVar = Array.isArray(status?.column) ? {} : undefined;
  var actionButtonVar = Array.isArray(actionButton?.column) ? {} : undefined;
  var actionButton1Var = Array.isArray(actionButton1?.column) ? {} : undefined;

  const dataFormatForTd = (key, value) => {
    if (!tdNotShow?.includes(key)) {
      if (Array.isArray(status?.column)) {
        if (status?.column.some((col) => key === col)) {
          statusVar[key] = value;
        }
      } else {
        if (status?.column === key) {
          statusVar = value;
        }
      }
      if (Array.isArray(actionButton?.column)) {
        if (actionButton?.column.some((col) => key === col)) {
          actionButtonVar[key] = value;
        }
      } else {
        if (actionButton?.column === key) {
          actionButtonVar = value;
        }
      }
      if (Array.isArray(actionButton1?.column)) {
        if (actionButton1?.column.some((col) => key === col)) {
          actionButton1Var[key] = value;
        }
      } else {
        if (actionButton1?.column === key) {
          actionButton1Var = value;
        }
      }

      if (value === null || value === "") {
        return "--";
      } else {
        return value?.toString();
      }
    } else {
      if (Array.isArray(status?.column)) {
        if (status?.column.some((col) => key === col)) {
          statusVar[key] = value;
        }
      } else {
        if (status?.column === key) {
          statusVar = value;
        }
      }
      if (Array.isArray(actionButton?.column)) {
        if (actionButton?.column.some((col) => key === col)) {
          actionButtonVar[key] = value;
        }
      } else {
        if (actionButton?.column === key) {
          actionButtonVar = value;
        }
      }
      if (Array.isArray(actionButton1?.column)) {
        if (actionButton1?.column.some((col) => key === col)) {
          actionButton1Var[key] = value;
        }
      } else {
        if (actionButton1?.column === key) {
          actionButton1Var = value;
        }
      }
      if (dateColumn?.includes(key)) {
        if (value) {
          const dateFormat = dateTime(value).toDateString().split(" ").slice(1);
          return `${dateFormat[1]}  ${dateFormat[0]}, ${dateFormat[2]}`;
        } else {
          return "--";
        }
      } else if (datetimeColumn?.includes(key)) {
        if (value) {
          const dateFormat = dateTime(value);
          return dateFormat.toDateString() + " " + dateFormat.toLocaleTimeString();
        } else {
          return "--";
        }
      }
    }
  };

  return (
    <>
      {settingsModal && (
        <div className={`${styles.settingsDiv} justify-content-end d-flex`}>
          <button onClick={settingsModal} variant="light" className={`${styles.settingsBtn} p-2 rounded bg-light border `}>
            {" "}
            <img src={settingsgif} alt="" /> Table Settings
          </button>
        </div>
      )}
      <div className={`table ${styles.customizeTableDiv} ${fixedColumnTable ? styles.fixedColumnTable : ""}`}>
        <Table responsive striped className={`${borderRounded ? "rounded-3" : ""} bg-white border-1 border-secondary overflow-auto m-0  text-nowrap ${caret ? styles.table_main_caret : styles.table_main}   ${className}`}>
          <thead
            className={`${isToggled === false ? `d-none` : ""} text-secondary`}
            style={{
              fontSize: "13px",
              fontWeight: "400",
              lineHeight: "18px",
            }}
          >
            <tr>
              {caret && <th className={`py-3 `}>{""}</th>}
              {thumbnail ? (
                <th
                  className={`py-3`}
                  style={{
                    ...(orderBy && Object.keys(orderBy)[0] === thumbnail?.description ? { color: "#9537FF", fontWeight: "bold" } : { fontWeight: "400" }),
                    ...(sortColumn?.includes(thumbnail?.description) ? { cursor: "pointer" } : {}),
                  }}
                  onClick={() => {
                    if (sortColumn) {
                      if (sortColumn?.includes(thumbnail?.description)) {
                        handleSort(thumbnail?.description);
                      }
                    }
                  }}
                >
                  <div
                  // className={checkbox ? "ms-4" : ""}
                  >
                    {checkbox && !checkbox?.isNotSelectAll ? (
                      <input type="checkbox" onClick={checkAllHandle} style={{ zIndex: "100" }} className={`${checkbox ? ` mx-2 ` : ""} `} checked={data.every((item) => checkbox?.data?.some((subItem) => subItem[Object.keys(subItem)[0]] === item[Object.keys(item)[0]]))} />
                    ) : (
                      <></>
                    )}
                    {transform(thumbnail?.title)}
                    {sortColumn && sortColumn?.includes(thumbnail?.description) && (
                      <img
                        style={{
                          pointerEvents: "none",
                        }}
                        alt="thumbnail"
                        className="ms-1"
                        src={orderBy[thumbnail?.description] === "ASC" ? sortDesc : sortAsc}
                      />
                    )}
                  </div>
                </th>
              ) : (
                ""
              )}
              {list && <th className="py-3">Item(s)</th>}
              {Object.keys(data[0]).map((s, index) => {
                if (totalPayments && totalPayments.column === s) {
                  return (
                    <th
                      className={` py-3 border-start ${index === 1 ? styles.shadow : ""} `}
                      key={index}
                      style={{
                        ...(orderBy && Object.keys(orderBy)[0] === s ? { color: "#9537FF", fontWeight: "bold" } : { fontWeight: "400" }),
                        ...(sortColumn.includes(s) ? { cursor: "pointer" } : {}),
                      }}
                      onClick={() => {
                        if (sortColumn) {
                          if (sortColumn.includes(s)) {
                            handleSort(s);
                          }
                        }
                      }}
                    >
                      {s ? <span className="fw-bold">{transform(s)}</span> : "0"}
                    </th>
                  );
                } else if (salesDate && salesDate.column === s) {
                  return (
                    <th
                      className={` py-3 border-start   ${index === 3 ? styles.shadow : ""} `}
                      key={index}
                      style={{
                        width: "100px !important",
                        minWidth: "100px !important",
                      }}
                      onClick={() => {
                        if (sortColumn) {
                          if (sortColumn.includes(s)) {
                            handleSort(s);
                          }
                        }
                      }}
                    >
                      {s ? <span className="">{transform(s)}</span> : "0"}
                    </th>
                  );
                } else if (netSales && netSales.column === s) {
                  return (
                    <th
                      className={` py-3 border-start ${index === 1 ? styles.shadow : ""} `}
                      key={index}
                      style={{
                        ...(orderBy && Object.keys(orderBy)[0] === s ? { color: "#9537FF", fontWeight: "bold" } : { fontWeight: "400" }),
                        ...(sortColumn.includes(s) ? { cursor: "pointer" } : {}),
                      }}
                      onClick={() => {
                        if (sortColumn) {
                          if (sortColumn.includes(s)) {
                            handleSort(s);
                          }
                        }
                      }}
                    >
                      {s ? <span className="fw-bold">{transform(s)}</span> : "0"}
                    </th>
                  );
                } else if (!thNotShow?.includes(s)) {
                  return (
                    <th
                      className={` py-3  ${index === 1 ? styles.shadow : ""} `}
                      key={index}
                      style={{
                        ...(orderBy && Object.keys(orderBy)[0] === s ? { color: "#9537FF", fontWeight: "bold" } : { fontWeight: "400" }),
                        ...(sortColumn?.includes(s) ? { cursor: "pointer" } : {}),
                      }}
                      onClick={() => {
                        if (sortColumn) {
                          if (sortColumn?.includes(s)) {
                            handleSort(s);
                          }
                        }
                      }}
                    >
                      <div className={checkbox && Object.keys(data[0]).filter((item) => !thNotShow?.includes(item))[0] === s && !thumbnail ? "ms-4" : ""}>
                        {transform(s)}
                        {sortColumn && sortColumn?.includes(s) && (
                          <img
                            style={{
                              pointerEvents: "none",
                            }}
                            alt="thumbnail"
                            className={"ms-1"}
                            src={orderBy[s] === "ASC" ? sortDesc : sortAsc}
                          />
                        )}
                      </div>
                    </th>
                  );
                }
              })}
              {batches && <th className="py-3">Batches</th>}
              {message && <th className="py-3">Message</th>}
              {barcode && <th className="py-3">Barcode</th>}
              {featured && <th className="py-3">featured</th>}

              {url && <th className="py-3">File</th>}
              {status && <th className="py-3">Status</th>}

              {actions && (
                <th className="border-start text-center py-3" style={{ fontWeight: "400" }}>
                  Actions
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => (
              <>
                <tr className={`${isToggled === false ? `d-none` : ""} border-bottom ${firstColumnWidth === true ? styles.first_column_width : ""} ${highlight && highlight(row) ? styles.highlight : ""}`} key={index}>
                  {caret && (
                    <>
                      <td
                        style={{ cursor: "pointer" }}
                        className="text-center"
                        onClick={() => {
                          setCaretState((prev) => {
                            if (prev.id === row.id) {
                              if (prev.caret) {
                                return { ...prev, caret: false };
                              } else {
                                return { ...prev, caret: true };
                              }
                            } else {
                              return {
                                caret: true,
                                id: row.id,
                              };
                            }
                          });
                          caret(row.id);
                        }}
                      >
                        <VendorCaret caret={caretState} rowId={row.id} />
                      </td>
                    </>
                  )}
                  {thumbnail && (
                    <td className={` ${thumbnail?.className ? thumbnail?.className : ""}`}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "left",
                        }}
                      >
                        {checkbox ? (
                          <>
                            <input type="checkbox" onClick={(e) => handleCheckbox(e, row)} style={{ zIndex: "100" }} className={`${checkbox ? ` mx-2 ` : ""} `} checked={checkbox?.data?.filter((item) => item[Object.keys(item)[0]] === row[Object.keys(row)[0]]).length > 0 ? true : false} />
                            <ThumbnailImage srcInput={row[thumbnail?.img]} />
                            <div
                              style={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {" "}
                              {row[thumbnail?.description]}
                            </div>
                          </>
                        ) : (
                          <>
                            <ThumbnailImage srcInput={row[thumbnail?.img] || row?.img0} />
                            <div
                              style={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {" "}
                              {row[thumbnail?.description]}
                            </div>
                          </>
                        )}
                      </div>
                    </td>
                  )}
                  {list && (
                    <td className="">
                      <ListColumn data={row["title"]} />
                    </td>
                  )}
                  {Object.entries(row).map(([k, v], i) => {
                    if (link && link?.column === k) {
                      return (
                        <td>
                          {v ? (
                            <a href={v} target="_blank">
                              {link?.label}
                            </a>
                          ) : (
                            "--"
                          )}
                        </td>
                      );
                    } else if (salesDate && salesDate.column === k) {
                      return <td className="border-start ">{v ? <span className=" w-100">{v}</span> : "0"}</td>;
                    } else if (netSales && netSales.column === k) {
                      return <td className="border-start   fw-bold">{v ? <span>{v}</span> : "0"}</td>;
                    } else if (totalPayments && totalPayments.column === k) {
                      return <td className="border-start  fw-bold">{v ? <span>{v}</span> : "0"}</td>;
                    } else {
                      const value = dataFormatForTd(k, v);
                      return value?.length > 0 ? (
                        <td key={i}>
                          <div className="d-flex align-items-center">
                            {checkbox && Object.keys(row).filter((item) => !thNotShow?.includes(item))[0] === k && !thumbnail ? (
                              <input
                                type="checkbox"
                                onClick={(e) => handleCheckbox(e, row)}
                                style={{ zIndex: "1000", position: "relative" }}
                                checked={checkbox?.data?.filter((item) => item[Object.keys(item)[0]] === row[Object.keys(row)[0]]).length > 0 ? true : false}
                                className={`${checkbox && Object.keys(row).filter((item) => !thNotShow?.includes(item))[0] === k ? ` mx-2 ` : ""}`}
                              />
                            ) : (
                              <></>
                            )}

                            <span>{value}</span>
                          </div>
                        </td>
                      ) : (
                        <></>
                      );
                    }
                  })}

                  {batches && (
                    <td>
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => batches(row["id"], row)}
                        // onClick={() => batches(row)}
                        className="tooltipp"
                      >
                        <img
                          src={batchIcon}
                          alt="batchIcon"
                          style={{
                            pointerEvents: "none",
                          }}
                        />
                        <span className="tooltiptext">Batches</span>
                      </div>
                    </td>
                  )}
                  {featured && (
                    <td>
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setShow(true);

                          setId(row["id"]);
                        }}
                        className="tooltipp"
                      >
                        <img src={featuredIcon} alt="featuredIcon" style={{}} />
                        <span className="tooltiptext">Featured Item</span>
                      </div>
                    </td>
                  )}
                  {barcode && (
                    <td>
                      <div style={{ cursor: "pointer" }} onClick={() => barcode(row)} className="tooltipp">
                        <img
                          src={barcodeIcon}
                          alt="Barcode icon"
                          style={{
                            pointerEvents: "none",
                          }}
                        />
                        <span className="tooltiptext">Barcode</span>
                      </div>
                    </td>
                  )}
                  {message && (
                    <td>
                      <div>
                        <Button style={{ padding: "8px", fontSize: "12px" }} variant={"outline-secondary"} onClick={() => message(row)}>
                          Click to show
                        </Button>
                      </div>
                    </td>
                  )}
                  {url && (
                    <td>
                      <a className="btn btn-primary fs-14" href={process.env.REACT_APP_URL_S3 + "/" + row["file"]} download={process.env.REACT_APP_URL_S3 + "/" + row["file"]}>
                        Download File{" "}
                      </a>
                    </td>
                  )}
                  {status && <td className={` ${status.style ? status.style(statusVar) : ""}`}>{status.format(statusVar)}</td>}
                  {actions && (
                    <td
                      className={` border-start  `}
                      // style={{ maxWidth: "15%", width: "100%" }}
                    >
                      <div className="d-lg-flex align-items-center justify-content-end gap-3 px-2 d-none ">
                        {actionButton1 && actionButton1?.isShow(actionButton1Var) && (
                          <div>
                            <Button
                              className={actionButton1.className}
                              style={actionButton1.style}
                              variant={typeof actionButton1.variant === "function" ? actionButton1.variant(row["id"], row) : actionButton1.variant}
                              onClick={() => {
                                actionButton1.onClick(row["id"], row);
                              }}
                            >
                              {typeof actionButton1.label === "function" ? actionButton1.label(row) : actionButton1.label}
                            </Button>
                          </div>
                        )}
                        {actionButton && actionButton?.isShow(actionButtonVar, row) && (
                          <div>
                            <Button
                              className={actionButton.className}
                              style={actionButton.style}
                              variant={typeof actionButton.variant === "function" ? actionButton.variant(row["id"], row) : actionButton.variant}
                              onClick={() => {
                                actionButton.onClick(row["id"], row);
                              }}
                              {...(typeof actionButton?.disabled === "function" ? { disabled: actionButton?.disabled(row) } : actionButton?.disabled ? { disabled: true } : {})}
                            >
                              {typeof actionButton.label === "function" ? actionButton.label(row) : actionButton.label}
                            </Button>
                          </div>
                        )}

                        {refund && refund?.isShow(actionButtonVar, row) && (
                          <div>
                            <Button style={{ padding: "8px", fontSize: "12px" }} variant={"outline-primary"} onClick={() => refund.onClick(row["id"])}>
                              Refund
                            </Button>
                          </div>
                        )}

                        {duplicate && (
                          <div style={{ cursor: "pointer" }} onClick={() => duplicate(row["id"], row["is_miscellaneous"])} className="tooltipp">
                            <img
                              src={duplicateIcon}
                              alt="save"
                              style={{
                                pointerEvents: "none",
                              }}
                            />
                            <span className="tooltiptext">Duplicate</span>
                          </div>
                        )}
                        {settings && (
                          <div style={{ cursor: "pointer" }} onClick={() => settings(row["id"])} className="tooltipp">
                            <img
                              src={settingsIcon}
                              alt="edit"
                              style={{
                                pointerEvents: "none",
                              }}
                            />
                            <span className="tooltiptext">Reset Password</span>
                          </div>
                        )}
                        {(typeof edit === "object" ? edit?.isShow(row) : edit) && (
                          <div style={{ cursor: "pointer" }} onClick={() => (typeof edit === "object" ? edit?.action(row["id"], row) : edit(row["id"], row))} className="tooltipp">
                            <img
                              src={editpencil}
                              alt="edit"
                              style={{
                                pointerEvents: "none",
                              }}
                            />
                            <span className="tooltiptext">Edit</span>
                          </div>
                        )}
                        {archive && archive.isShow(row) && (
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setModalArchiveShow(true);
                              setId(row["id"]);
                            }}
                            className="tooltipp"
                          >
                            <img
                              src={archiveIcon}
                              alt="archive"
                              style={{
                                pointerEvents: "none",
                              }}
                            />
                            <span className="tooltiptext">Archive</span>
                          </div>
                        )}
                        {unarchive && unarchive.isShow(row) && (
                          <div
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setModalUnArchiveShow(true);
                              setId(row["id"]);
                            }}
                            className="tooltipp"
                          >
                            <img
                              src={unarchiveIcon}
                              alt="unarchive"
                              style={{
                                pointerEvents: "none",
                              }}
                            />
                            <span className="tooltiptext">Unarchive</span>
                          </div>
                        )}
                        {remove && (
                          <div
                            onClick={(e) => {
                              setModalDeleteShow(true);
                              setId(row["id"]);
                            }}
                            style={{
                              cursor: "pointer",
                            }}
                            className="tooltipp"
                          >
                            <img
                              src={removeIcon}
                              alt="removeIcon"
                              style={{
                                pointerEvents: "none",
                              }}
                            />
                            <span className="tooltiptext">Delete</span>
                          </div>
                        )}
                        {editBatch && row.purchase_order === "No PO" && (
                          <div style={{ cursor: "pointer" }} onClick={() => editBatch(row["id"], row)} className="tooltipp">
                            <img
                              src={editpencil}
                              alt="edit"
                              style={{
                                pointerEvents: "none",
                              }}
                            />
                            <span className="tooltiptext">Edit</span>
                          </div>
                        )}
                        {deleteBatch && row.purchase_order === "No PO" && (
                          <div
                            onClick={() => {
                              deleteBatch(row, row["batch_id"]);
                            }}
                            style={{
                              cursor: "pointer",
                            }}
                            className="tooltipp"
                          >
                            <img
                              src={removeIcon}
                              alt="removeIcon"
                              style={{
                                pointerEvents: "none",
                              }}
                            />
                            <span className="tooltiptext">Delete</span>
                          </div>
                        )}
                        {(typeof print === "object" ? print?.isShow(row) : print) && (
                          <div style={{ cursor: "pointer" }} onClick={() => (typeof print === "object" ? print?.action(row["id"]) : print(row["id"]))} className="tooltipp">
                            <img
                              src={printIcon}
                              alt="removeIcon"
                              style={{
                                pointerEvents: "none",
                              }}
                            />
                            <span className="tooltiptext">Print</span>
                          </div>
                        )}
                        {(typeof view === "object" ? view?.isShow(row) : view) && (
                          <div style={{ cursor: "pointer" }} onClick={() => (typeof view === "object" ? view?.action(row["id"]) : view(row["id"]))} className="tooltipp">
                            <img
                              src={eyeIcon}
                              alt="removeIcon"
                              style={{
                                pointerEvents: "none",
                              }}
                            />
                            <span className="tooltiptext">View</span>
                          </div>
                        )}
                        {(typeof email === "object" ? email?.isShow(row) : email) && (
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              if (row["customer_email"] !== "" && row["vendor_email"] !== "" && row["vendor_email"] !== "nil") {
                                typeof email === "object" ? email.action(row["id"]) : email(row["id"]);
                              }
                            }}
                            className={`tooltipp ${row["customer_email"] !== "" ? "" : "opacity"} `}
                          >
                            <img
                              src={emailIcon}
                              alt="removeIcon"
                              style={{
                                pointerEvents: "none",
                              }}
                            />

                            <span className="tooltiptext">Email</span>
                          </div>
                        )}
                        {viewImei && row["imei"] && (
                          <div
                            // style={{ cursor: "pointer" }}
                            onClick={() => viewImei(row)}
                            className="tooltipp cursor-pointer"
                          >
                            <FontAwesomeIcon icon={faRectangleList} />
                            <span className="tooltiptext"> View Imei</span>
                          </div>
                        )}
                        {payment && (
                          <div style={{ cursor: "pointer" }} onClick={() => payment(row)} className="tooltipp">
                            <img
                              src={paymentIcon}
                              alt="payment Icon"
                              style={{
                                pointerEvents: "none",
                              }}
                            />
                            <span className="tooltiptext">Payments</span>
                          </div>
                        )}
                        {purchaseOrder && (
                          <div style={{ cursor: "pointer" }} onClick={() => purchaseOrder(row["id"])} className="tooltipp">
                            <img
                              src={purchaseOrderIcon}
                              alt="PurchaseOrderIcon"
                              style={{
                                pointerEvents: "none",
                              }}
                            />
                            <span className="tooltiptext">Purchase Order</span>
                          </div>
                        )}
                        {/* {barcode && (
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => barcode(row)}
                            className="tooltipp"
                          >
                            <img
                              src={barcodeIcon}
                              alt="Barcode icon"
                              style={{
                                pointerEvents: "none",
                              }}
                            />
                            <span className="tooltiptext">Barcode</span>
                          </div>
                        )} */}
                        {dummy && (
                          <div style={{ cursor: "pointer" }} onClick={() => dummy(row)} className="tooltipp">
                            <FontAwesomeIcon icon={faFileExcel} />
                            <span className="tooltiptext">Dummy</span>
                          </div>
                        )}
                        {done && row.status === "Pending" && (
                          <div style={{ cursor: "pointer" }} onClick={() => done(row.id)} className="tooltipp">
                            <FontAwesomeIcon icon={faCheckSquare} />
                            <span className="tooltiptext">Done</span>
                          </div>
                        )}
                        {cancel && row.status === "Pending" && (
                          <div style={{ cursor: "pointer" }} onClick={() => cancel(row.id)} className="tooltipp">
                            <FontAwesomeIcon icon={faClose} />
                            <span className="tooltiptext">Cancel</span>
                          </div>
                        )}
                        {/* {batches && (
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => batches(row["id"])}
                            className="tooltipp"
                          >
                            <img
                              src={batchIcon}
                              alt="batchIcon"
                              style={{
                                pointerEvents: "none",
                              }}
                            />
                            <span className="tooltiptext">Batches</span>
                          </div>
                        )} */}
                        {hyperlink && (
                          <Link to={hyperlink.link(row["id"])} target={hyperlink.target}>
                            {hyperlink.label(row["id"])}
                          </Link>
                        )}
                        {SimpleLink && (
                          <Link to={SimpleLink.link(row)} target={"_blank"}>
                            {SimpleLink.label}
                          </Link>
                        )}
                        {deposit && (
                          <div style={{ cursor: "pointer" }} onClick={() => deposit(row.id)} className="tooltipp">
                            <img
                              src={depositIcon}
                              alt="depositIcon"
                              style={{
                                pointerEvents: "none",
                              }}
                            />
                            <span className="tooltiptext">Deposit</span>
                          </div>
                        )}
                      </div>

                      {inventoryActions && (
                        <Dropdown className="d-flex  d-none d-lg-flex d-md-none justify-content-center">
                          <Dropdown.Toggle variant="light" className={` border-0 p-0`} id={row["id"]} style={{ background: "transparent" }}>
                            <img src={ellipsis} alt="ellipsis" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu className=" position-fixed rounded-0 border-0 shadow">
                            {/* {actionButton &&
                              actionButton?.isShow(actionButtonVar) && (
                                <Dropdown.Item
                                  className="purple"
                                  onClick={() => {
                                    actionButton.onClick(row["id"], row);
                                  }}
                                >
                                  {actionButton.label}
                                </Dropdown.Item>
                              )} */}
                            {inventoryActions?.edit && (
                              <Dropdown.Item className="purple" onClick={() => inventoryActions?.edit(row["id"], row["is_miscellaneous"])}>
                                <img
                                  src={editpencil}
                                  alt="edit"
                                  className="me-2"
                                  style={{
                                    pointerEvents: "none",
                                  }}
                                />{" "}
                                Edit
                              </Dropdown.Item>
                            )}
                            {inventoryActions?.duplicate && (
                              <Dropdown.Item className="purple" onClick={() => inventoryActions?.duplicate(row["id"], row["is_miscellaneous"])}>
                                <img
                                  src={duplicateIcon}
                                  alt="save"
                                  className="me-2"
                                  style={{
                                    pointerEvents: "none",
                                  }}
                                />{" "}
                                Duplicate
                              </Dropdown.Item>
                            )}
                            {inventoryActions?.archive && (
                              <Dropdown.Item
                                className="purple"
                                onClick={() => {
                                  setModalArchiveShow(true);
                                  setId(row["id"]);
                                }}
                              >
                                <img
                                  src={archiveIcon}
                                  alt="archive"
                                  className="me-2"
                                  style={{
                                    pointerEvents: "none",
                                  }}
                                />
                                Archive
                              </Dropdown.Item>
                            )}
                            {inventoryActions?.unarchive && (
                              <Dropdown.Item
                                className="purple"
                                onClick={() => {
                                  setModalUnArchiveShow(true);
                                  setId(row["id"]);
                                }}
                              >
                                <img
                                  src={unarchiveIcon}
                                  alt="unarchive"
                                  className="me-2"
                                  style={{
                                    pointerEvents: "none",
                                  }}
                                />
                                Unarchive
                              </Dropdown.Item>
                            )}
                            {inventoryActions?.remove && (
                              <Dropdown.Item
                                className="purple"
                                onClick={() => {
                                  setModalDeleteShow(true);
                                  setId(row["id"]);
                                }}
                              >
                                <img
                                  src={removeIcon}
                                  alt="removeIcon"
                                  className="me-2"
                                  style={{
                                    pointerEvents: "none",
                                  }}
                                />{" "}
                                Delete
                              </Dropdown.Item>
                            )}
                            {inventoryActions?.batches && (
                              <Dropdown.Item
                                className="purple"
                                onClick={() => {
                                  inventoryActions?.batches(row["id"], row);
                                }}
                              >
                                <img
                                  src={batchIcon}
                                  alt="batchIcon"
                                  className="me-2"
                                  style={{
                                    pointerEvents: "none",
                                  }}
                                />{" "}
                                batches
                              </Dropdown.Item>
                            )}
                            {inventoryActions?.barcode && (
                              <Dropdown.Item
                                className="purple"
                                onClick={() => {
                                  inventoryActions?.barcode(row);
                                }}
                              >
                                <img
                                  src={barcodeIcon}
                                  alt="barcode Icon"
                                  className="me-2"
                                  style={{
                                    pointerEvents: "none",
                                  }}
                                />{" "}
                                Barcode
                              </Dropdown.Item>
                            )}
                          </Dropdown.Menu>
                        </Dropdown>
                      )}
                      <Dropdown className="d-flex justify-content-center d-lg-none">
                        <Dropdown.Toggle variant="light" className={` border-0 p-0 toggle`} id="dropdown-basic" style={{ background: "transparent" }}>
                          <img src={ellipsis} alt="ellipsis" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className=" position-fixed rounded-0 border-0 shadow">
                          {inventoryActions?.edit && (
                            <Dropdown.Item className="purple" onClick={() => inventoryActions?.edit(row["id"], row["is_miscellaneous"])}>
                              <img
                                src={editpencil}
                                alt="edit"
                                className="me-2"
                                style={{
                                  pointerEvents: "none",
                                }}
                              />{" "}
                              Edit
                            </Dropdown.Item>
                          )}
                          {inventoryActions?.duplicate && (
                            <Dropdown.Item className="purple" onClick={() => inventoryActions?.duplicate(row["id"], row["is_miscellaneous"])}>
                              <img
                                src={duplicateIcon}
                                alt="save"
                                className="me-2"
                                style={{
                                  pointerEvents: "none",
                                }}
                              />{" "}
                              Duplicate
                            </Dropdown.Item>
                          )}
                          {inventoryActions?.archive && (
                            <Dropdown.Item
                              className="purple"
                              onClick={() => {
                                setModalArchiveShow(true);
                                setId(row["id"]);
                              }}
                            >
                              <img
                                src={archiveIcon}
                                alt="archive"
                                className="me-2"
                                style={{
                                  pointerEvents: "none",
                                }}
                              />
                              Archive
                            </Dropdown.Item>
                          )}
                          {inventoryActions?.unarchive && (
                            <Dropdown.Item
                              className="purple"
                              onClick={() => {
                                setModalUnArchiveShow(true);
                                setId(row["id"]);
                              }}
                            >
                              <img
                                src={unarchiveIcon}
                                alt="unarchive"
                                className="me-2"
                                style={{
                                  pointerEvents: "none",
                                }}
                              />
                              Unarchive
                            </Dropdown.Item>
                          )}
                          {inventoryActions?.remove && (
                            <Dropdown.Item
                              className="purple"
                              onClick={() => {
                                setModalDeleteShow(true);
                                setId(row["id"]);
                              }}
                            >
                              <img
                                src={removeIcon}
                                alt="removeIcon"
                                className="me-2"
                                style={{
                                  pointerEvents: "none",
                                }}
                              />{" "}
                              Delete
                            </Dropdown.Item>
                          )}
                          {inventoryActions?.batches && (
                            <Dropdown.Item
                              className="purple"
                              onClick={() => {
                                inventoryActions?.batches(row["id"], row);
                              }}
                            >
                              <img
                                src={batchIcon}
                                alt="batchIcon"
                                className="me-2"
                                style={{
                                  pointerEvents: "none",
                                }}
                              />{" "}
                              batches
                            </Dropdown.Item>
                          )}
                          {inventoryActions?.barcode && (
                            <Dropdown.Item
                              className="purple"
                              onClick={() => {
                                inventoryActions?.barcode(row);
                              }}
                            >
                              <img
                                src={barcodeIcon}
                                alt="barcode Icon"
                                className="me-2"
                                style={{
                                  pointerEvents: "none",
                                }}
                              />{" "}
                              Barcode
                            </Dropdown.Item>
                          )}
                          {actionButton1 && actionButton1?.isShow(actionButton1Var) && (
                            <Dropdown.Item
                              className="purple"
                              onClick={() => {
                                actionButton1.onClick(row["id"], row);
                              }}
                            >
                              {typeof actionButton1.label === "function" ? actionButton1.label(row) : actionButton1.label}
                            </Dropdown.Item>
                          )}
                          {actionButton && actionButton?.isShow(actionButtonVar, row) && (
                            <Dropdown.Item
                              className="purple"
                              onClick={() => {
                                actionButton.onClick(row["id"], row);
                              }}
                            >
                              {typeof actionButton.label === "function" ? actionButton.label(row) : actionButton.label}
                            </Dropdown.Item>
                          )}
                          {refund && refund?.isShow(actionButtonVar, row) && (
                            <Dropdown.Item className="purple" onClick={() => refund.onClick(row["id"])}>
                              Refund
                            </Dropdown.Item>
                          )}
                          {duplicate && (
                            <Dropdown.Item className="purple" onClick={() => duplicate(row["id"], row["is_miscellaneous"])}>
                              Duplicate
                            </Dropdown.Item>
                          )}
                          {dummy && (
                            <Dropdown.Item className="purple" onClick={() => dummy(row)}>
                              Dummy
                            </Dropdown.Item>
                          )}
                          {(typeof edit === "object" ? edit?.isShow(row) : edit) && (
                            <Dropdown.Item
                              className="purple"
                              onClick={() => {
                                typeof edit === "object" ? edit?.action(row["id"]) : edit(row["id"], row);
                              }}
                            >
                              Edit
                            </Dropdown.Item>
                          )}

                          {settings && (
                            <Dropdown.Item className="purple" onClick={() => settings(row["id"])}>
                              Reset Password
                            </Dropdown.Item>
                          )}

                          {viewImei && row["imei"] && (
                            <Dropdown.Item className="purple" onClick={() => viewImei(row)}>
                              ViewImei
                            </Dropdown.Item>
                          )}
                          {unarchive && unarchive.isShow(row) && (
                            <Dropdown.Item
                              className="purple"
                              onClick={() => {
                                setModalUnArchiveShow(true);
                                setId(row["id"]);
                              }}
                            >
                              Unarchive
                            </Dropdown.Item>
                          )}
                          {archive && archive.isShow(row) && (
                            <Dropdown.Item
                              className="purple"
                              onClick={() => {
                                setModalArchiveShow(true);
                                setId(row["id"]);
                              }}
                            >
                              Archive
                            </Dropdown.Item>
                          )}
                          {remove && (
                            <Dropdown.Item
                              className="purple"
                              onClick={() => {
                                setModalDeleteShow(true);
                                setId(row["id"]);
                              }}
                            >
                              Delete
                            </Dropdown.Item>
                          )}
                          {(typeof print === "object" ? print?.isShow(row) : print) && (
                            <Dropdown.Item className="purple" onClick={() => (typeof print === "object" ? print?.action(row["id"]) : print(row["id"]))}>
                              Print
                            </Dropdown.Item>
                          )}
                          {(typeof view === "object" ? view?.isShow(row) : view) && (
                            <Dropdown.Item
                              className="purple"
                              onClick={() => {
                                typeof view === "object" ? view?.action(row["id"]) : view(row["id"]);
                              }}
                            >
                              View
                            </Dropdown.Item>
                          )}
                          {(typeof email === "object" ? email?.isShow(row) : email) && (
                            <Dropdown.Item
                              className={`purple ${row["customer_email"] !== "" ? "" : "opacity"}`}
                              onClick={() => {
                                if (row["customer_email"] !== "") {
                                  typeof email === "object" ? email.action(row["id"]) : email(row["id"]);
                                }
                              }}
                            >
                              Email
                            </Dropdown.Item>
                          )}
                          {payment && (
                            <Dropdown.Item
                              className="purple"
                              onClick={() => {
                                payment(row);
                              }}
                            >
                              Payments
                            </Dropdown.Item>
                          )}
                          {purchaseOrder && (
                            <Dropdown.Item
                              className="purple"
                              onClick={() => {
                                purchaseOrder(row["id"]);
                              }}
                            >
                              Purchase Order
                            </Dropdown.Item>
                          )}

                          {done && row.status === "Pending" && (
                            <Dropdown.Item
                              className="purple"
                              onClick={() => {
                                done(row.id);
                              }}
                            >
                              Done
                            </Dropdown.Item>
                          )}
                          {cancel && row.status === "Pending" && (
                            <Dropdown.Item
                              className="purple"
                              onClick={() => {
                                cancel(row.id);
                              }}
                            >
                              Cancel
                            </Dropdown.Item>
                          )}
                          {hyperlink && (
                            <Dropdown.Item as={Link} to={hyperlink.link(row["id"])} target={hyperlink.target}>
                              {hyperlink.label(row["id"])}
                            </Dropdown.Item>
                          )}
                          {editBatch && row.purchase_order === "No PO" && (
                            <Dropdown.Item className="purple" onClick={() => editBatch(row["id"], row)}>
                              Edit
                            </Dropdown.Item>
                          )}

                          {deleteBatch && row.purchase_order === "No PO" && (
                            <Dropdown.Item className="purple" onClick={() => deleteBatch(row, row["batch_id"])}>
                              Delete
                            </Dropdown.Item>
                          )}
                          {deposit && (
                            <Dropdown.Item className="purple" onClick={() => deposit(row["id"])}>
                              Deposit
                            </Dropdown.Item>
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  )}
                </tr>
                {caret && caretState.id === row.id && caretState.caret && (
                  <>
                    {caretTitle ? (
                      caretData && Object.values(caretData).length > 0 ? (
                        <>
                          <tr className="border-bottom colored-row">
                            <td></td>
                            <td className="fs-5 fw-3 text-capitalize" colSpan="100%">
                              {caretTitle}
                            </td>
                          </tr>
                          {Object.values(caretData).length > 0 && (
                            <>
                              <tr className="border-0 colored-row">
                                <td className="border-2 border-bottom border-end-0"></td>
                                <td className="border-end- border-bottom border-2 py-0" colSpan="100%">
                                  <Row>
                                    {Object.entries(caretData).map(([k, v], i) => (
                                      <Col lg="3" md="6" sm="6" xs="6" className="py-2 border-end border-2" style={{ whiteSpace: "normal" }} key={i}>
                                        {transform(k)}: {v === "" || v === "nil" ? "-" : v?.toLocaleString("en-IN")}
                                      </Col>
                                    ))}
                                  </Row>
                                </td>
                              </tr>
                            </>
                          )}
                        </>
                      ) : (
                        <tr className="border-bottom colored-row">
                          <td className="fs-6 fw-3 text-center" colSpan="100%">
                            <img src={LodingGif} width={25} />
                          </td>
                        </tr>
                      )
                    ) : caretData && Object.values(caretData).length > 0 ? (
                      <>
                        <tr className="border-0 colored-row">
                          <td className="border-start border-top py-0" colSpan="100%">
                            <Row className="w-100">
                              {Object.entries(caretData).map(([k, v], i) => (
                                <Col lg="4" md="6" sm="6" xs="6" className="py-2 border-end border-bottom" style={{ whiteSpace: "normal" }} key={i}>
                                  {transform(k)}: {v === "" || v === "nil" ? "-" : v?.toLocaleString("en-IN")}
                                </Col>
                              ))}
                            </Row>
                          </td>
                        </tr>
                      </>
                    ) : (
                      <tr className="border-bottom colored-row">
                        <td className="fs-6 fw-3 text-center" colSpan="100%">
                          <img src={LodingGif} width={25} />
                        </td>
                      </tr>
                    )}
                  </>
                )}
              </>
            ))}
            {total && (
              <>
                <tr className={`${styles.total_td} total_td border-top border-4`}>
                  <td className="total_td"></td>
                  <td className="total_td fw-bold">Totals:{total?.total} </td>
                  {/* {Object.entries(total).map(([k, v], i) => (
                    <td className="total_td" key={i}>
                      {v?.toLocaleString("en-IN")}
                    </td>
                  ))} */}
                  <td className="total_td"> {total?.due_amount}</td>
                </tr>
              </>
            )}
            {totalReports && (
              <>
                <tr
                  className={`${styles.total_td} total_td border-top border-1`}
                  style={{
                    borderBottom: "1px solid #DEDFE4",
                  }}
                >
                  <td className="total_td fw-bold ">Total </td>
                  {Object.entries(totalReports).map(([k, v], i) => (
                    <td className="total_td fw-bold" key={i}>
                      {v?.toLocaleString("en-IN").split("T")[0]}
                    </td>
                  ))}
                </tr>
              </>
            )}
          </tbody>
        </Table>
        {/* </div> */}
      </div>

      <Modal show={modelDeleteShow} onHide={cancelConfirm} animation={true} centered>
        <Modal.Header>
          <Modal.Title>Delete Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center pb-4">
            <img src={triangle} alt="triangle" />
            <img src={dangerline} alt="dangerline" style={{ position: "absolute", right: "auto", top: "2.2rem" }} />
          </div>
          <div>
            <span className="d-flex justify-content-center pb-4">Do you really want to delete?</span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex gap-3">
            <span
              className="w-100 d-flex justify-content-center align-items-center text-secondary p-2 border-2 border-secondary"
              style={{
                cursor: "pointer",
                border: "2px solid rgba(151, 156, 162, 1)",
                borderRadius: "6px",
              }}
              onClick={cancelConfirm}
            >
              Cancel
            </span>
            <Button className="w-100" onClick={() => deleteConfirm(id)}>
              Delete
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* Archive Model */}

      <Modal show={modelArchiveShow} onHide={cancelConfirm} animation={true} centered>
        <Modal.Header>
          <Modal.Title>Archive</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center pb-4">
            <img src={triangle} alt="triangle" />
            <img src={dangerline} alt="dangerline" style={{ position: "absolute", right: "auto", top: "2.2rem" }} />
          </div>
          <div>
            <span className="d-flex justify-content-center pb-4">Do you really want to Archive?</span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex gap-3">
            <span
              className="w-100 d-flex justify-content-center align-items-center text-secondary p-2 border-2 border-secondary"
              style={{
                cursor: "pointer",
                border: "2px solid rgba(151, 156, 162, 1)",
                borderRadius: "6px",
              }}
              onClick={cancelConfirm}
            >
              Cancel
            </span>
            {/* <Button className="w-100 rounded-3" onClick={cancelConfirm}>
              Cancel
            </Button> */}
            <Button className="w-100 rounded-3" onClick={() => archiveConfirm(id)}>
              Archive
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* UnArchive Model */}

      <Modal show={modelUnArchiveShow} onHide={cancelConfirm} animation={true} centered>
        <Modal.Header>
          <Modal.Title>Unarchive</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center pb-4">
            <img src={triangle} alt="triangle" />
            <img src={dangerline} alt="dangerline" style={{ position: "absolute", right: "auto", top: "2.2rem" }} />
          </div>
          <div>
            <span className="d-flex justify-content-center pb-4">Do you really want to Unarchive?</span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex gap-3">
            {/* <Button className="w-100 rounded-3" onClick={cancelConfirm}>
              Cancel
            </Button> */}
            <span
              className="w-100 d-flex justify-content-center align-items-center text-secondary p-2 border-2 border-secondary"
              style={{
                cursor: "pointer",
                border: "2px solid rgba(151, 156, 162, 1)",
                borderRadius: "6px",
              }}
              onClick={cancelConfirm}
            >
              Cancel
            </span>
            <Button className="w-100 rounded-3" onClick={() => unarchiveConfirm(id)}>
              Unarchive
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
        size="md"
        backdrop="static"
        centered
      >
        <Modal.Header>
          <Modal.Title>Feature Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center pb-4">
            <img src={triangle} alt="triangle" />
            <img src={dangerline} alt="dangerline" style={{ position: "absolute", right: "auto", top: "2.2rem" }} />
          </div>
          <div>
            <span className="d-flex justify-content-center pb-4">Do you really want to Feature?</span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex gap-3">
            <span
              className="w-100 d-flex justify-content-center align-items-center text-secondary p-2 border-2 border-secondary"
              style={{
                cursor: "pointer",
                border: "2px solid rgba(151, 156, 162, 1)",
                borderRadius: "6px",
              }}
              onClick={cancelConfirm}
            >
              No
            </span>

            <Button
              className="w-100 rounded-3"
              onClick={() => {
                featuredConfirm(id);
              }}
            >
              Yes
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CustomizeTableview;
