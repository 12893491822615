import { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import SearchInput, { SearchMobleInput } from "../../Components/SearchInput";
import PaginationBar from "../../Components/PaginationBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import PopUpModal from "../../Components/Modal";
import filterImg from "../../assets/svg/filter.svg";
import axios from "axios";
import NoResult from "../../Components/NoResult";
import EditCustomer from "./EditCustomer";
import CustomizeTableview from "../../Components/TableViews/CustomizeTableview";
import DatePicker from "../../Components/DatePicker/DatePicker";
import moment from "moment";
import SearchableSelect from "../../Components/SearchableSelect";
import AddCustomer from "./AddCustomer";
import { useSelector } from "react-redux";
import customloader from "../../assets/images/RollingLoader.gif";
import Csv from "../../Components/csv/Csv";
import MobileSearchAcoordian from "../../Components/MobileSearchAcoordian";
import { useNavigate, useSearchParams } from "react-router-dom";
import { requestDateTime, TIMEOUT } from "../../Features/dateTime";
import CustomerSales from "./CustomerSales";
import LoaderPage from "../../Components/loaders/loaderPage";

const Customer = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { user } = useSelector((state) => state.auth);
  const shopId = user.shop_id;
  const shopName = user.shop_name;
  const [modalAdd, setAddModal] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalSales, setModalSales] = useState(false);
  // customer
  const [customer, setCustomer] = useState([]);
  const [customerId, setCustomerId] = useState();
  const [totalCustomers, setTotalCustomers] = useState();

  const [toggle, refresh] = useState(false);
  const [orderBy, setOrderBy] = useState({ created_at: "DESC" });

  const [noOfPages, setNoOfPages] = useState(1);
  const [sizeOfPages, setSizeOfPages] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchInput, setSearchInput] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const [fromDate, setFromDate] = useState(moment().subtract(29, "days").startOf("day")._d);
  const [toDate, setToDate] = useState(moment().endOf("day")._d);

  const handlePageSize = (e) => {
    setSizeOfPages(e.value);
  };

  // Start Customer with pagination
  const changePage = (page) => {
    setCurrentPage(page);
    navigate("/customer?page=" + page);
  };

  //searchParams
  useEffect(() => {
    if (searchParams.get("page")) {
      setCurrentPage(Number(searchParams.get("page")));
    }
  }, [currentPage, toggle, searchParams]);

  function getCustomers() {
    axios
      .post(
        process.env.REACT_APP_API_URL + `/customer/get/${shopId}?page=` + currentPage + "&size=" + sizeOfPages,
        {
          search: searchInput,
          dates: {
            from: requestDateTime(fromDate).toISOString().replace("T", " ").replace("Z", "").split(".")[0],
            to: requestDateTime(toDate).toISOString().replace("T", " ").replace("Z", "").split(".")[0],
          },
          orderBy: orderBy,
        },
        { withCredentials: true }
      )
      .then((data) => {
        const res = data?.data;
        setCustomer(res?.data);
        setTotalCustomers(res?.count?.no_of_items);

        if (res?.count?.no_of_pages === 0) {
          if (searchParams.get("page")) {
            setNoOfPages(0);
            navigate("/customer?page=0");
          }
        } else {
          setNoOfPages(res?.count?.no_of_pages);
          if (currentPage > res?.count?.no_of_pages) {
            setCurrentPage(res?.count?.no_of_pages);
            navigate("/customer?page=" + res?.count?.no_of_pages);
          }
          if (searchParams.get("page") === "0") {
            setCurrentPage(1);
            navigate("/customer?page=1");
          }
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        const tempErr = err.response.data.error;
        if (tempErr.status === 404 && tempErr.message === "No Record Found") {
          setCustomer([]);
        }
      });
  }

  useEffect(() => {
    let timeOut;
    if (searchInput?.length > 0) {
      timeOut = setTimeout(() => {
        setIsLoading(true);
        getCustomers();
      }, TIMEOUT);
    } else {
      setIsLoading(true);
      getCustomers();
    }
    return () => {
      clearTimeout(timeOut);
    };
  }, [currentPage, searchInput, noOfPages, sizeOfPages, shopId, toggle, orderBy]);

  useEffect(() => {
    if (currentPage === 1) {
      refresh((prev) => !prev);
    } else {
      changePage(1);
    }
  }, [fromDate, toDate, orderBy]);

  function requestAllHistory() {
    return axios.post(
      process.env.REACT_APP_API_URL + `/customer/get/${shopId}`,
      {
        search: searchInput,
        dates: {
          from: requestDateTime(fromDate).toISOString().replace("T", " ").replace("Z", "").split(".")[0],
          to: requestDateTime(toDate).toISOString().replace("T", " ").replace("Z", "").split(".")[0],
        },
        orderBy: orderBy,
      },
      { withCredentials: true }
    );
  }

  const resetFilterHandle = () => {
    setFromDate(moment().subtract(29, "days").startOf("day")._d);
    setToDate(moment().endOf("day")._d);
  };

  const closeModalAdd = () => {
    setAddModal(false);
    refresh((prev) => !prev);
  };

  const [customerSalesId, setCustomerSalesId] = useState(-1);
  // View Customer
  const view = async (id) => {
    setModalSales(true);
    setCustomerSalesId(id);
  };
  const closeModalSales = () => {
    setModalSales(false);
    setCustomerId(-1);
    setCustomerSalesId(-1);
    refresh((prev) => !prev);
  };
  // Edit Customer
  const edit = (id) => {
    setModalEdit(true);
    setCustomerId(id);
  };
  const closeModalEdit = () => {
    setModalEdit(false);
    setCustomerId(-1);
    refresh((prev) => !prev);
  };
  // Delete Customer
  const remove = (id) => {
    axios
      .delete(process.env.REACT_APP_API_URL + "/customer/delete/" + id, {
        withCredentials: true,
      })
      .then(() => {
        refresh((prev) => !prev);
      })
      .catch((err) => {
        throw new Error(err);
      });
  };

  const [toogleMobileSearch, setToogleMobileSearch] = useState(false);
  const searchResult = () => {
    setToogleMobileSearch(false);
    setToogleMobileSearch(false);
  };
  const handleAcoordianCLose = () => {
    setToogleMobileSearch(false);
    setSearchInput("");
    // setExpenseTypeFilter({ label: "all" });
  };
  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleAcoordianOpen = (value) => {
    setToogleMobileSearch(value);
  };
  const filter = () => {
    setToogleMobileSearch(false);
  };
  const SearchData = [];
  const InvoicePayments = async (id) => {
    window.open(`/invoice-payments-sales/` + id, "_blank", "noopener,noreferrer");
  };

  return (
    <>
      {toogleMobileSearch === "select" ? (
        <MobileSearchAcoordian SearchData={SearchData} toggleSearchclose={handleAcoordianCLose} dateName={"Select Date"} fromDate={fromDate} toDate={toDate} setFromDate={setFromDate} setToDate={setToDate} filter={filter} />
      ) : (
        <Container fluid>
          {toogleMobileSearch === "search" ? (
            <div className="d-flex  mb-2 justify-content-center align-items-center">
              <div className="">
                {" "}
                <SearchMobleInput searchlogo value={searchInput} handleChange={handleSearchChange} />
              </div>
              <span className="ms-4" onClick={handleAcoordianCLose}>
                X
              </span>
            </div>
          ) : (
            <Row className="d-flex align-items-center">
              <Col xs="2" lg="3" className="d-none d-lg-inline">
                <SearchInput searchlogo handleChange={handleSearchChange} value={searchInput} />
              </Col>
              <Col xs lg="2" xl="3" className="d-none d-lg-inline"></Col>
              <Col xs lg="2" className="d-none d-lg-inline">
                <DatePicker important={true} fromDate={fromDate} toDate={toDate} setFromDate={setFromDate} setToDate={setToDate} />
              </Col>

              <Col xs="2" lg="3" onClick={() => handleAcoordianOpen("search")} className="d-lg-none d-inline">
                <SearchInput searchlogo bordered />
              </Col>
              <Col className="d-lg-none d-flex" xs="2" lg="2">
                <Button variant="outline-primary" onClick={() => handleAcoordianOpen("select")}>
                  <img src={filterImg} alt="filter" />
                  <span className=" ms-2 d-none d-sm-inline">Filter</span>{" "}
                </Button>
              </Col>
              <Col className="d-none d-sm-inline d-lg-none d-md-inline" sm={2}></Col>
              <Col xs="6" lg="3" xl="2" sm="3">
                <SearchableSelect
                  placeholder={`Showing ${sizeOfPages}`}
                  defaultValue={`Showing ${sizeOfPages}`}
                  value={`Showing ${sizeOfPages}`}
                  searchable={false}
                  options={[
                    { value: "10", label: "10" },
                    { value: "20", label: "20" },
                    { value: "30", label: "30" },
                  ]}
                  handleChange={handlePageSize}
                />
              </Col>
              <Col xs="2" sm="3" lg="2" xl="2" xxl="2" className="p-0">
                <Button className="h-100 w-100" style={{ fontSize: "0.75rem" }} size="sm" type="submit" onClick={() => setAddModal(true)}>
                  <span className="d-none d-sm-inline d-lg-inline">Add Customer</span> <FontAwesomeIcon icon={faPlus} />
                </Button>
              </Col>
            </Row>
          )}
          <div className="pt-2  d-lg-inline">
            <Button size="sm" type="submit" style={{ fontSize: "14px", marginTop: "7px" }} onClick={resetFilterHandle}>
              <span className=" d-lg-inline">Reset Filters</span>{" "}
            </Button>
          </div>
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
              <LoaderPage />
            </div>
          ) : (
            <>
              {customer?.length === 0 ? (
                <NoResult onClick={() => setAddModal(true)} name="Customer" />
              ) : (
                <>
                  <CustomizeTableview
                    className={"mt-5"}
                    data={customer}
                    edit={edit}
                    remove={remove}
                    view={view}
                    columnNotShow={["id"]}
                    dateColumn={["created_at", "updated_at"]}
                    sortColumn={["customer_name", "created_at", "updated_at"]}
                    orderBy={orderBy}
                    setOrderBy={setOrderBy}
                    actions={user.permissionArray?.includes("Customer")}
                  />
                  <Row className="mt-5 align-items-center">
                    <Col className="d-none d-md-block" md="3" lg="3">
                      <span className="text-secondary fs-6">{totalCustomers} results</span>
                    </Col>
                    <Col xs="12" md="6" lg="6">
                      <PaginationBar currentPage={currentPage} noOfPages={noOfPages} changePage={changePage} />
                    </Col>
                    <Col className="d-md-none d-flex align-items-center mt-3" xs="6" lg="3">
                      <span className="text-secondary fs-6">{totalCustomers} results</span>
                    </Col>
                    <Col xs="6" md="3" lg="3" className="text-end ">
                      <Csv
                        filename={`${
                          "Customers " +
                          shopName +
                          " " +
                          new Date().toLocaleDateString("en-US", {
                            timeZone: "Asia/Karachi",
                          })
                        }`}
                        api={requestAllHistory}
                        format={(data) => {
                          const { id, created_at, customer_number, ...temp } = data;
                          const newObj = {
                            ...temp,
                            customer_number: customer_number.replaceAll("+", "").replaceAll("-", " "),
                            created_at: created_at.split("T")[0],
                          };
                          return newObj;
                        }}
                        error={(err) => {
                          if (err.response) {
                            const tempErr = err.response.data.error;
                            if (tempErr.status === 404 && tempErr.message === "No Record Found") {
                              return true;
                            } else {
                              return false;
                            }
                          } else {
                            return false;
                          }
                        }}
                        responseObject={(data) => {
                          return data?.data?.data;
                        }}
                      />
                    </Col>
                  </Row>
                </>
              )}
            </>
          )}
        </Container>
      )}

      <PopUpModal title="Add New Customer" show={modalAdd} onHide={closeModalAdd}>
        <AddCustomer close={closeModalAdd} />
      </PopUpModal>

      <PopUpModal title="Edit Customer" show={modalEdit} onHide={closeModalEdit}>
        <EditCustomer id={customerId} close={closeModalEdit} />
      </PopUpModal>

      <CustomerSales id={customerSalesId} show={modalSales} close={closeModalSales} />
    </>
  );
};
export default Customer;
