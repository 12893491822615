import React from "react";
import { Card, Col, Row, Form, Table } from "react-bootstrap";
import style from "../../styles/Reports.module.css";
import insightsup from "../../assets/svg/sales.svg";
import dollarliability from "../../assets/svg/newliability.svg";
import dollarexpenses from "../../assets/svg/newExpense.svg";
import customers from "../../assets/svg/customers.svg";
import VerticalBarChart from "../Charts/VerticalBarChart";
import { useState, useEffect, useRef } from "react";
import { CSVLink } from "react-csv";
import moment from "moment";
import { useSelector } from "react-redux";
import axios from "axios";
import SearchableSelect from "../SearchableSelect";
import { requestDateTime } from "../../Features/dateTime";
import ReactSelect from "react-select";
import DatePicker from "../DatePicker/DatePicker";
import LineChart from "../Charts/LineChart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import MobilrReportsFilter from "./MobileReportsFilter";

const GeneralReport = ({
  fromDate = moment().subtract(29, "days").startOf("day")._d,
  toDate = moment().endOf("day")._d,
  setToDate,
  setFromDate,
  close,
  show,
  MainactiveTab,
}) => {
  const [data, setData] = useState();
  const [liability, setLiability] = useState();
  const [products, setProducts] = useState([]);
  const [overView, setOverView] = useState(true);
  const [totalSales, setTotalSales] = useState([]);

  const [reportType, setReportType] = useState({
    value: 1,
    label: "Total Sales",
  });
  const [averageSales, setAverageSales] = useState({
    labels: [],
    datasets: [
      {
        label: "Average Sales",
        data: [
          65, 59, 80, 81, 56, 65, 59, 80, 81, 56, 65, 59, 80, 81, 56, 65, 59,
          80, 81, 56, 65, 59, 80, 81, 56, 65, 59, 80, 81, 56,
        ],
        backgroundColor: "rgba(89, 50, 234, 0.5)",
        borderColor: "rgb(75, 192, 192)",
      },
    ],
  });

  const { user } = useSelector((state) => state.auth);
  const shop_id = user.shop_id;
  const userId = user.user_id;
  const shopName = user.shop_name;

  // Get Counts
  useEffect(() => {
    let dates = {
      from: requestDateTime(fromDate)
        .toISOString()
        .replace("T", " ")
        .replace("Z", "")
        .split(".")[0],
      to: requestDateTime(toDate)
        .toISOString()
        .replace("T", " ")
        .replace("Z", "")
        .split(".")[0],
    };
    axios
      .post(
        process.env.REACT_APP_API_URL + `/shops/get-counts/${userId}`,
        { shop_id, dates },
        { withCredentials: true },
      )
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        throw new Error(err.response.data);
      });
  }, [shop_id, userId, fromDate, toDate]);

  // Get Counts
  useEffect(() => {
    let dates = {
      from: requestDateTime(fromDate)
        .toISOString()
        .replace("T", " ")
        .replace("Z", "")
        .split(".")[0],
      to: requestDateTime(toDate)
        .toISOString()
        .replace("T", " ")
        .replace("Z", "")
        .split(".")[0],
    };
    axios
      .post(
        process.env.REACT_APP_API_URL + `/shops/get-counts-liability/${userId}`,
        { dates },
        { withCredentials: true },
      )
      .then((res) => {
        setLiability(res.data[0]?.liability);
      })
      .catch((err) => {
        throw new Error(err.response.data);
      });
  }, [userId, fromDate, toDate]);

  // Get best selling products
  useEffect(() => {
    let dates = {
      from: requestDateTime(fromDate)
        .toISOString()
        .replace("T", " ")
        .replace("Z", "")
        .split(".")[0],
      to: requestDateTime(toDate)
        .toISOString()
        .replace("T", " ")
        .replace("Z", "")
        .split(".")[0],
    };
    axios
      .post(
        process.env.REACT_APP_API_URL + `/sales/best-selling-product/${userId}`,
        { dates, reportType },
        { withCredentials: true },
      )
      .then((res) => {
        setProducts(res.data);
      })
      .catch((err) => {
        throw new Error(err.response.data);
      });
  }, [userId, fromDate, toDate, reportType]);

  const dateObj = new Date();
  const year = dateObj.getUTCFullYear();
  // Average sales

  var months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  var monthName = months[dateObj.getMonth()];
  const [currentMonth, setCurrentMonth] = useState(monthName);
  const [month, setMonth] = useState([monthName]);
  useEffect(() => {
    axios
      .post(
        process.env.REACT_APP_API_URL +
          `/sales/average-sales-reports/${userId}`,
        {
          shop_id: shop_id,
          month: currentMonth,
        },
        { withCredentials: true },
      )
      .then((res) => {
        const label = res.data.map((data) => data.label);
        const salesData = res.data.map((data) => data.total_sales);
        setAverageSales({
          labels: label,
          datasets: [
            {
              label: "Average Sales",
              data: salesData,
              backgroundColor: "rgba(89, 50, 234, 0.5)",
              borderColor: "rgba(225, 102, 255, 1)",
            },
          ],
        });
      })
      .catch((err) => {
        throw new Error(err.response.data);
      });
  }, [userId, shop_id, currentMonth]);

  // Sale report for year
  useEffect(() => {
    axios
      .post(
        process.env.REACT_APP_API_URL + `/reports/history/${userId}`,
        { shop_id, month },
        { withCredentials: true },
      )
      .then((res) => {
        setTotalSales(res.data);
      })
      .catch((err) => {
        throw new Error(err.response.data);
      });
  }, [userId, shop_id, month]);

  const transform = (text) => {
    const temp = text.split("_");
    let ret = "";
    temp.forEach((txt) => {
      ret += " " + txt.charAt(0).toUpperCase() + txt.slice(1);
    });
    return ret;
  };
  const convertDate = (date) => {
    const inputDate = new Date(date);

    // Format the date as "jul 11 2023"
    // const months = [
    //   "jan",
    //   "feb",
    //   "mar",
    //   "apr",
    //   "may",
    //   "jun",
    //   "jul",
    //   "aug",
    //   "sep",
    //   "oct",
    //   "nov",
    //   "dec",
    // ];
    const formattedDate =
      months[inputDate.getMonth()] +
      " " +
      inputDate.getDate() +
      " " +
      inputDate.getFullYear();
    return formattedDate;
  };
  return (
    <>
      <Row className="py-2">
        <Row className="w-100 d-none d-lg-flex d-md-flex d-sm-flex row py-3 px-2 bg-white mb-3">
          <Col lg={2}>
            <DatePicker
              fromDate={fromDate}
              toDate={toDate}
              setFromDate={setFromDate}
              setToDate={setToDate}
            />
          </Col>
        </Row>
        <p className="fw-bold fs-16 mb-4 ms-2">
          {`${convertDate(fromDate)}` === `${convertDate(toDate)}`
            ? convertDate(toDate)
            : `${convertDate(fromDate)} - ${convertDate(toDate)}`}{" "}
        </p>{" "}
        <Col xs={12} className="d-flex d-lg-flex d-md-flex d-sm-flex ">
          <div className="w-100 d-block d-lg-none d-md-none d-sm-none">
            <p
              className="p-3 mb-0 border align-items-center gap-2 d-flex rounded-top bg-white w-100"
              onClick={() => setOverView((prev) => !prev)}
            >
              {overView ? (
                <FontAwesomeIcon icon={faChevronDown} />
              ) : (
                <FontAwesomeIcon icon={faChevronUp} />
              )}{" "}
              Over view{" "}
            </p>
            <Card
              className={` border-1 d-flex py-2 ${overView ? "" : "d-none"}  `}
            >
              <div className="d-flex ms-2 align-items-center ">
                <img
                  style={{
                    pointerEvents: "none",
                  }}
                  className="mx-3"
                  src={insightsup}
                  height={"24.54px"}
                  alt="card logo"
                />
                <div className="ms-2">
                  <Card.Text className={` d-grid  `}>
                    <span>Total Sales</span>
                    <span className={` fs-20 fw-bold  `}>
                      Rs {data?.sales[0].sales > 0 ? data?.sales[0].sales : 0}
                      <span className="fs-13 text-muted">
                        {" "}
                        Exact{" "}
                        {data?.sales[0].sales > 0
                          ? data?.sales[0].sales
                          : 0}{" "}
                      </span>
                    </span>
                  </Card.Text>
                </div>
              </div>
            </Card>
            <Card
              className={` border-1 d-flex py-2  ${overView ? "" : "d-none"} `}
            >
              <div className="d-flex ms-2 align-items-center ">
                <img
                  style={{
                    pointerEvents: "none",
                  }}
                  className="mx-3"
                  src={customers}
                  height={"24.54px"}
                  alt="card logo"
                />
                <div className="ms-2">
                  <Card.Text className={` d-grid  `}>
                    <span>Total Customers</span>
                    <span className={` fs-20 fw-bold  `}>
                      Rs{" "}
                      {data?.customer[0].customers > 0
                        ? data?.customer[0].customers
                        : 0}
                      <span className="fs-13 text-muted">
                        {" "}
                        Exact{" "}
                        {data?.customer[0].customers > 0
                          ? data?.customer[0].customers
                          : 0}{" "}
                      </span>
                    </span>
                  </Card.Text>
                </div>
              </div>
            </Card>
            <Card
              className={` border-1 d-flex py-2  ${overView ? "" : "d-none"} `}
            >
              <div className="d-flex ms-2 align-items-center ">
                <img
                  style={{
                    pointerEvents: "none",
                  }}
                  className="mx-3"
                  src={dollarexpenses}
                  height={"24.54px"}
                  alt="card logo"
                />
                <div className="ms-2">
                  <Card.Text className={` d-grid  `}>
                    <span>Total Expense</span>
                    <span className={` fs-20 fw-bold  `}>
                      Rs{" "}
                      {data?.expenses[0].expenses > 0
                        ? Number(data?.expenses[0].expenses).toLocaleString(
                            "en-IN",
                          )
                        : 0}
                      <span className="fs-13 text-muted">
                        {" "}
                        Exact{" "}
                        {data?.expenses[0].expenses > 0
                          ? Number(data?.expenses[0].expenses).toLocaleString(
                              "en-IN",
                            )
                          : 0}{" "}
                      </span>
                    </span>
                  </Card.Text>
                </div>
              </div>
            </Card>
            <Card
              className={` border-1 d-flex py-2 ${overView ? "" : "d-none"}  `}
            >
              <div className="d-flex ms-2 align-items-center ">
                <img
                  style={{
                    pointerEvents: "none",
                  }}
                  className="mx-3"
                  src={dollarliability}
                  height={"24.54px"}
                  alt="card logo"
                />
                <div className="ms-2">
                  <Card.Text className={` d-grid  `}>
                    <span>Total Liability</span>
                    <span className={` fs-20 fw-bold  `}>
                      Rs{" "}
                      {liability > 0
                        ? Number(liability).toLocaleString("en-IN")
                        : 0}
                      <span className="fs-13 text-muted">
                        {" "}
                        Exact{" "}
                        {liability > 0
                          ? Number(liability).toLocaleString("en-IN")
                          : 0}{" "}
                      </span>
                    </span>
                  </Card.Text>
                </div>
              </div>
            </Card>
          </div>
        </Col>
        <Col
          xs={6}
          lg={3}
          className="mb-2 d-none d-lg-block d-md-block d-sm-block"
        >
          <Card className={`${style.cards} border-0 py-2 reports-card`}>
            <span className="border-bottom text-start px-2">Total Sales</span>
            <div className="py-3 d-flex justify-content-evenly flex-md-row flex-column">
              <img
                style={{
                  pointerEvents: "none",
                }}
                src={insightsup}
                height={"51.54px"}
                alt="card logo"
              />
              <div className="text-md-start text-center">
                <Card.Text className={` ${style.cardstxt} mt-3`}>
                  <span className={`${style.cardstitle}`}>
                    {data?.sales[0].sales > 0 ? data?.sales[0].sales : 0}
                  </span>
                </Card.Text>
              </div>
            </div>
          </Card>
        </Col>
        <Col
          xs={6}
          lg={3}
          className="mb-2 d-none d-lg-block d-md-block d-sm-block"
        >
          <Card className={`${style.cards} border-0 py-2 reports-card`}>
            <span className="border-bottom text-start px-2">
              Total Customers
            </span>

            <div className="py-3 d-flex justify-content-evenly flex-md-row flex-column">
              <img
                style={{
                  pointerEvents: "none",
                }}
                src={customers}
                height={"51.54px"}
                alt="card logo"
              />
              <div className="text-md-start text-center">
                <Card.Text className={` ${style.cardstxt} mt-3`}>
                  <span className={`${style.cardstitle}`}>
                    {data?.customer[0].customers > 0
                      ? data?.customer[0].customers
                      : 0}
                  </span>
                </Card.Text>
              </div>
            </div>
          </Card>
        </Col>
        <Col
          xs={6}
          lg={3}
          className="mb-2 d-none d-lg-block d-md-block d-sm-block"
        >
          <Card className={`${style.cards} border-0 py-2 reports-card`}>
            <span className="border-bottom text-start px-2">
              Total Expenses
            </span>

            <div className="py-3 d-flex justify-content-evenly flex-md-row flex-column">
              <img
                style={{
                  pointerEvents: "none",
                }}
                src={dollarexpenses}
                height={"51.54px"}
                alt="card logo"
              />
              <div className="text-md-start text-center">
                <Card.Text className={` ${style.cardstxt} mt-3`}>
                  <span className={`${style.cardstitle}`}>
                    {data?.expenses[0].expenses > 0
                      ? Number(data?.expenses[0].expenses).toLocaleString(
                          "en-IN",
                        )
                      : 0}
                  </span>
                </Card.Text>
              </div>
            </div>
          </Card>
        </Col>
        <Col
          xs={6}
          lg={3}
          className="mb-2 d-none d-lg-block d-md-block d-sm-block"
        >
          <Card className={`${style.cards} border-0 py-2 reports-card`}>
            <span className="border-bottom text-start px-2">
              Total Liability
            </span>

            <div className="py-3 d-flex justify-content-evenly flex-md-row flex-column">
              <img
                style={{
                  pointerEvents: "none",
                }}
                src={dollarliability}
                height={"51.54px"}
                alt="card logo"
              />
              <div className="text-md-start text-center">
                <Card.Text className={` ${style.cardstxt} mt-3`}>
                  <span className={`${style.cardstitle}`}>
                    {liability > 0
                      ? Number(liability).toLocaleString("en-IN")
                      : 0}
                  </span>
                </Card.Text>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
      <Row className="py-3">
        <Col sm={12} lg={9} className="mb-4">
          <Card className={` p-4 ${style.prsoldcard} border-0`}>
            <div className="d-flex justify-content-md-end justify-content-center">
              <Form.Select
                className={`text-secondary h-100  ${style.chartcarddate}`}
                defaultValue={monthName}
                onChange={(e) => setCurrentMonth(e.target.value)}
              >
                {months.map((data, index) => (
                  <option key={index} value={data}>
                    {data}{" "}
                  </option>
                ))}
              </Form.Select>
            </div>
            <Row className="py-2">
              <span
                className={`text-md-start text-center ${style.soldproducts}`}
              >
                Product Sold per day
              </span>
            </Row>
            <Row>
              <span className={`text-md-start text-center ${style.dateprsold}`}>
                {currentMonth} {year}
              </span>
            </Row>

            <LineChart data={averageSales} />
          </Card>
        </Col>

        <Col sm={12} lg={3} className="mb-4">
          <Card className={`py-4 ${style.overflowcard} border-0 d-block`}>
            <div className="h-100">
              <Row className="px-4 pb-4">
                <Col sm={6} lg={6}>
                  <h5 className={` ${style.dateprsold}`}>
                    10 most selling products
                  </h5>
                </Col>
                <Col sm={6} lg={6}>
                  <SearchableSelect
                    value={reportType}
                    searchable={false}
                    options={[
                      { value: 1, label: "Total Sales" },
                      { value: 2, label: "Sales Revenue" },
                    ]}
                    handleChange={(e) => {
                      setReportType(e);
                    }}
                  />
                </Col>
              </Row>
              <div className="border-bottom border-top">
                <Row className="px-4 py-3">
                  <Col xs={6} lg={6}>
                    <span className={`text-secondary ${style.dateprsold}`}>
                      Product Name
                    </span>
                  </Col>

                  <Col xs={6} lg={6} className="d-flex justify-content-end">
                    <span className={`text-secondary ${style.dateprsold}`}>
                      {reportType.value === 1 ? `Total Sales` : `Sales Revenue`}
                    </span>
                  </Col>
                </Row>
              </div>
              {!products[0] ? (
                <h6 className="text-center pt-5">No Products.</h6>
              ) : (
                <div
                  className={`ps-3 ${style.productOverflow} ${style.scroll}`}
                >
                  {products?.map((product, index) => (
                    <Row className=" justify-content-between pt-2" key={index}>
                      <Col xs={9} lg={9}>
                        <Row>
                          <span className="py-2" style={{ fontWeight: "500" }}>
                            {product.product_name}
                          </span>
                        </Row>
                      </Col>

                      <Col xs={3} lg={3}>
                        <p className="py-2 text-center">
                          {reportType.value === 1
                            ? product?.total_sales
                            : Math.abs(product?.sales_amount) > 999
                            ? Math.sign(product?.sales_amount) *
                                (
                                  Math.abs(product?.sales_amount) / 1000
                                ).toFixed(1) +
                              "K"
                            : Math.sign(product?.sales_amount) *
                              Math.abs(product?.sales_amount)}
                        </p>
                      </Col>
                    </Row>
                  ))}
                </div>
              )}
            </div>
          </Card>
        </Col>
      </Row>
      {/* <Card className="mt-3 mb-3">
        <Row
          className={`flex-md-row flex-column align-items-center ${style.salespadding}`}
        >
          <Col xs="12" lg="7" className="text-md-start text-center">
            <h5>Sales report for each month for this year</h5>
          </Col>
          <Col xs="12" lg="3">
            <div className="d-flex justify-content-md-end justify-content-center">
              <Form.Select
                className={`text-secondary h-100 `}
                defaultValue={monthName}
                onChange={(e) => setMonth(e.target.value)}
              >
                {months.map((data, index) => (
                  <option key={index} value={data}>
                    {data}{" "}
                  </option>
                ))}
              </Form.Select>
            </div>
          </Col>
          <Col xs="12" lg="2" className="text-end mt-2">
            <CSVLink
              className="btn btn-primary"
              filename={`${
                "Sales Report " +
                shopName +
                " For Last 12 Months " +
                new Date().toLocaleDateString("en-US", {
                  timeZone: "Asia/Karachi",
                })
              }.csv`}
              data={totalSales}
            >
              {months.map((data, index) => (
                <option key={index} value={data}>
                  {data}{" "}
                </option>
              ))}
            </Form.Select>
          </div>
        </Col>
        <Col xs="12" lg="2" className="text-end mt-2">
          <CSVLink
            className="btn btn-primary"
            filename={`${
              "Sales Report " +
              shopName +
              " For Last 12 Months " +
              new Date().toLocaleDateString("en-US", {
                timeZone: "Asia/Karachi",
              })
            }.csv`}
            data={totalSales}
          >
            Download as CSV
          </CSVLink>
        </Col>
      </Row>
      {!totalSales[0] ? (
        <div>No Data</div>
      ) : (
        <Table striped hover responsive className="border-top">
          <thead>
            <tr>
              {Object.keys(totalSales[0]).map((s, index) => (
                <th key={index}>{transform(s)}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {totalSales?.map((row, index) => (
              <tr className="border-bottom" key={index}>
                {Object.entries(row).map(([k, v], i) => (
                  <td className="border-bottom-0" key={i}>
                    {v.toLocaleString("en-IN")}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Card> */}
      <MobilrReportsFilter
        activeTAb={MainactiveTab}
        show={show}
        handleClose={close}
        fromDate={fromDate}
        toDate={toDate}
        setFromDate={setFromDate}
        setToDate={setToDate}
      />
    </>
  );
};

export default GeneralReport;
