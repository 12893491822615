import { useState } from "react";
import { Form, Row, Col, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import SearchableSelect from "../../Components/SearchableSelect";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import axios from "axios";
import PhoneInput from "../../Components/PhoneInput/PhoneInput";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import style from "./../../styles/ResetPassword.module.css";

const EditUser = ({ id, close }) => {
  const { user } = useSelector((state) => state.auth);
  const shopId = user.shop_id;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState();
  const [alternativenumber, setAlternativeNumber] = useState();
  const [location, setLocation] = useState({});
  const [locationFields, setLocationFields] = useState([]);
  const [isInventory, setIsInventory] = useState();
  const [isSales, setIsSales] = useState();
  const [isMarketing, setIsMarketing] = useState();
  const [isReports, setIsReports] = useState();
  const [isSettings, setIsSettings] = useState();
  const [isVendor, setIsVendor] = useState();
  const [isCustomer, setIsCustomer] = useState();
  const [isPurchaseOrder, setIsPurchaseOrder] = useState(false);
  const [isTradeIn, setIsTradeIn] = useState(false);
  const [isReservations, setIsReservations] = useState(false);
  const [isMarketplace, setIsMarketplace] = useState(false);
  const [role, setRole] = useState({});
  const [commission, setCommission] = useState("");
  const [reportsCheckBoxes, setReportsCheckBoxes] = useState({
    isGeneralReport: false,
    isDailyCashReport: false,
    isDailyTransactionReport: false,
    isProfitLossReport: false,
    isInventoryReport: false,
    isSalesReport: false,
    isIMEIReport: false,
    isSalesSummary: false,
    isJournal: false,
    isBankTransactionsReport: false,
    isMiscItemsHistoryReport: false,
  });
  const [isExpense, setIsExpense] = useState();
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [validated, setValidated] = useState(false);
  const [permissionsId, setPermissionsId] = useState();
  const [associationId, setAssociationId] = useState();
  const [isClicked, setIsClicked] = useState(false);
  const [reportsCheck, setreportsCheck] = useState(false);
  const [notificationsCheckBoxes, setNotificationsCheckBoxes] = useState({
    has_global: false,
    has_new_reservation: false,
  });
  const [modules, setModules] = useState([]);
  const [p_id, setP_id] = useState();

  const handleRoleChange = (e) => {
    if (e.value) {
      setRole(e);
    } else {
      setRole({});
    }
  };
  const handleCommisionChange = (e) => {
    let value = e.target.value.toString();
    const regex = /^[0-9]+(\.[0-9]{0,2})?$/; // Regular expression to validate numbers or floating points up to 2 decimal places
    if (value.includes(".")) {
      const decimalIndex = value.indexOf(".");
      const decimalPart = value.substring(decimalIndex + 1);
      value = value.substring(0, decimalIndex + 1) + decimalPart.substring(0, 2); // Truncate decimal part to 2 places
    }
    if (regex.test(value) && parseFloat(value) <= 100) {
      setCommission(value);
    } else if (value === "") {
      setCommission("");
    } else {
      setCommission(100);
    }
  };
  const fetchSubModulesData = async (id) => {
    setP_id(id);
    // setSubModulesOptions(modulesOptions[id].subModules);
  };
  const handleCheckboxChange = (e, index) => {
    setP_id(index);
    if (e.target.isChecked) {
      setModules((prev) => {
        return prev.map((item, i) => {
          if (i === index) {
            return { ...item, isChecked: e.target.checked };
          }
          return item;
        });
      });
    } else {
      setModules((prev) => {
        return prev.map((item, i) => {
          if (i === index) {
            item.subModules.map((item, i) => {
              item.isChecked = false;
            });
          }
          if (i === index) {
            return { ...item, isChecked: e.target.checked };
          }
          return item;
        });
      });
    }
  };

  const handleSubmoduleCheckboxChange = (e, index, pr_id) => {
    if (modules[pr_id]?.isChecked ? modules[pr_id]?.isChecked : false) {
      setModules((prevModulesOptions) => {
        return prevModulesOptions.map((module, i) => {
          if (i === pr_id) {
            return {
              ...module,
              subModules: module.subModules.map((item, subIndex) => {
                if (subIndex === index) {
                  return { ...item, isChecked: e.target.checked };
                }
                return item;
              }),
            };
          }
          return module;
        });
      });
    } else {
      alert("Please select Parent Module first");
    }
  };
  useEffect(() => {
    if (id !== -1) {
      axios
        .get(process.env.REACT_APP_API_URL + `/user/get-secondary-user/${id}`, {
          withCredentials: true,
        })
        .then(
          (res) => {
            setModules(res.data[0].modules);
            setName(res.data[0].user_full_name);
            setEmail(res.data[0].user_email);
            setNumber(res.data[0].whatsapp_number);
            setAlternativeNumber(res.data[0].backup_phone_number);
            setLocation({
              value: res.data[0].location_id,
              label: res.data[0].location_nick,
            });
            setRole(res.data[0]?.role ? { value: res.data[0].role, label: res.data[0].role } : {});
            setCommission(res.data[0]?.commission === 0 ? "" : res.data[0]?.commission);
            setIsInventory(Number(res.data[0].Inventory) === 1 ? true : false);
            setIsMarketing(Number(res.data[0].MyMarketing) === 1 ? true : false);
            setIsReports(Number(res.data[0].Reports) === 1 ? true : false);
            setIsSales(Number(res.data[0].Sales) === 1 ? true : false);
            setIsSettings(Number(res.data[0].Settings) === 1 ? true : false);
            setIsVendor(Number(res.data[0].Vendor) === 1 ? true : false);
            setIsExpense(Number(res.data[0].MyExpenses) === 1 ? true : false);
            setIsCustomer(Number(res.data[0].Customer) === 1 ? true : false);
            setIsPurchaseOrder(Number(res.data[0].PurchaseOrder) === 1 ? true : false);
            setIsMarketplace(Number(res.data[0].Marketplace) === 1 ? true : false);
            setIsTradeIn(Number(res.data[0].TradeIn) === 1 ? true : false);
            setAssociationId(res.data[0].association_id);
            setPermissionsId(res.data[0].permission_id);

            setReportsCheckBoxes(res.data[0].SubReports);

            setNotificationsCheckBoxes({
              has_new_reservation: res.data[0].has_new_reservation === 1 ? true : false,
              has_global: res.data[0].has_global === 1 ? true : false,
            });
          },
          { withCredentials: true },
        )
        .catch((err) => {
          throw new Error(err);
        });
    }
  }, [id]);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + `/location/get-location/${shopId}`, {
        withCredentials: true,
      })
      .then((res) => {
        const getData = res.data.map((loc) => ({
          value: loc.location_id,
          label: loc.location_nick,
          isDisabled: new Date().toISOString() >= loc.active_till ? true : false,
        }));
        setLocationFields(getData);
      })
      .catch((err) => {
        throw new Error(err);
      });
  }, [shopId]);

  const extractIds = (data) => {
    const ids = [];
    data.forEach((mainObj) => {
      if (mainObj.isChecked) {
        ids.push(mainObj.id);
      }
      mainObj.subModules.forEach((submodule) => {
        if (submodule.isChecked) {
          ids.push(submodule.id);
        }
      });
    });
    return ids;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }
    try {
      setValidated(true);
      setError("");
      if (isReports && Object.values(reportsCheckBoxes).every((item) => item === false)) {
        setreportsCheck(true);
      }
      if (name !== "" && email !== "" && number.length > 0 && location.value !== "" && role.label !== "" && (isReports && Object.values(reportsCheckBoxes).every((item) => item === false) ? false : true)) {
        const user = {
          user_id: id,
          user_full_name: name,
          user_email: email,
          // password: password,
          whatsapp_number: number,
          backup_phone_number: alternativenumber,
          shop_id: shopId,
          user_type: "secondary",
          role: role?.label,
          commission: commission ? commission : 0,
        };
        const permissions = {
          Inventory: isInventory,
          Sales: isSales,
          Vendor: isVendor,
          PurchaseOrder: isPurchaseOrder,
          Tradein: isTradeIn,
          Customer: true,
          Reports: isReports,
          MyExpenses: isExpense,
          MyMarketing: isMarketing,
          Settings: isSettings,
          Marketplace: isMarketplace,
          TradeIn: isTradeIn,
          permission_id: permissionsId,
          SubReports: isReports ? reportsCheckBoxes : {},
        };

        const locationUser = {
          association_id: associationId,
          location_id: location.value,
        };

        setIsClicked(true);
        const res = await axios.patch(
          process.env.REACT_APP_API_URL + `/user/update-secondary-user`,
          {
            user,
            permissions,
            notificationPermissions: notificationsCheckBoxes,
            location: locationUser,
            modules: extractIds(modules),
          },
          { withCredentials: true },
        );
        if (res.data) {
          setSuccess("User Updated Successfully.");
          setTimeout(() => close(), 1000);
        }
      }
    } catch (error) {
      setIsClicked(true);
      setError(error.response?.data.error.message);
      setIsClicked(false);
    }
  };
  const handleReportsChange = (e, name) => {
    setreportsCheck(false);
    setReportsCheckBoxes({ ...reportsCheckBoxes, [name]: e.target.checked });
  };
  // const handleNotificationsChange = (e, name) => {
  //   setNotificationsCheck(false);
  //   setNotificationsCheckBoxes({
  //     ...notificationsCheckBoxes,
  //     [name]: e.target.checked,
  //   });
  // };
  const handleNotificationsChange = (e, name) => {
    setNotificationsCheckBoxes({
      ...notificationsCheckBoxes,
      [name]: e.target.checked,
    });
  };
  const handleNotifications = (e) => {
    if (e.target.checked) {
      setNotificationsCheckBoxes((prev) => ({
        ...prev,
        has_global: true,
      }));
    } else {
      setNotificationsCheckBoxes({
        has_global: false,
        has_new_reservation: false,
      });
    }
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      {error && <p className="text-danger p-2 text-center my-1 rounded-1">*{error}</p>}
      {success && <p className="text-success p-2 text-center my-1 rounded-1">*{success}</p>}
      <Row>
        <Col lg="6">
          <Form.Group className="mb-3">
            <Form.Label className="mb-1">
              Full Name <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control className="p-2" type="text" name="name" placeholder="Full Name" value={name} onChange={(e) => setName(e.target.value)} required />
            <Form.Control.Feedback type="invalid">* Please Enter User Name</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="mb-1">
              Email <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control className="p-2" type="text" name="email" placeholder="example@mail.com" pattern="[a-z0-9._+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" value={email} onChange={(e) => setEmail(e.target.value)} required />
            <Form.Control.Feedback type="invalid">* Invalid Email</Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg="6">
          <div className="mb-3">
            <SearchableSelect important label={"Location"} placeholder="Select Location" options={locationFields} handleChange={(e) => setLocation(e)} value={location} required />
            <Form.Control hidden type="text" name="email" diabled={true} value={Object.values(location).length > 0 ? location : ""} onChange={() => {}} required />
            <Form.Control.Feedback type="invalid">* Please Select Location</Form.Control.Feedback>
          </div>
          <div className="mb-3">
            <SearchableSelect
              important
              label={"Role"}
              placeholder="Select Role"
              options={[
                { value: "Cashier", label: "Cashier" },
                { value: "sales Person", label: "Sales Person" },
              ]}
              value={Object.values(role).length > 0 ? role : null}
              handleChange={handleRoleChange}
              required
            />
            <Form.Control hidden type="text" name="email" value={Object.values(role).length > 0 ? role : null} onChange={() => {}} required />
            <Form.Control.Feedback type="invalid">* Please Select Role</Form.Control.Feedback>
          </div>
        </Col>
        {role?.label === "Sales Person" && (
          <Form.Group className="mb-3">
            <Form.Label className="mb-1">Commision(%)</Form.Label>
            <Form.Control
              className="p-2"
              type="number"
              name="commission"
              placeholder="Commission"
              value={commission}
              onChange={handleCommisionChange}
              onKeyDown={(e) => {
                if (["e", "E", "+", "-"].includes(e.key)) {
                  e.preventDefault();
                }
              }}
            />
          </Form.Group>
        )}
        <Col lg="6">
          <Form.Group className="mb-3">
            <Form.Label className="mb-1">
              Phone No. <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <PhoneInput className="p-2" placeholder="+92-3XX-XXXXXXX" value={number} onChange={(e) => setNumber(e.target.value)} required />
            <Form.Control.Feedback type="invalid">* Please Select Phone Number</Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg="6">
          <Form.Group className="mb-3">
            <Form.Label className="mb-1">Alternative Phone No.</Form.Label>
            <PhoneInput className="p-2" placeholder="+92-3XX-XXXXXXX" value={alternativenumber} onChange={(e) => setAlternativeNumber(e.target.value)} />
          </Form.Group>
        </Col>
      </Row>
      {/* <div className='mb-3'>
                <Select label={'Permission Type'} placeholder="Specific" />
            </div> */}
      <Row className="py-2 mb-2">
        <Col lg="12 " className={`mb-2 `}>
          <Form.Group className="w-100" controlId="formBasicShopName">
            <Form.Label> Associated Modules</Form.Label>

            <div className="d-flex gap-3 flex-wrap">
              {modules.map((item, index) => (
                <div className="d-flex flex-column  ">
                  <div style={{ maxHeight: "50px" }} onClick={() => fetchSubModulesData(index)} className=" mb-2 d-flex gap-3 p-2 bg-light align-items-center ">
                    <Form.Check
                      key={index}
                      className={""}
                      onChange={(e) => {
                        handleCheckboxChange(e, index);
                      }}
                      name={item?.name}
                      checked={item.isChecked}
                      type="checkbox"
                      id={`${index}`}
                    />
                    <label
                      className=""
                      style={{ fontSize: "14px" }}
                      //  htmlFor={`${index}`}
                    >
                      {item.name}
                    </label>
                  </div>
                  {item?.subModules.length > 0 ? (
                    <Form.Group className="w-100" controlId="formBasicShopName">
                      <div className="d-grid ms-2 ">
                        {item?.subModules?.map((item, i) => (
                          <Form.Check
                            key={i}
                            className={`${style.Checkbox}`}
                            onChange={(e) => {
                              handleSubmoduleCheckboxChange(e, i, index);
                            }}
                            name={item.name}
                            checked={item.isChecked ? item.isChecked : false}
                            type="checkbox"
                            id={`${index}-${p_id}`}
                            label={`${item.name}`}
                          />
                        ))}
                      </div>
                    </Form.Group>
                  ) : (
                    ""
                  )}
                </div>
              ))}
            </div>
          </Form.Group>
        </Col>
        {notificationsCheckBoxes.has_global ? (
          <>
            <hr className="mt-1 mb-1" />
            <Row className="py-2 mb-2">
              <label className="fs-5 fw-bold mb-1">Notifications</label>
              <Col lg={"6"}>
                <Form.Group>
                  <Form.Check type="checkbox" label="Reservation Notifications" checked={notificationsCheckBoxes.has_new_reservation} onChange={(e) => handleNotificationsChange(e, "has_new_reservation")} />
                </Form.Group>
              </Col>
            </Row>
          </>
        ) : (
          ""
        )}
        {isReports ? (
          <>
            {" "}
            <hr className="mt-1 mb-1" />
            <Row className="py-2 mb-2">
              <label className="fs-5 fw-bold mb-1">Reports</label>
              <Col lg={"6"}>
                <Form.Group>
                  <Form.Check type="checkbox" label="General Report" checked={reportsCheckBoxes?.isGeneralReport} onChange={(e) => handleReportsChange(e, "isGeneralReport")} />
                </Form.Group>
              </Col>
              <Col lg={"6"}>
                <Form.Group>
                  <Form.Check type="checkbox" label="Daily Cash Report" checked={reportsCheckBoxes?.isDailyCashReport} onChange={(e) => handleReportsChange(e, "isDailyCashReport")} />
                </Form.Group>
              </Col>
              <Col lg={"6"}>
                <Form.Group>
                  <Form.Check type="checkbox" label="Daily Transaction Report" checked={reportsCheckBoxes?.isDailyTransactionReport} onChange={(e) => handleReportsChange(e, "isDailyTransactionReport")} />
                </Form.Group>
              </Col>
              <Col lg={"6"}>
                <Form.Group>
                  <Form.Check type="checkbox" label="Profit/Loss Report" checked={reportsCheckBoxes?.isProfitLossReport} onChange={(e) => handleReportsChange(e, "isProfitLossReport")} />
                </Form.Group>
              </Col>
              <Col lg={"6"}>
                <Form.Group>
                  <Form.Check type="checkbox" label="Inventory Report" checked={reportsCheckBoxes?.isInventoryReport} onChange={(e) => handleReportsChange(e, "isInventoryReport")} />
                </Form.Group>
              </Col>
              <Col lg={"6"}>
                <Form.Group>
                  <Form.Check type="checkbox" label="Sales Report" checked={reportsCheckBoxes?.isSalesReport} onChange={(e) => handleReportsChange(e, "isSalesReport")} />
                </Form.Group>
              </Col>
              <Col lg={"6"}>
                <Form.Group>
                  <Form.Check type="checkbox" label="IMEI Report" checked={reportsCheckBoxes?.isIMEIReport} onChange={(e) => handleReportsChange(e, "isIMEIReport")} />
                </Form.Group>
              </Col>
              <Col lg={"6"}>
                <Form.Group>
                  <Form.Check type="checkbox" label="Sales Summary Report" checked={reportsCheckBoxes.isSalesSummary} onChange={(e) => handleReportsChange(e, "isSalesSummary")} />
                </Form.Group>
              </Col>
              <Col lg={"6"}>
                <Form.Group>
                  <Form.Check type="checkbox" label="Journal Report" checked={reportsCheckBoxes.isJournal} onChange={(e) => handleReportsChange(e, "isJournal")} />
                </Form.Group>
              </Col>
              <Col lg={"6"}>
                <Form.Group>
                  <Form.Check type="checkbox" label="Bank Transaction Report" checked={reportsCheckBoxes.isBankTransactionsReport} onChange={(e) => handleReportsChange(e, "isBankTransactionsReport")} />
                </Form.Group>
              </Col>
              <Col lg={"6"}>
                <Form.Group>
                  <Form.Check type="checkbox" label="Misc Items History Report" checked={reportsCheckBoxes.isMiscItemsHistoryReport} onChange={(e) => handleReportsChange(e, "isMiscItemsHistoryReport")} />
                </Form.Group>
              </Col>
            </Row>
          </>
        ) : (
          ""
        )}
      </Row>
      {reportsCheck && <p className="text-danger ">select atleast one report</p>}
      <Button disabled={isClicked} type="submit" className="w-100">
        Save
      </Button>
    </Form>
  );
};

export default EditUser;
