import React, { useLayoutEffect } from "react";
import { Route, Routes, useLocation, useSearchParams } from "react-router-dom";
import Cookies from "./Features/Cookies";
import { currentUser, authActions } from "./Features/authSlice";
import { emptyCart, emptyLocation } from "./Features/cart/cartSlice";
// import { emptyMiscCart } from "./Features/cart/miscCartSlice";
import { onLogout } from "./Features/locationSlice";
import { useSelector, useDispatch } from "react-redux";
import io from "socket.io-client";
import { notificationActions } from "./Features/notificationSlice";

//Components

import { useEffect } from "react";

import { useNavigate } from "react-router-dom";

import LogRocket from "logrocket";

import { toast } from "react-toastify";
import unreadNotificationIcon from "./assets/svg/unreadNotificationIcon.svg";
import { publicRoutes } from "./routes/Routes-List";
import DynamicRoutes from "./routes/DynamicRoutes";
import SideNavData from "./Components/Sidebar/SideNavData";
import { routesAction } from "./Features/routesSlice";

const RedirectComponent = () => {
  const [searchParams] = useSearchParams();
  const forceLoginToken = searchParams.get("token");
  const location = useLocation();
  const settings = location.pathname.includes("settings");

  let token = Cookies.get(process.env.REACT_APP_ACCESS_TOKEN);
  const dispatch = useDispatch();
  const refreshToken = localStorage.getItem("refreshToken");
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);
  const socketRef = React.useRef();

  useLayoutEffect(() => {
    if (!forceLoginToken && !user) {
      if (token) {
        dispatch(currentUser(refreshToken));
      } else {
        dispatch(authActions.logout());
        dispatch(onLogout());
        dispatch(emptyCart());
        // dispatch(emptyMiscCart());
        dispatch(emptyLocation());
        localStorage.removeItem("cartItems");
        localStorage.removeItem("cartMiscItems");
        localStorage.removeItem("location");
        localStorage.removeItem("customer");
      }
    } else {
      if (!token) {
        dispatch(authActions.logout());
        dispatch(emptyCart());
        dispatch(onLogout());
        // dispatch(emptyMiscCart());
        dispatch(emptyLocation());
        localStorage.removeItem("cartItems");
        localStorage.removeItem("cartMiscItems");
        localStorage.removeItem("location");
        localStorage.removeItem("customer");
      }
    }
  }, [dispatch, refreshToken, token, location]);

  useEffect(() => {
    document.tidioIdentify = {
      distinct_id: user?.user_id,
      email: user?.user_email,
      name: user?.user_full_name,
    };
    const chatReady = document.getElementById("chat");
    if (!chatReady) {
      const tag = document.createElement("script");
      tag.src = "//code.tidio.co/oplinkkex3bdyylcedugj3jkmnzv8lxw.js";
      tag.id = "chat";
      tag.async = true;
      const body = document.getElementsByTagName("body")[0];
      body.appendChild(tag);
    } else {
      if (settings === false) {
        function onTidioChatApiReady() {
          window.tidioChatApi.hide();
        }
        if (window.tidioChatApi) {
          window.tidioChatApi.on("ready", onTidioChatApiReady);
        } else {
          document.addEventListener("tidioChat-ready", onTidioChatApiReady);
        }
      }
    }
  }, [settings, location, user]);

  useEffect(() => {
    if (process.env.REACT_APP_ENV.replace(" ", "") === "production") {
      if (user) {
        LogRocket.identify(user?.user_id, {
          email: user?.user_email,
          name: user?.user_full_name,
          shopId: user?.shop_id,
        });
      } else {
        LogRocket.identify(null);
      }
    }
  }, [user]);

  useEffect(() => {
    if (user?.is_active === 0) {
      navigate("/");
      // Cookies.remove(process.env.REACT_APP_ACCESS_TOKEN);
      // localStorage.removeItem("refreshToken");
    }
  }, [user, token]);

  // fetching modules

  function fetchModules() {
    return new Promise(async (resolve, reject) => {
      const itemMap = new Map(user?.modules?.filter((item) => item.isIncluded === true).map((item) => [item.name, item.subModules]));
      const values = SideNavData.reduce((acc, val) => {
        const subModules = itemMap.get(val.identifier);
        if (subModules && val.subNav) {
          const updatedSubNav = val.subNav.map((subVal) => {
            const isIncluded = subModules.some((subItem) => subItem.name === subVal.identifier && (subVal.alwaysGenerateRoute ? true : subItem.isIncluded === true));
            const isComing = subModules.some((subItem) => subItem.name === subVal.identifier && subItem.is_coming === 1);
            return {
              ...subVal,
              Included: isIncluded ? "true" : "false",
              isComing: isComing,
            };
          });

          acc.push({
            ...val,
            subNav: updatedSubNav,
            Included: "true",
          });
        } else if (subModules) {
          acc.push({
            ...val,
            Included: "true",
          });
        } else {
          acc.push({
            ...val,
            Included: "false",
          });
        }

        return acc;
      }, []);
      dispatch(routesAction.configureRoutes(values));
    });
  }
  useEffect(() => {
    const CustomToast = ({ notificationData }) => (
      <>
        {notificationData?.payload && JSON.parse(notificationData?.payload)?.image && JSON.parse(notificationData?.payload)?.image !== null ? (
          <>
            <div className="d-flex align-item-center flex-column">
              <div>
                <img src={process.env.REACT_APP_URL_S3 + "/" + JSON.parse(notificationData?.payload)?.image} style={{ height: "200px", width: "inherit", overflow: "hidden", objectFit: "cover", borderBottom: "1px solid black" }} />
              </div>
              <div>
                <img src={unreadNotificationIcon} style={{ marginRight: "5px" }} />
                {notificationData.body}
              </div>
            </div>
          </>
        ) : (
          <div className="d-flex justify-content-around">
            <img src={unreadNotificationIcon} style={{ marginRight: "5px" }} />
            {notificationData.body}
          </div>
        )}
      </>
    );
    const notificationCountCallback = (notificationData) => {
      dispatch(notificationActions.notificationCount(notificationData));
    };
    const notificationToast = (notificationData) => {
      toast(<CustomToast notificationData={notificationData} />, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
        pauseOnHover: false,
        closeButton: true,
        className: "toastMsg",
      });
    };

    if (token && token !== "undefined") {
      if (!socketRef.current) {
        socketRef.current = io(process.env.REACT_APP_API_URL, {
          auth: {
            token,
          },
        });
      }
      const socket = socketRef.current;
      socket.on("notificationCount", notificationCountCallback);
      socket.on("global", notificationToast);
      socket.on("newReservation", notificationToast);
      socket.on("subscriptions", notificationToast);
      socket.on("addonsRequest", notificationToast);
      socket.on("deliveryOrder", notificationToast);

      return () => {
        socket.off("notificationCount", notificationCountCallback);
        socket.off("global", notificationToast);
        socket.off("newReservation", notificationToast);
        socket.off("subscriptions", notificationToast);
        socket.off("addonsRequest", notificationToast);
        socket.off("deliveryOrder", notificationToast);
      };
    } else {
      if (socketRef.current) {
        socketRef.current.disconnect();
        socketRef.current = null;
      }
    }
  }, [token]);

  useEffect(() => {
    fetchModules();
  }, [token, user?.modules]);

  return (
    <>
      {/* <RoutesList/> */}
      <Routes>
        {publicRoutes.map((route) => (
          <Route key={route.path} path={route.path} element={<route.Component />} />
        ))}
        {<Route path="*" element={<DynamicRoutes />} />}
      </Routes>
    </>
  );
};

export default RedirectComponent;
