import { useState, useEffect } from "react";
import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import SearchInput, { SearchMobleInput } from "../../Components/SearchInput";
import CustomizeTableview from "../../Components/TableViews/CustomizeTableview";
import { useSelector } from "react-redux";
import PaginationBar from "../../Components/PaginationBar";
import SearchableSelect from "../../Components/SearchableSelect";
import axios from "axios";
import NoResult from "../../Components/NoResult";
import filterStyle from "../../styles/filtersButtons.module.css";
import filterr from "../../assets/svg/filter.svg";
import { Link } from "react-router-dom";
import ReactSelect from "../../Components/ReactSelect";
import Csv from "../../Components/csv/Csv";
import MobileSearchAcoordian from "../../Components/MobileSearchAcoordian";
import { useNavigate, useSearchParams } from "react-router-dom";
import { dateTime, TIMEOUT } from "../../Features/dateTime";
import { toast } from "react-toastify";
import LoaderPage from "../../Components/loaders/loaderPage";
import DatePicker from "../../Components/DatePicker/DatePicker";
import moment from "moment/moment";
import DeliveryForYouOrdersInvoice from "./DeliveryForYouOrdersInvoice";
import PopUpModal from "../../Components/Modal";

const DeliveryForYouOrders = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const location = useSelector((state) => state.location.location);
  const [noOfPages, setNoOfPages] = useState(1);
  const [sizeOfPages, setSizeOfPages] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [toogleMobileSearch, setToogleMobileSearch] = useState(false);
  const [search, setSearch] = useState("");
  const [toggle, refresh] = useState(false);
  const [statusSearch, setStatusSearch] = useState({ value: null, label: "All" });
  const [isLoading, setIsLoading] = useState(true);
  const [orderBy, setOrderBy] = useState({ created_at: "DESC" });
  const [category, setCategory] = useState(null);
  const [categoriesOptions, setCategoriesOptions] = useState(null);
  const [orders, setOrders] = useState([]);
  const [ordersData, setOrdersData] = useState({});
  const [totalOrders, setTotalOrders] = useState();
  const [fromDate, setFromDate] = useState(moment().subtract(29, "days").startOf("day")._d);
  const [toDate, setToDate] = useState(moment().endOf("day")._d);
  const [invoiceModal, setInvoiceModal] = useState(false);
  const [invoiceId, setInvoiceId] = useState(null);

  const handleAcoordianCLose = () => {
    setToogleMobileSearch(false);
    setSearch("");
    setStatusSearch(null);
    setFromDate(moment().subtract(29, "days").startOf("day")._d);
    setToDate(moment().endOf("day")._d);
  };
  const filter = () => {
    setToogleMobileSearch(false);
  };
  const refreshHandle = () => {
    refresh((prev) => !prev);
  };

  const handleAcoordianOpen = (value) => {
    setToogleMobileSearch(value);
  };
  const { user } = useSelector((state) => state.auth);
  const shop_id = user.shop_id;
  const shopName = user.shop_name;
  const filterCategoriesMobile = (e) => {
    if (e.target.value === "All") {
      setCategory({ value: null, label: e.target.value });
    } else {
      setCategory({ value: e.target.id, label: e.target.value });
    }
  };

  // Search Products
  const searchHandle = (e) => {
    e.preventDefault();
    if (e.target.value === "") {
      setSearch("");
    } else {
      setSearch(e.target.value);
    }
    setCurrentPage(1);
  };

  const searchStatus = (e) => {
    if (e.value) {
      setStatusSearch({ value: e.value, label: e.label });
    } else {
      setStatusSearch({ value: null, label: "All" });
    }
    setCurrentPage(1);
  };

  const searchStatusMobile = (e) => {
    if (e.target.value === null) {
      setStatusSearch({ value: null, label: "All" });
    } else {
      setStatusSearch({ value: e.target.id, label: e.target.value });
    }
    setCurrentPage(1);
  };
  const getCat = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.REACT_APP_API_URL + "/categories/get", {
          withCredentials: true,
        })
        .then((res) => {
          const getData = res.data
            .filter((cat) => cat.id !== 7)
            .map((cat) => ({
              value: cat.id,
              label: cat.category,
            }));
          getData.splice(0, 0, { value: null, label: "All" });
          resolve(getData);
          setCategoriesOptions(getData);
        })
        .catch((err) => {
          reject("err" + err);
        });
    });
  };
  const filterCategories = (e) => {
    if (e.value) {
      setCategory({ value: e.value, label: e.label });
    } else {
      setCategory(null);
    }
    setCurrentPage(1);
  };

  const resetFilterHandle = (e) => {
    setStatusSearch({ value: "Pending", label: "Pending" });
    setCategory(null);
    setFromDate(moment().subtract(29, "days").startOf("day")._d);
    setToDate(moment().endOf("day")._d);
    setSizeOfPages(10);
    setCurrentPage(1);
  };

  const SearchData = [
    {
      name: "Select Status",
      handleChange: searchStatusMobile,
      value: statusSearch,
      filter: [
        { value: null, label: "All" },
        { value: "Pending", label: "Pending" },
        { value: "Confirmed", label: "Confirmed" },
        { value: "Picked", label: "Picked" },
        { value: "Shipped", label: "Shipped" },
        { value: "Delivered", label: "Delivered" },
        { value: "Cancelled", label: "Cancelled" },
        { value: "Returned", label: "Returned" },
      ],
    },
    {
      name: "Select Category",
      handleChange: filterCategoriesMobile,
      value: category,
      filter: categoriesOptions,
    },
  ];

  const changePage = (page) => {
    setCurrentPage(page);
  };

  //searchParams
  useEffect(() => {
    if (searchParams.get("page")) {
      setCurrentPage(Number(searchParams.get("page")));
    }
  }, [currentPage, toggle, searchParams]);

  function getDeliverForYouOrders() {
    const orderByFormatForQueryString = (obj) => {
      const [[key, value]] = Object.entries(obj);
      return `${key}:${value}`;
    };
    const orderByString = orderBy ? orderByFormatForQueryString(orderBy) : "";
    const searchParams = new URLSearchParams({
      page: currentPage,
      size: sizeOfPages,
      ...(search.length > 0 ? { search: search } : {}),
      ...(statusSearch?.value ? { status: statusSearch?.value } : {}),
      ...(orderBy ? { orderBy: orderByString } : {}),
      ...(category ? { category: category?.value } : {}),
      ...(location ? { location: location?.value } : {}),
      ...(fromDate && toDate ? { "date[from]": moment(fromDate).format("YYYY-MM-DD"), "date[to]": moment(toDate).format("YYYY-MM-DD") } : {}),
    });
    axios
      .get(process.env.REACT_APP_API_URL + `/d4u-orders?` + searchParams.toString(), { withCredentials: true })
      .then((data) => {
        setIsLoading(false);
        setTotalOrders(data?.data?.pagination?.no_of_items);
        setOrders(data?.data?.data);

        if (data?.data?.pagination?.no_of_pages === 0) {
          if (searchParams.get("page")) {
            setNoOfPages(0);
            navigate("/listings/orders?page=0");
          }
        } else {
          setNoOfPages(data?.data?.pagination?.no_of_pages);
          if (currentPage > data?.data?.pagination?.no_of_pages) {
            setCurrentPage(data?.data?.pagination?.no_of_pages);
            navigate("/listings/orders?page=" + data?.data?.pagination?.no_of_pages);
          }
          if (searchParams.get("page") === "0") {
            setCurrentPage(1);
            navigate("/listings/orders?page=1");
          }
        }
      })
      .catch((err) => {
        const tempErr = err.response.data.error;
        if (tempErr.status === 404 && tempErr.message === "No Record Found") {
          setOrders([]);
        }
        setIsLoading(false);
      });
  }

  useEffect(() => {
    let timeout;
    if (location) {
      if (search?.length > 0) {
        timeout = setTimeout(() => {
          setIsLoading(true);
          getDeliverForYouOrders();
        }, TIMEOUT);
      } else {
        setIsLoading(true);
        getDeliverForYouOrders();
      }
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [search, fromDate, toDate, location, currentPage, noOfPages, sizeOfPages, statusSearch, shop_id, toggle, orderBy, category]);

  useEffect(() => {
    if (currentPage === 1) {
      refresh((prev) => !prev);
    } else {
      changePage(1);
    }
  }, []);

  function requestAllHistory() {
    const orderByFormatForQueryString = (obj) => {
      const [[key, value]] = Object.entries(obj);
      return `${key}:${value}`;
    };
    const orderByString = orderBy ? orderByFormatForQueryString(orderBy) : "";
    const searchParams = new URLSearchParams({
      page: currentPage,
      size: sizeOfPages,
      ...(search.length > 0 ? { search: search } : {}),
      ...(statusSearch?.value ? { status: statusSearch?.value } : {}),
      ...(orderBy ? { orderBy: orderByString } : {}),
      ...(category ? { category: category?.value } : {}),
      ...(location ? { location: location?.value } : {}),
      ...(fromDate && toDate ? { "date[from]": moment(fromDate).format("YYYY-MM-DD"), "date[to]": moment(toDate).format("YYYY-MM-DD") } : {}),
    });
    return axios.get(process.env.REACT_APP_API_URL + `/d4u-orders?` + searchParams.toString(), { withCredentials: true });
  }

  const caret = async (orderId) => {
    const data = orders.filter((item) => item.id === orderId);
    const { customer_name, customer_email, customer_phone, customer_address, notes, ...ordersObj } = data[0];
    setOrdersData({ customer_name, customer_email, customer_phone, customer_address, notes });
  };
  const close = () => {
    setInvoiceModal(false);
    setInvoiceId(null);
  };
  return (
    <>
      {toogleMobileSearch === "select" ? (
        <MobileSearchAcoordian SearchData={SearchData} toggleSearchclose={handleAcoordianCLose} dateName={"Select Date"} fromDate={fromDate} toDate={toDate} setFromDate={setFromDate} setToDate={setToDate} filter={filter} />
      ) : (
        <>
          <Container fluid>
            {toogleMobileSearch === "search" ? (
              <div className="d-flex  mb-2 justify-content-center align-items-center">
                <div className="">
                  {" "}
                  <SearchMobleInput searchlogo value={search} handleChange={searchHandle} />
                </div>
                <span className="ms-4" onClick={handleAcoordianCLose}>
                  X
                </span>
              </div>
            ) : (
              <Row className="d-flex align-items-center">
                <Col xs="2" lg="3" xl="2" className="d-none d-lg-inline mb-3">
                  <SearchInput value={search} searchlogo handleChange={searchHandle} />
                </Col>
                <Col xs lg="3" xl="2" className="d-none d-lg-inline mb-3"></Col>
                <Col xs lg="3" xl="2" className="d-none d-lg-inline mb-3">
                  <DatePicker important={true} fromDate={fromDate} toDate={toDate} setFromDate={setFromDate} setToDate={setToDate} />
                </Col>
                <Col xs lg="3" xl="2" className="d-none d-lg-inline mb-3">
                  <ReactSelect placeholder="Filter Category" value={category} options={getCat} handleChange={filterCategories} />
                </Col>

                <Col xs lg="3" xl="2" className="d-none d-lg-inline mb-3">
                  <SearchableSelect
                    placeholder="Filter Status"
                    searchable={false}
                    value={statusSearch}
                    options={[
                      { value: null, label: "All" },
                      { value: "Pending", label: "Pending" },
                      { value: "Confirmed", label: "Confirmed" },
                      { value: "Picked", label: "Picked" },
                      { value: "Shipped", label: "Shipped" },
                      { value: "Delivered", label: "Delivered" },
                      { value: "Cancelled", label: "Cancelled" },
                      { value: "Returned", label: "Returned" },
                    ]}
                    handleChange={searchStatus}
                  />
                </Col>
                {/* For Mobile screen Start */}

                <Col className="d-none d-sm-inline d-lg-none d-md-inline px-0" sm={2}></Col>
                <Col xs="6" lg="3" xl="2" sm="3" className="mb-3">
                  <SearchableSelect
                    selectClassName={"bg-white"}
                    placeholder={`Showing ${sizeOfPages}`}
                    defaultValue={`Showing ${sizeOfPages}`}
                    value={`Showing ${sizeOfPages}`}
                    searchable={false}
                    options={[
                      { value: "10", label: "10" },
                      { value: "20", label: "20" },
                      { value: "30", label: "30" },
                    ]}
                    handleChange={(e) => setSizeOfPages(e.value)}
                  />
                </Col>
                <Col onClick={() => handleAcoordianOpen("search")} xs="2" sm="2" lg="3" className="d-lg-none d-inline mb-3">
                  <SearchInput searchlogo bordered />
                </Col>
                <Col className={`d-lg-none d-inline mb-3 ${filterStyle.filter}`} xs="2" lg="2">
                  <Link to="" className={`${filterStyle.filterToggleButtonLink}`}>
                    <Button onClick={() => handleAcoordianOpen("select")} variant="outline-primary" className={`${filterStyle.filterToggleButton} d-flex align-items-center`}>
                      <img className="" src={filterr} alt="filter" />
                      <span className=" ms-2 d-none d-sm-inline">Filter</span>
                    </Button>
                  </Link>
                </Col>
                <Col xs="2" sm="3" lg="2" xl="2" xxl="2" className={`mb-3 p-0 ${filterStyle.addbtn}`}></Col>

                {/* For Mobile screen End */}
              </Row>
            )}
            <div className="pt-2">
              <Button size="sm" type="submit" style={{ fontSize: "14px" }} onClick={resetFilterHandle}>
                <span className="d-lg-inline">Reset Filters</span>{" "}
              </Button>
            </div>

            {isLoading ? (
              <div
                style={{
                  height: "50vh",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <LoaderPage />
              </div>
            ) : orders.length === 0 ? (
              <NoResult />
            ) : (
              <>
                <CustomizeTableview
                  className={"mt-3"}
                  data={orders}
                  caret={caret}
                  caretData={ordersData}
                  columnNotShow={["id", "fk_customer_id", "fk_handler_id", "fk_item_id", "fk_listing_id", "fk_location_id", "fk_shop_id", "is_deleted", "deleted_at", "status", "customer_name", "customer_email", "customer_phone", "customer_address", "notes"]}
                  dateColumn={["created_at", "updated_at"]}
                  sortColumn={["created_at", "updated_at", "item_name", "quantity", "price", "total_price", "customer_name", "customer_phone", "customer_email"]}
                  orderBy={orderBy}
                  setOrderBy={setOrderBy}
                  status={{
                    column: ["status", "created_at"],
                    style: ({ status }) => {
                      if (status === "Delivered") {
                        return "text-success";
                      } else if (status === "Pending") {
                        return "text-warning";
                      } else if (status === "Cancelled") {
                        return "text-danger";
                      } else if (status === "Picked") {
                        return "text-info";
                      } else if (status === "Confirmed") {
                        return "text-primary";
                      } else if (status === "Shipped") {
                        return "text-secondary";
                      } else if (status === "Returned") {
                        return "text-danger";
                      }
                    },
                    format: ({ status }) => {
                      if (status === "Delivered") {
                        return "Delivered";
                      } else if (status === "Pending") {
                        return "Pending";
                      } else if (status === "Cancelled") {
                        return "Cancelled";
                      } else if (status === "Picked") {
                        return "Picked";
                      } else if (status === "Confirmed") {
                        return "Confirmed";
                      } else if (status === "Shipped") {
                        return "Shipped";
                      } else if (status === "Returned") {
                        return "Returned";
                      }
                    },
                  }}
                  actionButton={{
                    column: ["status", "sales_order_id"],
                    label: (value) => {
                      return value["sales_order_id"] === null ? "Sell" : "Invoice";
                    },
                    onClick: (er, val) => {
                      if (val.sales_order_id === null) {
                        setInvoiceModal(true);
                        setInvoiceId(val?.id);
                      } else {
                        navigate(`/listings/orders/${val?.id}/invoice`);
                      }
                    },
                    variant: "primary",
                    disabled: (value) => (value["sales_order_id"] === null ? value["status"] !== "Confirmed" : false),
                    style: { padding: "6px 18px" },
                    isShow: (_, row) => {
                      return true;
                    },
                  }}
                />
                <Row className="mt-5 align-items-center">
                  <Col className="d-none d-md-block" md="3" lg="3">
                    <span className="text-secondary fs-6">{totalOrders} result(s)</span>
                  </Col>
                  <Col xs="12" md="6" lg="6">
                    <PaginationBar currentPage={currentPage} noOfPages={noOfPages} changePage={changePage} />
                  </Col>
                  <Col className="d-md-none d-flex align-items-center mt-3" xs="6" lg="3">
                    <span className="text-secondary fs-6">{totalOrders} result(s)</span>
                  </Col>
                  <Col xs="6" md="3" lg="3" className="text-end mt-3">
                    <Csv
                      filename={`${
                        "Deliver For You Orders " +
                        shopName +
                        " " +
                        new Date().toLocaleDateString("en-US", {
                          timeZone: "Asia/Karachi",
                        })
                      }`}
                      api={requestAllHistory}
                      responseObject={(response) => {
                        return response.data.data;
                      }}
                      format={(data) => {
                        const { id, fk_customer_id, fk_handler_id, fk_item_id, fk_listing_id, fk_location_id, fk_shop_id, is_deleted, created_at, updated_at, deleted_at, ...temp } = data;
                        const newObj = {
                          ...temp,
                          created_at: created_at?.split("T")[0],
                          updated_at: updated_at && updated_at.length > 0 ? updated_at?.split("T")[0] : "",
                        };
                        return newObj;
                      }}
                      error={(err) => {
                        const tempErr = err.response.data.error;
                        if (tempErr.status === 404 && tempErr.message === "No Record Found") {
                          return true;
                        } else {
                          return false;
                        }
                      }}
                    />
                  </Col>
                </Row>
              </>
            )}
          </Container>
        </>
      )}
      <PopUpModal size="xl" onHide={close} show={invoiceModal} close={close} title="Order Invoice">
        <DeliveryForYouOrdersInvoice close={close} invoiceId={invoiceId} refreshHandle={refreshHandle} />
      </PopUpModal>
    </>
  );
};
export default DeliveryForYouOrders;
