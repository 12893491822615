import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import LoaderPage from "../loaders/loaderPage";
import style from "../../styles/Notification.module.css";
import PaginationBar from "../PaginationBar";
import unreadNotificationIcon from "../../assets/svg/unreadNotificationIcon.svg";
import readNotificationIcon from "../../assets/svg/readNotificationIcon.svg";
import filledCircle from "../../assets/svg/filledCircle.svg";
import { dateTime } from "../../Features/dateTime";
import { Col, Container, Row } from "react-bootstrap";
import search from "../../assets/svg/search.svg";
import { useDispatch } from "react-redux";
import { setLocation } from "../../Features/locationSlice";
import NotificationImageModal from "./NotificationImageModal";
const AllNotifications = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const notificationsCount = useSelector((state) => state.notification.notificationsCount);
  const location = useSelector((state) => state.location.location);
  const { user } = useSelector((state) => state.auth);
  const [noOfPages, setNoOfPages] = useState(1);
  const [sizeOfPages, setSizeOfPages] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [count, setCount] = useState(0);
  const [imageModal, setImageModal] = useState(false);
  const [data, setData] = useState(null);
  const userId = user.user_id;
  const shop_id = user.shop_id;
  const changePage = (page) => {
    setCurrentPage(page);
  };
  useEffect(() => {
    const getNotifications = async () => {
      try {
        setIsLoading(true);
        setNotifications([]);
        const responseData = await axios.get(process.env.REACT_APP_API_URL + `/notifications/?user_id=${userId}&limit=${sizeOfPages}&offset=${currentPage * sizeOfPages - sizeOfPages}`, { withCredentials: true });
        if (responseData) {
          setIsLoading(false);
          setNotifications(responseData?.data?.data?.data);
          setNoOfPages(responseData?.data?.data?.pagination?.totalPages);
          setCount(responseData?.data?.data?.pagination?.count);
        }
      } catch (error) {
        setIsLoading(true);
        setNotifications([]);
        setIsLoading(false);
      }
    };
    getNotifications();
  }, [currentPage, noOfPages, sizeOfPages, userId, notificationsCount]);
  const datetimeFunction = (value) => {
    const dateFormat = dateTime(value);
    return dateFormat.toDateString() + "  at " + dateFormat.toLocaleTimeString();
  };
  const handleNotificationStatus = async (item) => {
    try {
      let itemPayload;
      if (typeof item?.payload === "string") {
        itemPayload = JSON.parse(item?.payload);
      } else {
        itemPayload = item?.payload;
      }
      if ((item?.type === "2" && item?.is_read === 0) || (item?.type === "7" && item?.is_read === 0)) {
        const response = await axios.put(
          process.env.REACT_APP_API_URL + `/notifications/mark-as-read`,
          { groupTag: item.group_tag },
          {
            withCredentials: true,
          }
        );
        if (response) {
          if (item?.type === "2") {
            if (location?.value !== itemPayload?.location?.value) {
              dispatch(setLocation({ shop_id, location: itemPayload?.location }));
            }
            navigate("/listings/reservations");
          } else if (item.type === "7") {
            if (location?.value !== itemPayload?.location.value) {
              dispatch(setLocation({ shop_id, location: itemPayload?.location }));
            }
            navigate("/listings/orders");
          }
        }
      } else if ((item.type === "2" && item.is_read === 1) || (item.type === "7" && item.is_read === 1)) {
        if (item.type === "2") {
          if (location?.value !== itemPayload?.location.value) {
            dispatch(setLocation({ shop_id, location: itemPayload?.location }));
          }
          navigate("/listings/reservations");
        } else if (item.type === "7") {
          if (location?.value !== itemPayload?.location.value) {
            dispatch(setLocation({ shop_id, location: itemPayload?.location }));
          }
          navigate("/listings/orders");
        }
      } else if ((item.type === "1" && item.is_read === 0) || (item.type === "3" && item.is_read === 0) || (item.type === "4" && item.is_read === 0) || (item.type === "6" && item.is_read === 0)) {
        const response = await axios.put(
          process.env.REACT_APP_API_URL + `/notifications/mark-as-read`,
          { groupTag: item.group_tag },
          {
            withCredentials: true,
          }
        );
        if (response) {
          if (itemPayload !== null && itemPayload?.image !== null) {
            setImageModal(true);
            setData({ image: itemPayload?.image, link: item?.action });
          } else {
            if (item.action !== null) {
              const link = document.createElement("a");
              link.target = "_blank";
              link.href = item.action;
              link.rel = "noopener noreferrer";
              link.click();
            }
          }
        }
      } else if ((item.type === "1" && item.is_read === 1) || (item.type === "3" && item.is_read === 1) || (item.type === "4" && item.is_read === 1) || (item.type === "6" && item.is_read === 1)) {
        if (itemPayload !== null && itemPayload?.image !== null) {
          setImageModal(true);
          setData({ image: itemPayload?.image, link: item?.action });
        } else {
          if (item.action !== null) {
            const link = document.createElement("a");
            link.target = "_blank";
            link.href = item.action;
            link.rel = "noopener noreferrer";
            link.click();
          }
        }
      } else {
      }
    } catch (err) {
      const tempErr = err.response.data.error;
      if (tempErr.status === 404 && tempErr.message === "No Record Found") {
        setNotifications([]);
      }
    }
  };
  return (
    <>
      {isLoading ? (
        <div
          style={{
            height: "50vh",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <LoaderPage />
        </div>
      ) : notifications.length === 0 ? (
        <Container fluid className="d-flex justify-content-center align-items-center" style={{ height: "75vh" }}>
          <div className="text-center">
            <img height="45px" width="45px" src={search} alt="search" className="mb-3" />
            <h5 className="text-dark">You don't have any read notification!</h5>
          </div>
        </Container>
      ) : (
        <>
          {notifications.map((item, index) => (
            <div
              className={`mt-3${style.DropdownItem}`}
              id={index}
              onClick={() => {
                handleNotificationStatus(item);
              }}
            >
              <div
                className="d-flex gap-0 gap-sm-4 cursor-pointer p-3 mt-3"
                style={
                  item?.is_read === 0
                    ? {
                        backgroundColor: "#F8F6FF",
                        borderRadius: "14px",
                      }
                    : null
                }
              >
                <div className="d-flex align-items-center">
                  <img
                    style={{
                      pointerEvents: "none",
                    }}
                    width="49px"
                    height="49px"
                    src={item?.is_read === 0 ? unreadNotificationIcon : readNotificationIcon}
                    alt="notification Icon"
                  />
                </div>
                <div className="d-flex flex-column justify-content-center fs-6">
                  <strong>{item?.title}</strong>
                  <small>{item?.body}</small>
                  <small style={{ color: "#898995" }}>{datetimeFunction(item.created_at)}</small>
                </div>
                {item?.is_read === 0 && (
                  <div className="d-flex align-items-center justify-content-end" style={{ marginLeft: "auto" }}>
                    <img width="6px" height="6px" src={item?.is_read === 0 ? filledCircle : null} alt="Unread Notification Icon" />
                  </div>
                )}
              </div>
              {item?.is_read === 0 ? "" : <hr />}
            </div>
          ))}
          <Row className="mt-5 align-items-center">
            <Col className="d-none d-sm-block" sm="2" md="3" lg="3">
              <span className="text-secondary fs-6">{count} results</span>
            </Col>
            <Col xs="12" sm="7" md="6" lg="6">
              <PaginationBar currentPage={currentPage} noOfPages={noOfPages} changePage={changePage} />
            </Col>
            <Col className="d-sm-none d-flex align-items-center mt-3" xs="6" lg="3">
              <span className="text-secondary fs-6">{count} results</span>
            </Col>
          </Row>
        </>
      )}
      <NotificationImageModal
        show={imageModal}
        close={() => {
          setImageModal(false);
          setData(null);
        }}
        data={data}
      />
    </>
  );
};

export default AllNotifications;
