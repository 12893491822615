import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Col, Form, FormControl, Modal, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import searchicon from "../../assets/svg/search.svg";
import triangle from "../../assets/svg/triangle.svg";
import dangerline from "../../assets/svg/dangerline.svg";
import CustomizeTableview from "../TableViews/CustomizeTableview";
import customloader from "../../assets/images/RollingLoader.gif";
import style from "./../../styles/SearchInput.module.css";
import ViewIMEI from "./ViewIMEI";
import SearchInput from "../SearchInput";
import PaginationBar from "../PaginationBar";
import Batch from "./BatchForm";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

const ItemBatchesModal = ({ show, categoryId, itemTitle, close, id, refreshing = () => {} }) => {
  const [batchData, setBatchData] = useState([]);
  const [batchId, setBatchId] = useState(-1);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const [noOfPages, setNoOfPages] = useState(1);
  const [sizeOfPages, setSizeOfPages] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [imeiModal, setImeiModal] = useState(false);
  const [search, setSearch] = useState("");
  const [batchForm, setBatchForm] = useState(false);
  const [deleteBatch, setDeleteBatch] = useState(false);
  const [toggle, refresh] = useState(false);
  const [totalBatches, setTotalBatches] = useState(0);

  const changePage = (page) => {
    setCurrentPage(page);
  };

  const handleCloseModal = () => {
    refreshing();
    close();
    setSearch("");
    setImeiModal(false);
    setBatchForm(false);
    setDeleteBatch(false);
  };

  useEffect(() => {
    const getCustomerSales = async () => {
      try {
        setIsModalLoading(true);
        setBatchData([]);
        const responseData = await axios.get(process.env.REACT_APP_API_URL + `/inventory/batches/${id}?search=${search}&page=${currentPage}&size=${sizeOfPages}`, { withCredentials: true });
        if (responseData) {
          setIsModalLoading(false);
          setBatchData(responseData.data.batches);
          setNoOfPages(responseData.data.count.no_of_pages);
          setTotalBatches(responseData.data.count.no_of_items);
        }
      } catch (error) {
        setIsModalLoading(true);
        setBatchData([]);
        setIsModalLoading(false);
      }
    };
    if (id !== -1) {
      getCustomerSales();
    }
  }, [id, user, currentPage, search, sizeOfPages, noOfPages, toggle]);
  const viewImei = () => {};
  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const edit = (id, batch) => {
    setBatchId(batch.batch_id);
    setDeleteBatch(false);
    setBatchForm(true);
    setImeiModal(false);
  };

  const remove = (batch) => {
    setBatchId(batch.batch_id);
    setDeleteBatch(true);
    setBatchForm(false);
    setImeiModal(false);
  };
  const deleteConfirm = async () => {
    try {
      const res = await axios.delete(process.env.REACT_APP_API_URL + `/batch/${batchId}`, {
        withCredentials: true,
      });
      if (res?.data?.message) {
        toast.success(`${res.data.message}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1500,
          pauseOnHover: false,
          className: "toastMsg",
        });
      } else {
        toast.error(`${res.data.Errormessage}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1500,
          pauseOnHover: false,
          className: "toastMsg",
        });
      }
    } catch (error) {
      throw new Error(error);
    }
    setDeleteBatch(false);
    refreshHandle();
  };
  const refreshHandle = () => {
    refresh((prev) => !prev);
  };
  return (
    <>
      <Modal aria-labelledby="contained-modal-title-vcenter" centered size="xl" backdrop="static" className="customerSalesModal" show={show} onHide={handleCloseModal}>
        <Modal.Header className="border-0 pt-3 border-bottom" closeButton>
          <div className="d-flex align-items-center gap-4">
            <h4>Batches</h4>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="container pt-3">
            <div className="row">
              <div className="col-lg-8 col-md-12 mb-3">
                {(categoryId === 2 || categoryId === 3) && (
                  <Form className="position-relative w-35 ms-3 text-center">
                    <FormControl type="search" placeholder="Search by IMEI(s)" className={` ${style.searchInput} `} aria-label="Search" onChange={handleChange} value={search} />
                    <div className={` ${style.margin_bottom}  ${style.searchIcon} d-flex ms-2`}>{<img style={{ width: "1.47rem" }} src={searchicon} alt="search" />}</div>
                  </Form>
                )}
                <hr className="m-0 mb-3 d-sm-none d-block" />
                {isModalLoading ? (
                  <div className="d-flex justify-content-center align-items-center" style={{ height: "auto" }}>
                    <img src={customloader} alt="customloader" width="100" height="100" />
                  </div>
                ) : batchData.length > 0 ? (
                  <div
                    style={{
                      maxHeight: "70vh",
                      overflow: "auto",
                      display: "block",
                    }}
                    className="mb-4"
                  >
                    <CustomizeTableview
                      firstColumnWidth={true}
                      className={"customerSalesModal mb-2 mt-3"}
                      data={batchData}
                      editBatch={edit}
                      deleteBatch={remove}
                      columnNotShow={["is_deleted", "item_id", "batch_id"]}
                      dateColumn={["entry_date"]}
                      actionButton={{
                        column: "batch_id",
                        label: "View IMEI",
                        onClick: (er, val) => {
                          viewImei(er);
                          setBatchId(val.batch_id);
                          setImeiModal(true);
                          setBatchForm(false);
                          setDeleteBatch(false);
                        },
                        variant: "outline-primary",
                        style: { padding: "8px", fontSize: "12px" },
                        isShow: (value) => {
                          return categoryId === 2 || categoryId === 3 ? true : false;
                        },
                      }}
                      // actions={true}
                    />
                  </div>
                ) : (
                  <div className="mb-4 d-flex justify-content-center text-center">
                    {/* <NoResult height="auto" onClick={() => navigate("/sales")} name="Sale" /> */}
                    <h3>No Result Found</h3>
                  </div>
                )}
                {totalBatches > 10 && (
                  <Row className="mt-5 align-items-center">
                    <Col className="d-none d-md-block" md="3" lg="3">
                      <span className="text-secondary fs-6"> {totalBatches} Results</span>
                    </Col>
                    <Col xs="12" md="6" lg="6">
                      <PaginationBar noOfPages={noOfPages} currentPage={currentPage} changePage={changePage} />
                    </Col>
                  </Row>
                )}
                <div className={batchData.length > 0 ? "d-flex justify-content-end  " : "d-flex justify-content-center"}>
                  <Button
                    onClick={() => {
                      setBatchForm(true);
                      setImeiModal(false);
                      setBatchId(-1);
                      setDeleteBatch(false);
                    }}
                    type="submit"
                    variant="primary"
                    className="w-25  mt-2"
                  >
                    <span className="fs-20">+</span>Add Batch
                  </Button>
                </div>
              </div>
              <div className="col-lg-4 col-md-12">
                {/* <div className="col-lg-6 border-start"> */}
                {batchForm ? (
                  <Batch
                    itemId={id}
                    batchId={batchId}
                    refreshHandle={refreshHandle}
                    close={() => {
                      setBatchForm(false);
                    }}
                  />
                ) : (
                  ""
                )}
                {imeiModal ? <ViewIMEI show={imeiModal} id={batchId} itemTitle={itemTitle} setId={setBatchId} /> : ""}
                {deleteBatch ? (
                  <>
                    <div className="d-flex justify-content-between align-items-center px-5">
                      <h5>Delete Confirmation</h5>
                    </div>
                    <hr />
                    <div className="d-flex justify-content-center pb-4">
                      <div style={{ position: "relative" }}>
                        <img src={triangle} alt="triangle" />
                        <img
                          src={dangerline}
                          alt="dangerline"
                          style={{
                            position: "absolute",
                            left: "30px",
                            top: "1.2rem",
                          }}
                        />
                      </div>
                    </div>
                    <div>
                      <span className="d-flex justify-content-center pb-4">Do you really want to delete?</span>
                    </div>
                    <div className="d-flex gap-3">
                      <span
                        className="w-100 d-flex justify-content-center align-items-center text-secondary p-2 border-2 border-secondary"
                        style={{
                          cursor: "pointer",
                          border: "2px solid rgba(151, 156, 162, 1)",
                          borderRadius: "6px",
                        }}
                        onClick={() => setDeleteBatch(false)}
                      >
                        Cancel
                      </span>
                      <Button className="w-100" onClick={() => deleteConfirm(id)}>
                        Delete
                      </Button>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ItemBatchesModal;
