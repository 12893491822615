import { useState, useEffect } from "react";
import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import SearchInput, { SearchMobleInput } from "../../Components/SearchInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import customloader from "../../assets/images/RollingLoader.gif";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import filterImg from "../../assets/svg/filter.svg";
import axios from "axios";
import NoResult from "../../Components/NoResult";
import SearchableSelect from "../../Components/SearchableSelect";
import ReactSelect from "../../Components/ReactSelect";
import style from "../../styles/InventoryList.module.css";
import CustomizeTableview from "../../Components/TableViews/CustomizeTableview";
import PaginationBar from "../../Components/PaginationBar";
import AddExpenses from "./AddExpenses";
import { useSelector } from "react-redux";
import EditExpenses from "./EditExpenses";
import moment from "moment";
import DatePicker from "../../Components/DatePicker/DatePicker";
import Csv from "../../Components/csv/Csv";
import MobileSearchAcoordian from "../../Components/MobileSearchAcoordian";
import { useNavigate, useSearchParams } from "react-router-dom";
import { requestDateTime } from "../../Features/dateTime";

import { TIMEOUT } from "../../Features/dateTime";
import LoaderPage from "../../Components/loaders/loaderPage";

const Expenses = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { user } = useSelector((state) => state.auth);
  const location = useSelector((state) => state.location?.location);
  const register = useSelector((state) => state.location?.register);
  // const locationId = location.value;
  const shop_id = user.shop_id;
  const user_type = user.user_type;
  const userId = user.user_id;
  const shopName = user.shop_name;

  // const [locationId, setLocationId] = useState(-1);
  // const [defaultLocationValue, setDefaultLocationValue] = useState();
  // const [locationValue, setLocationValue] = useState({});
  // const [resetLocation, setResetLocation] = useState();
  // const [getTechLocations, setGetTechLocations] = useState();
  // const [addFormLocations, setAddFormLocation] = useState();
  const [openingBalances, setOpeningBalances] = useState([]);
  // const loadLocatinOptions = () => {
  //   return new Promise((resolve, reject) => {
  //     axios
  //       .post(
  //         process.env.REACT_APP_API_URL +
  //           "/location/get-location-by-user/" +
  //           userId,
  //         { shop_id: shop_id, user_type: user_type },
  //         { withCredentials: true }
  //       )
  //       .then((locationsRes) => {
  //         const locations = locationsRes.data.map(
  //           ({ location_id, location, active_till }) => ({
  //             value: location_id,
  //             label: location,
  //             isDisabled:
  //               new Date().toISOString() >= active_till ? true : false,
  //           })
  //         );
  //         setAddFormLocation([...locations]);
  //         locations.splice(0, 0, {
  //           value: null,
  //           label: "All",
  //           isDisabled: false,
  //         });
  //         // setLocationValue(locations[0]);
  //         setResetLocation(locations[0]);
  //         setDefaultLocationValue(locations[0]);
  //         setGetTechLocations(locations);
  //         resolve(locations);
  //       })
  //       .catch((err) => {
  //         reject(err);
  //       });
  //   });
  // };

  const [modalAdd, setModalAdd] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);

  const [toggle, refreshPage] = useState(false);

  const [expenseTypeFilter, setExpenseTypeFilter] = useState({});

  const [orderBy, setOrderBy] = useState({ expense_date: "DESC" });

  const [noOfPages, setNoOfPages] = useState(1);
  const [sizeOfPages, setSizeOfPages] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [fromDate, setFromDate] = useState(moment().subtract(29, "days").startOf("day")._d);
  const [toDate, setToDate] = useState(moment().endOf("day")._d);

  // Expenses

  const [expenses, setExpenses] = useState([]);
  const [totalExpenses, setTotalExpenses] = useState();

  const [searchInput, setSearchInput] = useState("");

  const [editExpenseId, setEditExpenseId] = useState(-1);
  const [toogleMobileSearch, setToogleMobileSearch] = useState(false);

  const edit = (id) => {
    setModalEdit(true);
    setEditExpenseId(id);
  };
  const remove = (id) => {
    axios
      .delete(process.env.REACT_APP_API_URL + "/expenses/delete/" + id, {
        withCredentials: true,
      })
      .then(() => {
        refreshPage((prev) => !prev);
      })
      .catch((err) => {
        throw new Error(err);
      });
  };

  const changePage = (page) => {
    navigate("/expenses?page=" + page);
  };

  //searchParams
  useEffect(() => {
    if (searchParams.get("page")) {
      setCurrentPage(Number(searchParams.get("page")));
    }
  }, [currentPage, toggle, searchParams]);

  function getExpenses() {
    let filters = {};
    if (Object.values(expenseTypeFilter)?.length > 0) {
      filters["expense_type"] = expenseTypeFilter.value;
    }
    if (searchInput?.length > 0) {
      filters["search"] = searchInput;
    }
    if (location.value !== -1) {
      axios
        .post(
          process.env.REACT_APP_API_URL + "/expenses/get?page=" + currentPage + "&size=" + sizeOfPages,
          {
            filters: filters,
            shopId: shop_id,
            location: location.value,
            dates: {
              from: requestDateTime(fromDate).toISOString().replace("T", " ").replace("Z", "").split(".")[0],
              to: requestDateTime(toDate).toISOString().replace("T", " ").replace("Z", "").split(".")[0],
            },
            orderBy: orderBy,
          },
          { withCredentials: true },
        )
        .then((data) => {
          const res = data?.data;
          setExpenses(res?.data);
          if (res?.count?.no_of_pages === 0) {
            if (searchParams.get("page")) {
              setNoOfPages(0);
              navigate("/expenses?page=0");
            }
          } else {
            setTotalExpenses(res?.count?.no_of_items);
            setNoOfPages(res?.count?.no_of_pages);
            if (currentPage > res?.count?.no_of_pages) {
              setCurrentPage(res?.count?.no_of_pages);
              navigate("/expenses?page=" + res?.count?.no_of_pages);
            }
            if (searchParams.get("page") === "0") {
              setCurrentPage(1);
              navigate("/expenses?page=1");
            }
          }
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          const tempErr = err.response.data.error;
          if (tempErr.status === 404 && tempErr.message === "No Record Found") {
            setExpenses([]);
          }
        });
    }
  }

  //loading
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let timeOut;
    if (searchInput?.length > 0) {
      timeOut = setTimeout(() => {
        setIsLoading(true);
        getExpenses();
      }, TIMEOUT);
    } else {
      setIsLoading(true);
      getExpenses();
    }
    return () => clearTimeout(timeOut);
  }, [searchInput, sizeOfPages, currentPage, toggle, shop_id, orderBy]);

  useEffect(() => {
    if (currentPage === 1) {
      refreshPage((prev) => !prev);
    } else {
      changePage(1);
    }
  }, [expenseTypeFilter, location, fromDate, toDate, orderBy]);

  function requestAllHistory() {
    let filters = {};
    if (Object.values(expenseTypeFilter)?.length > 0) {
      filters["expense_type"] = expenseTypeFilter.value;
    }
    if (searchInput?.length > 0) {
      filters["search"] = searchInput;
    }
    if (location.value !== -1) {
      return axios.post(
        process.env.REACT_APP_API_URL + "/expenses/get",
        {
          filters: filters,
          shopId: shop_id,
          location: location.value,
          dates: {
            from: requestDateTime(fromDate).toISOString().replace("T", " ").replace("Z", "").split(".")[0],
            to: requestDateTime(toDate).toISOString().replace("T", " ").replace("Z", "").split(".")[0],
          },
          orderBy: orderBy,
        },
        { withCredentials: true },
      );
    }
  }

  const handlePageSize = (e) => {
    setSizeOfPages(e.value);
  };

  //Add modal controllers
  const showModalAdd = () => {
    setModalAdd(true);
  };
  const closeModalAdd = () => {
    setModalAdd(false);
    refreshPage((prev) => !prev);
  };
  //Edit modal controllers
  const closeModalEdit = () => {
    setModalEdit(false);
    setEditExpenseId(-1);
    refreshPage((prev) => !prev);
  };
  const handleChangeExpenseType = (e) => {
    if (e.value) {
      setExpenseTypeFilter(e);
    } else {
      setExpenseTypeFilter({});
    }
  };
  // const handleChangeLocation = (e) => {
  //   if (e.value) {
  //     setLocationValue({ value: e.value, label: e.label });
  //     setLocationId(e.value);
  //   } else {
  //     setLocationValue({});
  //     setLocationId(-1);
  //   }
  // };
  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };
  const resetFilterHandle = (e) => {
    setExpenseTypeFilter({});
    setFromDate(moment().subtract(29, "days").startOf("day")._d);
    setToDate(moment().endOf("day")._d);
    // setLocationId(-1);
    // setLocationValue({});
  };

  // Responsive filters handles
  const handleAcoordianCLose = () => {
    setToogleMobileSearch(false);
    setSearchInput("");
    setExpenseTypeFilter({});
  };
  const handleAcoordianOpen = (value) => {
    setToogleMobileSearch(value);
  };

  const filter = () => {
    setToogleMobileSearch(false);
  };

  const handleChangeExpenseTypeMobile = (e) => {
    if (e.target.value) {
      setExpenseTypeFilter({ value: e.target.value, label: e.target.value });
    } else {
      setExpenseTypeFilter({});
    }
  };

  // const handleChangeLocationMobile = (e) => {
  //   setLocationValue({ value: e.target.id, label: e.target.value });
  //   setLocationId(e.target.id);
  // };

  const SearchData = [
    {
      name: "Select Expense Type",
      handleChange: handleChangeExpenseTypeMobile,
      value: expenseTypeFilter,
      filter: [
        { value: null, label: "All" },
        { value: "Rent", label: "Rent" },
        { value: "Purchase", label: "Purchase" },
        { value: "Food", label: "Food" },
        { value: "Electric Bills", label: "Electric Bills" },
        { value: "Parts", label: "Parts" },
        { value: "Repairing", label: "Repairing" },
        { value: "Delivery", label: "Delivery" },
        { value: "PTA Approval", label: "PTA Approval" },
        { value: "Tax", label: "Tax" },
        { value: "Other", label: "Other" },
      ],
    },
    // {
    //   name: "Select Location",
    //   handleChange: handleChangeLocationMobile,
    //   value: locationValue,
    //   filter: getTechLocations,
    // },
  ];

  const handleRefresh = () => {
    refreshPage((prev) => !prev);
  };

  return (
    <>
      {toogleMobileSearch === "select" ? (
        <MobileSearchAcoordian SearchData={SearchData} toggleSearchclose={handleAcoordianCLose} dateName={"Select Date"} fromDate={fromDate} toDate={toDate} setFromDate={setFromDate} setToDate={setToDate} filter={filter} />
      ) : (
        <Container fluid>
          {toogleMobileSearch === "search" ? (
            <div className="d-flex  mb-2 justify-content-center align-items-center">
              <div className="">
                {" "}
                <SearchMobleInput searchlogo value={searchInput} handleChange={handleSearchChange} />
              </div>
              <span className="ms-4" onClick={handleAcoordianCLose}>
                X
              </span>
            </div>
          ) : (
            <Row className="d-flex align-items-center">
              <Col xs="2" lg="3" xl="2" className="d-none d-lg-inline mb-3">
                <SearchInput searchlogo handleChange={handleSearchChange} value={searchInput} />
              </Col>
              <Col xs lg="2" className={`d-none d-lg-inline tooltippp mb-3 ${style.condition}`}>
                {/* <ReactSelect
                  placeholder="Location"
                  searchable={false}
                  value={
                    Object.keys(locationValue).length > 0 ? locationValue : null
                  }
                  options={loadLocatinOptions}
                  handleChange={handleChangeLocation}
                /> */}
              </Col>
              <Col xs lg="2" className={`d-none d-lg-inline tooltippp mb-3 ${style.condition}`}>
                <SearchableSelect
                  value={Object.keys(expenseTypeFilter).length > 0 ? expenseTypeFilter : null}
                  placeholder="Expense Type"
                  searchable={false}
                  options={[
                    { value: null, label: "All" },
                    { value: "Rent", label: "Rent" },
                    { value: "Purchase", label: "Purchase" },
                    { value: "Food", label: "Food" },
                    { value: "Electric Bills", label: "Electric Bills" },
                    { value: "Parts", label: "Parts" },
                    { value: "Repairing", label: "Repairing" },
                    { value: "Delivery", label: "Delivery" },
                    { value: "PTA Approval", label: "PTA Approval" },
                    { value: "Tax", label: "Tax" },
                    { value: "Other", label: "Other" },
                  ]}
                  handleChange={handleChangeExpenseType}
                />
              </Col>
              <Col xs lg="2" className="d-none d-lg-inline tooltippp mb-3">
                <DatePicker important={true} fromDate={fromDate} toDate={toDate} setFromDate={setFromDate} setToDate={setToDate} />
              </Col>

              <Col xs="2" lg="3" onClick={() => handleAcoordianOpen("search")} className="d-lg-none d-inline mb-3">
                <SearchInput searchlogo bordered />
              </Col>
              <Col className="d-lg-none d-flex mb-3" xs="2" lg="2">
                <Button className="py-2" onClick={() => handleAcoordianOpen("select")} variant="outline-primary">
                  <img src={filterImg} alt="filter" />
                  <span className=" ms-2 d-none d-sm-inline">Filter</span>{" "}
                </Button>
              </Col>
              <Col className="d-none d-sm-inline d-lg-none d-md-inline" sm={2}></Col>
              <Col xs="6" lg="3" xl="2" sm="3" className="tooltippp mb-3">
                <SearchableSelect
                  placeholder={`Showing ${sizeOfPages}`}
                  defaultValue={`Showing ${sizeOfPages}`}
                  value={`Showing ${sizeOfPages}`}
                  searchable={false}
                  options={[
                    { value: "10", label: "10" },
                    { value: "20", label: "20" },
                    { value: "30", label: "30" },
                  ]}
                  handleChange={handlePageSize}
                />
              </Col>
              {/* {expenses[0] && ( */}
              <Col xs="2" sm="3" lg="2" xl="2" xxl="2" className="mb-3 p-0">
                <Button className="w-100" type="submit" onClick={() => showModalAdd()}>
                  <span className="d-none d-sm-inline d-lg-inline" style={{ fontSize: ".8rem" }}>
                    Add Expense
                  </span>
                  <FontAwesomeIcon icon={faPlus} style={{ fontSize: ".8rem" }} />
                </Button>
              </Col>
              {/* )} */}
            </Row>
          )}
          <div className="pt-2 d-lg-inline">
            <Button size="sm" type="submit" style={{ fontSize: "14px", marginTop: "7px" }} onClick={resetFilterHandle}>
              <span className="">Reset Filters</span>{" "}
            </Button>
          </div>
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
              <LoaderPage />
            </div>
          ) : expenses?.length === 0 ? (
            <NoResult onClick={() => showModalAdd()} name="Expenses" />
          ) : (
            <>
              <CustomizeTableview className={"mt-4"} data={expenses} edit={edit} remove={remove} columnNotShow={["id"]} dateColumn={["expense_date"]} sortColumn={["expense_type", "expense_amount", "expense_date"]} orderBy={orderBy} setOrderBy={setOrderBy} />
              <Row className="mt-5 align-items-center">
                <Col className="d-none d-md-block" md="3" lg="3">
                  <span className="text-secondary fs-6">{totalExpenses} results</span>
                </Col>
                <Col xs="12" md="6" lg="6">
                  <PaginationBar currentPage={currentPage} noOfPages={noOfPages} changePage={changePage} />
                </Col>
                <Col className="d-md-none d-flex align-items-center mt-3" xs="6" lg="3">
                  <span className="text-secondary fs-6">{totalExpenses} results</span>
                </Col>
                <Col xs="6" md="3" lg="3" className="text-end ">
                  <Csv
                    filename={`${
                      "Expenses " +
                      shopName +
                      " " +
                      new Date().toLocaleDateString("en-US", {
                        timeZone: "Asia/Karachi",
                      })
                    }`}
                    api={requestAllHistory}
                    format={(data) => {
                      const { id, expense_date, ...temp } = data;
                      const newObj = {
                        "Expense Type": temp.expense_type,
                        "Expense Amount": temp.expense_amount,
                        "Payment Type": temp.payment_type,
                        "Expenses Date": expense_date.split("T")[0],
                      };

                      return newObj;
                    }}
                    error={(err) => {
                      if (err.response) {
                        const tempErr = err.response.data.error;
                        if (tempErr.status === 404 && tempErr.message === "No Record Found") {
                          return true;
                        } else {
                          return false;
                        }
                      } else {
                        return false;
                      }
                    }}
                    responseObject={(data) => {
                      return data?.data?.data;
                    }}
                  />
                </Col>
              </Row>
            </>
          )}
        </Container>
      )}

      <Modal show={modalAdd} onHide={closeModalAdd}>
        <Modal.Header closeButton>
          <Modal.Title>Add Expense</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddExpenses
            user={user}
            location={location}
            // location={addFormLocations}
            openingBalances={openingBalances}
            close={closeModalAdd}
            refresh={handleRefresh}
          />
        </Modal.Body>
      </Modal>

      <Modal show={modalEdit} onHide={closeModalEdit}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Expense</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditExpenses
            id={editExpenseId}
            locations={location}
            // locations={addFormLocations}
            close={closeModalEdit}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Expenses;
