import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const Settings = () => {
  const location = useLocation();
  const loc = location.pathname.includes("settings");
  const page = location.pathname.split("/").pop();
  const { user } = useSelector((state) => state.auth);
  useEffect(() => {
    if (loc === true) {
      window.tidioChatApi?.show();
    }
  }, [user, location, loc]);

  return (
    <>
      {page === "settings" ? (
        <Navigate to={"/settings/locations"} />
      ) : (
        <Outlet />
      )}
    </>
  );
};

export default Settings;
